import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import NavReducer from './NavReducer';
import GroupReducer from './GroupReducer';
import RightReducer from './RightReducer';
import ThemeReducer from './ThemeReducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import AccountReducer from './AccountReducer';
import CustomersReducer from "./CustomersReducer";
import SellersReducer from "./SellersReducer";
import CategoryProductReducer from "./CategoryProductReducer";
import InventoryReducer from "./InventoryReducer";
import EmployeeFunctionReducer from "./employeeFonctionReducer";
import ServiceReducer from "./ServiceReducer";
import CashierReducer from "./CashierReducer";
import OrderReducer from "./OrderReducer";
import DashboardReducer from "./dashboardReducer";
import SmsReducer from "./SmsReducer";
import ShopReducer from "./shopReducer";

export default combineReducers({
    auth: AuthReducer,
    nav: NavReducer,
    groups: GroupReducer,
    rights: RightReducer,
    customers: CustomersReducer,
    sellers: SellersReducer,
    employeeFunctions: EmployeeFunctionReducer,
    categoryProducts: CategoryProductReducer,
    inventory: InventoryReducer,
    services: ServiceReducer,
    cashier: CashierReducer,
    order: OrderReducer,
    dashboard: DashboardReducer,
    accounts: AccountReducer,
    sms: SmsReducer,
    shop: ShopReducer,
    theme: ThemeReducer,
    toastr: toastrReducer
});
