import React, {Component} from "react";
import { Row, Col, Form,  FloatingLabel, Alert,} from "react-bootstrap";
import {toastr} from 'react-redux-toastr';
import SimpleReactValidator from "simple-react-validator";
import { checkCameroonPhoneNumber } from "../../helpers/Number";
import Button from "../../Components/InputButton/InputButton";

import "./customer.css";

class CustomerForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: {},
            customer: {},
            shopId: 1,
        }
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
    }

    handleCustomerChange = (e) => {
        this.setState({
            ...this.state,
            customer: { ...this.state.customer, [e.target.name]: e.target.value, },
        });
    }

    handleUserChange = (e) => {
        this.setState({
            ...this.state,
            user: { ...this.state.user, [e.target.name]: e.target.value, },
        });
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    }

    validPhoneNumber = (phone) => {
        if(!checkCameroonPhoneNumber(phone)){ 
            toastr.error('Error', "phone number has to be in this format: 2376XXXXXXXX", {timeOut: 5000});
            return false;
        }
        return true;
    }

    confirmPassword = (password, confirmPassword) => {
        if(password !== confirmPassword){
            toastr.error('Error', "Confirm password doesn't match to the password!", {timeOut: 5000});
            return false;
        }
        return true;
    }

    submitForm = (e) => {
        // e.preventDefault();
        if(this.props.onSubmit){
            if(this.validator.allValid() && this.validPhoneNumber(this.state.customer.phone1)){
                const payload = {
                    user: {
                        firstname: this.state?.customer?.name,
                        lastname: this.state?.user?.lastname || "",
                        phone: this.state?.customer?.phone1,
                        email: this.state?.customer?.email?.toLowerCase(),
                        username: this.state?.user?.username?.toLowerCase(),
                        password: this.state?.customer?.phone1,
                    },
                    customer: {
                        name: this.state?.customer?.name + " " + (this.state?.user?.lastname || ""),
                        phone1: this.state?.customer?.phone1,
                        phone2: this.state?.customer?.phone2 || "",
                        email: this.state?.customer?.email,
                        postalCode: this.state?.customer?.postalCode || "",
                        address: this.state?.customer?.address || "",
                        country: this.state?.customer?.country || "",
                        city: this.state?.customer?.city || "",
                    },
                    shopId: +this.state.shopId,
                };
                this.props.onSubmit(e, payload);
            }else{
                this.validator.showMessages();
            }
        }
    }


    render(){
        const validateFirstName = this.validator.message('Firstname', this.state.customer?.name, 'required', { className: 'text-danger' });
        const validatePhone = this.validator.message('Phone', this.state.customer?.phone1, 'required|integer|min:12|max:12', { className: 'text-danger' });
        // const validateEmail = this.validator.message('Email', this.state.customer?.email, 'required|email', { className: 'text-danger' });
        const validateUsername = this.validator.message('Username', this.state.user?.username, 'required', { className: 'text-danger' });
        // const validatePassword = this.validator.message('Password', this.state.user?.password, 'required', { className: 'text-danger' });
        // const validateConfirmPassword = this.validator.message('Confirm Password', this.state.confirmpassword, 'required', { className: 'text-danger' });

        return (
            <Form className="customer-form" onSubmit={this.submitForm}>
                <Row>
                    <Col>
                        <h5 className="title"> Personal informations </h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <FloatingLabel controlId="floatingFirstname" label={<span>Firstname | <i className="text-danger">required</i> </span> } className='mt-2 mb-3'>
                            { validateFirstName ? <span className="danger popup-up">{ validateFirstName }</span> : <></>}
                            <Form.Control 
                                type="text" 
                                name="name" 
                                value={this.state?.customer?.name} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPostalCode" label="Postal code" className='mt-2 mb-3'>
                            <Form.Control 
                                type="text" 
                                name="postalCode" 
                                value={this.state?.customer?.postalCode} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingCountry" label="Country" className='mt-2 mb-3'>
                            <Form.Control 
                                type="text" 
                                name="country" 
                                value={this.state?.customer?.country} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                    </Col>

                    <Col md={6} sm={12}>
                        <FloatingLabel controlId="floatingLastname" label="lastname" className='mt-2 mb-3'>
                            <Form.Control 
                                type="text" 
                                name="lastname" 
                                value={this.state?.user?.lastname} 
                                onChange={this.handleUserChange}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingAdress" label="Adress" className='mt-2 mb-3'>
                            <Form.Control 
                                type="text" 
                                name="address" 
                                value={this.state?.customer?.address} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingCity" label="City" className='mt-2 mb-3'>
                            <Form.Control 
                                type="text" 
                                name="city" 
                                value={this.state?.customer?.city} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className="title"> Credentials </h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Alert variant={'info'}> <u>Note</u> : The <b className="text-dark">Phone number</b> is used as <b className="text-danger">Password</b> </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} sm={12}>
                        <FloatingLabel controlId="floatingUsername" label={<span>Username | <i className="text-danger">required</i> </span> } className='mt-2 mb-3'>
                            { validateUsername ? <span className="danger popup-up">{ validateUsername }</span> : <></>}
                            <Form.Control 
                                type="text" 
                                name="username" 
                                value={this.state?.user?.username} 
                                onChange={this.handleUserChange}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md={4} sm={12}>
                        <FloatingLabel controlId="floatingEmail" label={<span>Email</span> } className='mt-2 mb-3'>
                            <Form.Control 
                                type="email" 
                                name="email" 
                                value={this.state?.customer?.email} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md={4} sm={12}>
                    <FloatingLabel controlId="floatingPhone1" label={<span>Phone :  <b>2376...</b>|<i className="text-danger">required</i> </span> } className='mt-2 mb-3'>
                        { validatePhone ? <span className="danger popup-up">{ validatePhone }</span> : <></>}
                            <Form.Control 
                                type="number" 
                                name="phone1" 
                                value={this.state?.customer?.phone1} 
                                onChange={this.handleCustomerChange}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <div className="separator mb-2"></div>
                <Row>
                    <Col md={{ span: 6, offset: 6 }} sm={12}>
                        <Button
                            type="submit"
                            variant="primary"
                            value={"CREATE ACCOUNT"}
                            className="m-0 form-control"
                            loading={this.props.loading}
                            onClick={this.submitForm}
                        />
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default CustomerForm
