import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const getSellers = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_EMPLOYEE } };
    return await axios.get(`sellers?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getOperationsServicesBySellerId = async (id, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`sellers/${+id}/history?pageSize=${pageSize}&page=${page}`, config);
}

export const _createSeller = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_EMPLOYEE } };
    return await axios.post(`sellers`, data, config);
}

export const _updateSeller = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_EMPLOYEE } };
    const id = data.id;
    delete data.id;
    return await axios.put(`sellers/${+id}`, data, config);
}

export const _deleteSeller = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_EMPLOYEE } };
    return await axios.delete(`sellers/${+id}`, config);
}
