import {
    SET_COUNT_CUSTOMERS, SET_COUNT_SELLERS, SET_COUNT_PRODUCTS, SET_COUNT_AVAILABLE_PRODUCTS,
    SET_COUNT_SERVICES, SET_MOST_SOLD_PRODUCTS, SET_MOST_SOLD_SERVICES, SET_BEST_CUSTOMERS,
    SET_BEST_SELLERS, SET_DAILY_INCOMES, SET_MONTHLY_INCOMES, SET_ANNUAL_INCOMES, SET_DAILY_INCOMES_MONTH,
    SET_MONTHLY_INCOMES_YEAR,
} from '../action-type/dashboardType';
  
export const setCountCustomers = (data) => ({
    type: SET_COUNT_CUSTOMERS,
    payload: data,
});

export const setCountSellers = (data) => ({
    type: SET_COUNT_SELLERS,
    payload: data,
});

export const setCountProducts = (data) => ({
    type: SET_COUNT_PRODUCTS,
    payload: data,
});

export const setCountAvailableProducts = (data) => ({
    type: SET_COUNT_AVAILABLE_PRODUCTS,
    payload: data,
});

export const setCountServices = (data) => ({
    type: SET_COUNT_SERVICES,
    payload: data,
});

export const setMostSoldProducts = (data) => ({
    type: SET_MOST_SOLD_PRODUCTS,
    payload: data,
});

export const setMostSoldServices = (data) => ({
    type: SET_MOST_SOLD_SERVICES,
    payload: data,
});

export const setBestCustomers = (data) => ({
    type: SET_BEST_CUSTOMERS,
    payload: data,
});

export const setBestSellers = (data) => ({
    type: SET_BEST_SELLERS,
    payload: data,
});

export const setDailyIncomes = (data) => ({
    type: SET_DAILY_INCOMES,
    payload: data,
});

export const setMonthlyIncomes = (data) => ({
    type: SET_MONTHLY_INCOMES,
    payload: data,
});

export const setAnnualIncomes = (data) => ({
    type: SET_ANNUAL_INCOMES,
    payload: data,
});

export const setDailyIncomesInMonth = (data) => ({
    type: SET_DAILY_INCOMES_MONTH,
    payload: data,
});

export const setMonthlyIncomesInYear = (data) => ({
    type: SET_MONTHLY_INCOMES_YEAR,
    payload: data,
});

