import React from "react";
import {Badge} from "react-bootstrap";

const LabelStatus = (props) => {

    const renderLabel = () => {
        // "PENDING", "VALIDATED", "REJECTED"
        switch(props?.status?.toUpperCase()){
            case "VALIDATED" : {
                return <Badge bg="success">{"VALIDATED"}</Badge>
            }
            case "REJECTED" : {
                return <Badge bg="danger">{"REJECTED"}</Badge>
            }
            default : {
                return <Badge bg="secondary">{"PENDING"}</Badge>
            }
        }
    }

    return (
        <>{renderLabel()}</>
    )
}

export default LabelStatus