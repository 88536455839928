import React from 'react';
import PageLayout from "../../../layouts/defaultContentPage";
import {PAGE} from "../../../constants/constants";
import SmsPageView from "../../../views/main/Sms";

import "./sms.css";

function Sms() {

  return (
      <PageLayout
      title={PAGE.SMS}
      rightElement={null}
      isRightVisible={false}
    >
        <SmsPageView />
    </PageLayout>
  )
}

export default Sms
