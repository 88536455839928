export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';

export const SET_RECORDS = 'SET_RECORDS';
export const ADD_RECORD = 'ADD_RECORD';
export const ADD_RECORDS_MULTIPLE = 'ADD_RECORDS_MULTIPLE';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const SELECT_RECORD = 'SELECT_RECORD';

export const SET_STATEMENTS = 'SET_STATEMENTS';
export const ADD_STATEMENTS_MULTIPLE = 'ADD_STATEMENTS_MULTIPLE';
export const SELECT_STATEMENT = 'SELECT_STATEMENT';
export const SET_RECONCICLED_BANK_RECORD_ITEM = 'SET_RECONCICLED_BANK_RECORD_ITEM';
