import {
    SET_ACCOUNTS, ADD_ACCOUNT, UPDATE_ACCOUNT, DELETE_ACCOUNT, SET_RECORDS, ADD_RECORD, ADD_RECORDS_MULTIPLE, UPDATE_RECORD, DELETE_RECORD, SELECT_RECORD, SELECT_ACCOUNT,
    SET_STATEMENTS, SELECT_STATEMENT, ADD_STATEMENTS_MULTIPLE, SET_RECONCICLED_BANK_RECORD_ITEM
} from '../action-type/accountType';

const INITIAL_STATE = {
    accounts: [],
    records: [],
    selectedAccount: {},
    selectedRecord: {},
    statements: [],
    selectedStatement: {},
};

const AccountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACCOUNTS: {
            if (action.payload) {
                return {
                    ...state,
                    accounts: action.payload
                }
            }
            return state;
        }
        case ADD_ACCOUNT: {
            if (action.payload) {
                return {
                    ...state,
                    accounts: [ action.payload, ...state.accounts]
                }
            }
            return state;
        }
        case UPDATE_ACCOUNT: {
            if (action.payload) {
                const i = state.accounts.findIndex(elm => elm.id === action.payload.id);
                state.accounts[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_ACCOUNT: {
            if (action.payload) {
                let {accounts} = state;
                const i = accounts.findIndex(elm => elm.id === action.payload);
                accounts.splice(i, 1);
                return { ...state, accounts }
            }
            return state;
        }
        case SET_RECORDS: {
            if (action.payload) {
                return {
                    ...state,
                    records: action.payload,
                }
            }
            return state;
        }
        case ADD_RECORD: {
            if (action.payload) {
                return {
                    ...state,
                    records: [action.payload, ...state.records],
                }
            }
            return state;
        }
        case ADD_RECORDS_MULTIPLE: {
            if (action.payload) {
                const data = state.records.concat(action.payload);
                return {
                    ...state,
                    records: data
                }
            }
            return state;
        }
        case UPDATE_RECORD: {
            if (action.payload) {
                const i = state.records.findIndex((element) => {
                    return element.id === action.payload.id;
                });
                state.records[i] = action.payload;
                return state;
            }
            return { ...state }
        }
        case DELETE_RECORD: {
            return {
                ...state,
                records: [...state.records.splice(+action.payload, 1)]
            }
        }
        case SELECT_RECORD: {
            return {
                ...state,
                selectedRecord: action.payload
            }
        }
        case SELECT_ACCOUNT: {
            return {
                ...state,
                selectedAccount: action.payload
            }
        }

        case SET_STATEMENTS: {
            if (action.payload) {
                return {
                    ...state,
                    statements: action.payload,
                }
            }
            return state;
        }
        case ADD_STATEMENTS_MULTIPLE: {
            if (action.payload) {
                const data = state.records.concat(action.payload);
                return {
                    ...state,
                    statements: data,
                }
            }
            return state;
        }
        case SELECT_STATEMENT: {
            return {
                ...state,
                selectedStatement: action.payload,
            }
        }
        case SET_RECONCICLED_BANK_RECORD_ITEM: {
            if (action.payload && action.payload?.id) {
                const data = state.records.map((item) => {
                    if (item.id === action.payload.id) return action.payload;
                    return item;
                });
                return { ...state, records: data };
            }
            return { ...state };
        }
        default:
            return state
    }
}

export default AccountReducer;