import React from 'react';

import "./footer.css";

function Footer() {
    return (
        <footer className="footer">
            <span className="copy">&copy; Copyright 2021. All rights reserved.</span>
        </footer>
    )
}

export default Footer;
