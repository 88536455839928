import React, {useState, useEffect, lazy, Suspense } from "react";
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Link } from "react-router-dom";
import {Modal, Container, Row, Col, } from "react-bootstrap";
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import {setAccounts, addAccount, updateAccount, deleteAccount, selectAccount} from "../../../../redux/action/AccountAction";
import { setSellers, } from "../../../../redux/action/SellersAction";
import { getSellers } from "../../../../services/Sellers";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import ModalRight from "../../../../Components/ModalRight";
import Input from "../../../../Components/CustomInput";
import Button from "../../../../Components/InputButton";
import BtnAdd from "../../../../Components/BtnAdd";
import Label from "../../../../Components/label";
import Loader from "../../../../Components/Loader";
import { FcFilingCabinet, FcPrivacy, FcUnlock, FcTreeStructure, FcRefresh, } from "react-icons/fc";
import { IoTrash } from "react-icons/io5";
import { getUsersAccounts, _createUserAccount, _updateUserAccount, _deleteUserAccount, _resetPassword } from "../../../../services/Account";

import "./usersAccounts.css";

const Table = lazy(() => import("../../../../Components/Table/GenericTable") );

function UsersAccounts (props) {
    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState("");
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [loadingForm, setLoadingForm] = useState(false);
    let [stateUserAccount, setStateUserAccount] = useState({});
    let [stateData, setStateData] = useState({});
    let [displayForm, setDisplayForm] = useState(null);
    let [byEmployee, setByEmployee] = useState(false);
    let [employee, setEmployee] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [dataReset, setDataReset] = useState({});

    const handleFilterChange = async (e) => {
        setFilter(e.target.value);
    }

    const handleUAChange = (e) => {
        stateUserAccount = {  ...stateUserAccount, [e.target.name] : e.target.value }
        setStateUserAccount(stateUserAccount);
    }

    const checkConfirmationPwd = (password, confPassword) => {
        return password === confPassword;
    }

    const createUserAccount = async (e) => {
        e.stopPropagation();
            setLoadingForm(true);
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_USER_ACCOUNT)){
                const checkedPwd = checkConfirmationPwd(stateUserAccount.password, stateUserAccount.confirmpassword);
                if(!checkedPwd){
                    toastr.error('Erreur', "Password does not match width the confirmation", {timeOut: 3000});
                    setLoadingForm(false);
                    return ;
                }
                if(!loading){
                    try{
                        const res = await _createUserAccount(stateUserAccount, +props.shop.id);
                        if(res.status === 200){
                            props.addAccount(res.data);
                            setStateUserAccount({});
                            setByEmployee(false);
                        }else{
                            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                        }
                        setLoadingForm(false);
                    }catch(e){
                        console.error("Erreur: ", e);
                        toastr.error('Erreur', e.message, {timeOut: 5000});
                        setLoadingForm(false);
                    }
                }
            }else{
                toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
            }
        
    }

    useEffect(() => {
        (async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_EMPLOYEE)) {
            setLoadingData(true);
            try {
              const res = await getSellers(filter, pageSize, page);
             
              if (res.status === 200) {
                setLoadingData(false);
                props.setSellers(res.data.rows);
              } else {
                setLoadingData(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e, {timeOut: 5000});
              setLoadingData(false);
            }
          }else{
            toastr.error('Erreur', "You do not have permission for this action!", {timeOut: 5000});
          }
        })()
        // eslint-disable-next-line
      }, []);

    const renderEmployees = () => {
        return props?.employees?.map((item, i) => {
            return (
                <option value={JSON.stringify(item)} key={i}>{item.name}</option>
            )
        })
    }

    const handleCheckBox = (e) => {
        setByEmployee(e.target.checked);
    }

    const handleEmpChange = (e) => {
        const selectedUser = JSON.parse(e.target.value);
        let user = {
            firstname: selectedUser.name,
            lastname: "",
            phone: selectedUser.phone1,
            email: selectedUser.email,
        };
        setStateUserAccount({...stateUserAccount, ...user});
        setEmployee(e.target.value);
    }

    const renderFormCreate = () => {
        return (
            <form name="userAccountCreateForm" className="m-2">
                <div className="form-group d-flex align-items-center">
                    <input className="checkbox mr-5" type="checkbox" name="byEmployee" id="byEmployee" value={byEmployee} onChange={handleCheckBox} />
                    <label htmlFor="byEmployee" className="label-input">Create account with an existing <b>employee</b> :</label>
                </div>

                { byEmployee ? <div className="form-group">
                    <label htmlFor="employee" className="label-input" style={{ color: "#1179AD" }}>Employees:</label>
                    <select className="form-control select" name="categoryProductId" id="categoryProductId" onChange={handleEmpChange} defaultValue={employee.id || ''}>
                        <option value="">Select an employee...</option>
                        { renderEmployees() }
                    </select>
                </div> : <></> }

                <div className="form-group">
                    <label htmlFor="firstname" className="label-input">Firstname:</label>
                    <input className="form-control" type="text" name="firstname" id="firstname" value={stateUserAccount.firstname || ""} onChange={handleUAChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="lastname" className="label-input">Lastname:</label>
                    <input className="form-control"  type="text" name="lastname" id="lastname" value={stateUserAccount.lastname || ""} onChange={handleUAChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="label-input">Phone:</label>
                    <input className="form-control"  type="text" name="phone" id="phone" value={stateUserAccount.phone || ""} onChange={handleUAChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="label-input">
                        Email:
                    </label>
                    <input className="form-control"  type="email" name="email" id="email" value={stateUserAccount.email || ""} onChange={handleUAChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="password" className="label-input">Password:</label>
                    <input className="form-control"  type="password" name="password" id="password" value={stateUserAccount.password || ""} onChange={handleUAChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmpassword" className="label-input">Confirm Password:</label>
                    <input className="form-control"  type="password" name="confirmpassword" id="confirmpassword" value={stateUserAccount.confirmpassword || ""} onChange={handleUAChange} required />
                </div>
                <div className="separator mb-2 mt-2"></div>
                <div className="form-group">
                    <Button
                        variant="primary"
                        value="Submit"
                        className="m-0 form-control"
                        loading={loadingForm}
                        onClick={createUserAccount}
                    />
                </div>
            </form>
        )
    }

    const renderDetailAccount = () => {
        return (
            <div className="p-2">
                <div className="item-group">
                    <div className="item-left">Role</div>
                    <div className="item-right">
                        {
                            props.selectedAccount?.userGroups?.length ?
                            renderRoles(props.selectedAccount?.userGroups) :
                            <></>
                        }
                    </div>
                </div>
                <div className="item-group">
                    <div className="item-left">Firstname</div>
                    <div className="item-right">{props.selectedAccount.firstname}</div>
                </div>
                <div className="item-group">
                    <div className="item-left">Lastname</div>
                    <div className="item-right">{props.selectedAccount.lastname}</div>
                </div>{/* 
                <div className="item-group">
                    <div className="item-left">Birth date</div>
                    <div className="item-right">{ props.selectedAccount.birthdate ? moment(props.selectedAccount.birthdate).format("YYYY/MM/DD") : <></> }</div>
                </div> */}
                <div className="item-group">
                    <div className="item-left">Phone</div>
                    <div className="item-right">{props.selectedAccount.phone}</div>
                </div>
                <div className="item-group">
                    <div className="item-left">Email</div>
                    <div className="item-right">{props.selectedAccount.email}</div>
                </div>
                <div className="item-group">
                    <div className="item-left">Phone</div>
                    <div className="item-right">{props.selectedAccount.phone}</div>
                </div>
            </div>
        )
    }

    const renderRightModal = () => {
        switch (displayForm) {
            case "new" : {
                return(
                    <ModalRight
                        title="New user account"
                        content={renderFormCreate()}
                        isVisible={isRightVisible}
                        onClose={(e) => setIsRightVisible(false)}
                    />
                )
            }
            case "preview" : {
                return(
                    <ModalRight
                        title="User account detail"
                        content={renderDetailAccount()}
                        isVisible={isRightVisible}
                        onClose={(e) => setIsRightVisible(false)}
                    />
                )
            }

            default : return <></>
        }
    }

    const displayCreateUserAccountForm = (e) => {
        e.preventDefault();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_USER_ACCOUNT)){
            setIsRightVisible(true);
            setDisplayForm("new");
        }
    }

    const toggleLockAccount = async (e, status) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CHANGE_STATUS_USER_ACCOUNT)){
            const toastrConfirmOptions = {
                onOk: () => changeStatus(),
                onCancel: () => console.log('CANCEL: clicked')
            };
            toastr.confirm("Do you really want to change status of this user accout?", toastrConfirmOptions)
        }
    }

    const PrecessResetPassword = async (e, id) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.RESET_PASSWORD_USER_ACCOUNT)){
            const toastrConfirmOptions = {
                onOk: () => resetPassword(id),
                onCancel: () => console.log('RESET PASSWORD: Canceled!')
            };
            toastr.confirm("Do you really want to reset passworf for this user accout?", toastrConfirmOptions)
        }
    }

    const resetPassword = async (id) => {
        setLoading(true);
        try{
            const res = await _resetPassword(+id);
            if(res.status === 200){
                setLoading(false);
                props.updateAccount(res.data);
                setDataReset(res.data);
                setShowModal(true);
            }else{
                setLoading(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
        }
    }

    const deleteAccount = (e, id) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_USER_ACCOUNT)){
            const toastrConfirmOptions = {
                onOk: () => delAccount(id),
                onCancel: () => {}
            };
            toastr.confirm("Do you really want to delete this user accout?", toastrConfirmOptions);
        }
    }

    const changeStatus = async (data) => {
        setLoading(true);
        try{
            const res = await _updateUserAccount(data);
            if(res.status === 200){
                setLoading(false);
                props.updateAccount(data);
            }else{
                setLoading(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
        }catch(e){
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
        }
    }

    const delAccount = async (id) => {
        setLoading(true);
        try{
            const res = await _deleteUserAccount(id);
            if(res.status === 200){
                setLoading(false);
                props.deleteAccount(id);
            }else{
                setLoading(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
        }catch(e){
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
        }
    }

    const selectElement = (item) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT)){
            props.selectAccount(item?.originalData);
            setIsRightVisible(true);
            setDisplayForm("preview");
        }
    }

    useEffect(() => {
        (async () => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT)){
                setLoadingData(true);
                try {
                    const res = await getUsersAccounts(filter, 20, 1);
                    setStateData({
                        currentPage: res.data.pager.currentPage,
                        pageSize: res.data.pager.pageSize,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                    });
                    if(res.status === 200){
                        setLoadingData(false);
                        props.setAccounts(res.data.rows);
                    }else{
                        setLoadingData(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                    console.log({stateData});
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e.message, {timeOut: 5000});
                    setLoadingData(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, [filter]);

    const stopPropagationEvent = (e) => {
        e.stopPropagation();
    }

    const renderAction = (item) => {
        return (
            <div className="">
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER) ?
                <button className="btn btn-action mr-1" title="Assign Group User"  onClick={(e) => stopPropagationEvent(e)}>
                    <Link to={`${item.id}/groups`}>
                        <FcTreeStructure />
                    </Link>
                </button> :
                <></>}
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CHANGE_STATUS_USER_ACCOUNT) ?
                    <button className="btn btn-action" onClick={(e) => toggleLockAccount(e, !item.active)}>
                        {item.active ? <FcPrivacy /> : <FcUnlock />}
                    </button> :
                    <></> }
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.RESET_PASSWORD_USER_ACCOUNT) ?
                    <button className="btn btn-action" onClick={(e) => PrecessResetPassword(e, item.id)}>
                        <FcRefresh />
                    </button> :
                    <></> }
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_USER_ACCOUNT) ?
                    <button className="btn btn-action"  onClick={(e) => deleteAccount(e, item.id)}> <IoTrash /> </button> :
                    <></>}
            </div>
        )
    }

    const renderRoles = (roles = []) => {
        return roles.map((role, idx) => {
            return (
                <Label key={`role-${idx}`} icon={FcFilingCabinet} statement={role?.group?.description} className="role-tag" />
            )
        })
    } 

    const headerData = [
        { title: <div className="size-30 flex-center  rounded"> # </div> , align: "center", className: 'sm-hide', width: 50, name: "number"},
        { title: "Firstname"  , align: "left", className: 'pl-15', width: "auto", name: "firstname"},
        { title: "Lastname"  , align: "left", className: 'pl-15 sm-hide', width: "auto", name: "lastname"},
        { title: "Email"  , align: "left", className: 'pl-15 sm-hide', width: "auto", name: "email"},
        { title: "Username"  , align: "left", className: 'pl-15 sm-hide', width: "auto", name: "username"},
        { title: "Role"  , align: "left", className: 'pl-15', width: "auto", name: "role"},
        { title: <> Actions </> , align: "left", className: '', width: "150px", name: "action"},
    ];

    const formatBodyData = (data) => {
        return data?.map((item, i) => {
            let resData = {
                originalData: item,
                number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i+1} </div>,
                firstname: item.firstname,
                lastname: item.lastname,
                email: item.email,
                username: item.username,
                role:  item?.userGroups?.length ? renderRoles(item?.userGroups) : <></> ,
                action: renderAction(item),
            }
            return resData;
        })
    }

    const renderResetMessage = (data) => {
        return (
            <>
            <p className="text-center">
                Password for this user was reset successfully! Please send this password to the user <span className="text-primary">{`${data.firstname} ${data.lastname}`}</span>
            </p>
            <p className="text-center" style={{fontSize: "2rem", color: "#333333"}}>{data.password}</p>
            </>
        )
    }

    return (
        <>
            <div className="users-account-view">
                <div className="page-action">
                    <div className="d-flex align-items-center">
                        <BtnAdd
                            statement="New User"
                            onClick={(e) => displayCreateUserAccountForm(e)}
                            disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_USER_ACCOUNT)}
                            className="mr-2"
                            style={{marginRight: 5}}
                        />
                        <Loader loading={loading} />
                    </div>
                    <div className="search-content-form">
                        <Input
                            type="text"
                            globalClassName="search-input"
                            name="filter"
                            value={filter}
                            placeholder="Enter a keyword..."
                            onChange={handleFilterChange}
                        />
                    </div>
                </div>

                <Suspense fallback={<Loader loading />} >
                    <Table
                        header={headerData}
                        body={formatBodyData(props.usersAccounts)}
                        className=''
                        hoverColor="rgba(0, 0, 0, 0.05)"
                        isLoading={loadingData}
                        asLoader={<Loader loading />}
                        onSelect={(item) => selectElement(item)}
                        selectedClass="active"
                    />
                    {/* { this.renderPaginarion() } */}
                </Suspense>

            </div>
            { renderRightModal() }

            {/* reset password modal */}
            <Modal show={showModal}  onHide={() => {}}>
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center justify-content-between">
                    <span>RESET PASSWORD {``}</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col>
                      <div className="modal-content-data">
                        {renderResetMessage(dataReset)}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <button 
                  type='button' 
                  className='btn btn-info' 
                  onClick={() => setShowModal(false)}
                >Close</button>
              </Modal.Footer>
            </Modal>

        </>

    )
}

const mapStateToProps = (state) => {
    const {accounts, sellers, auth} = state;
    return {
        usersAccounts : accounts.accounts,
        selectedAccount : accounts.selectedAccount,
        employees: sellers.sellers, 
        shop : auth?.authData?.shop,
    }
}

export default connect(mapStateToProps, { setAccounts, addAccount, updateAccount, deleteAccount, selectAccount, setSellers })(UsersAccounts);
