export default class Storage {

    /**
     * Storage to set key value
     * @param {string} key 
     * @param {*} value 
     * @returns void
     */
    static Set(key, value = ""){
        if(key){
            value = JSON.stringify(value);
            localStorage.setItem(key, value);
        }
    }

    /**
     * Storage to get value with key
     * @param {string} key 
     * @returns *
     */
    static Get(key){
        const res = localStorage.getItem(key);
        if(res){ return JSON.parse(res);}
        return null;
    }

    /**
     * Storage to remove value with key
     * @param {string} key 
     * @returns void
     */
    static Clear(key){
        localStorage.removeItem(key);
    }

    /**
     * Remove all keys pairs to the storage
     */
    static ClearAll(){
        localStorage.clear();
    }
}