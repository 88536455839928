import React from 'react';
import PageLayout from "../../../layouts/defaultContentPage";
import {PAGE} from "../../../constants/constants";
import DashboardView from "../../../views/main/Dashboard";

import "./dashboard.css";

export default function DashboardPage() {
  return (
    <PageLayout
      title={PAGE.DASHBOARD}
      rightElement={null}
      isRightVisible={false}
    >
      <DashboardView />
    </PageLayout>
  );
}
