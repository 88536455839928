import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const getUsersAccounts = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT } };
    return await axios.get(`admin/users?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getUsersAccountById = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT } };
    return await axios.get(`users/${+id}`, config);
}

export const _createUserAccount = async (data, shopId) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_USER_ACCOUNT } };
    return await axios.post(`admin/register/${shopId}`, data, config);
}

export const _updateUserAccount = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_USER_ACCOUNT } };
    return await axios.put(`user`, data, config);
}

export const _resetPassword = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.RESET_PASSWORD_USER_ACCOUNT } };
    return await axios.put(`admin/users/${id}/reset-password`, {}, config);
}

export const _deleteUserAccount = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_USER_ACCOUNT } };
    return await axios.delete(`admin/${id}`, config);
}