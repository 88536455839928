import React from "react";

import "./LabelRadio.css";

function LabelRadio ({name, statement, checked, className, onChange, id}) {

    // let [valueLabel, setValueLabel] = useState(value);
    // let [checked, setChecked] = useState(false);

    return (
        <button className={`label-radio ${checked ? "active" : ""} ${className ? className : ""}`}>
            <label htmlFor={`${id}-statement`}>
                <input type="checkbox" name={`${name}`} id={`${id}-statement`} onChange={onChange} checked={checked} className="checkbox" />
                <span className="statement">{statement}</span>
            </label>            
        </button>
    )
}

export default LabelRadio;
