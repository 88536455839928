export const SESSION_STORAGE_KEYS = {
    DEFAULT_SHOP : "shop",
    TOKEN: "token",
    ROLE: "role",
    HABILITATIONS: "habilitations",
    CASHIER_OPERATIONS: "CASHIER_OPERATIONS",
    ORDER_OPERATIONS: "ORDER_OPERATIONS",
}

export const PATHS = {
    UNAUTHORIZED_PATH: "/main/dashboard",
    FETCH_DATA: "/fetch-data",
    AUTH_FAILED_URL: "/",
}

export const PAGE = {
    DASHBOARD: "DASHBOARD",
    CUSTOMERS: "CUSTOMERS",
    EMPLOYEES: "EMPLOYEES",
    BILLING: "BILLING",
    STOCK: "STOCK",
    SELLING: "SELLING",
    INVENTORY: "INVENTORY",
    SERVICES: "SERVICES",
    CASHIER: "CASHIER",
    // 
    ACCOUNT_MANAGEMENT: "ACCOUNT MANAGEMENT",
    USER_ACCOUNTS: "USER ACCOUNTS",
    GROUPS: "GROUPS",
    RIGHTS: "RIGHTS",
}

export const ORGANIZATION_USER_STATUS = {
    PENDING: "En attente",
    ACTIVE: "Actif",
    CANCELED: "Annulé",
}

export const TAG_TYPES = {
    ROW: "row",
}

export const mapStatusToVariantLabel = (status) => {
    switch (status) {
        case ORGANIZATION_USER_STATUS.ACTIVE:
            return "success"
        case ORGANIZATION_USER_STATUS.PENDING:
            return "warning"
        case ORGANIZATION_USER_STATUS.CANCELED:
            return "danger"
        default:
            return "success"
    }
}

export const ContentTypes = {
  pdf: "application/pdf",
  jpg: "image/jpg",
  png: "image/png",
  jpeg: "image/jpeg",
}

export const PAGINATION = {
    DEFAULT_PAGE_SIZE: 40,
    DEFAULT_PAGE: 1
}

export const REGEX = {
    PHONE: '^[623][0-9]{8}$',
}

export const BILL = {
    VAT: 19.25,
}

export const MESURE_UNIT_OPTIONS = [
    {name: "Piece"},
    {name: "Dizaine"},
    {name: "Paquet"},
    {name: "Carton"},
    {name: "Sac"},
    {name: "Tas"},
    {name: "Jour"},
];

export const ACCOUNT_TYPE = {
    BANQUE: "Banque",
    CAISSE: "Caisse",
};

export const SEARCH_KEY_TYPE = {
    PRODUCT : "PRODUCT",
    SERVICE : "SERVICE",
}

export const PAYMENT_MODE = {
    CASH: "CASH",
    ORANGE_MONEY: "ORANGE MONEY",
    MTN_MOMO: "MTN MOMO",
}

export const SERVICE = {
    TIP_RATE: 5,
}