import { ADD_RIGHT, ADD_RIGHTS, SET_RIGHTS, UPDATE_RIGHT, DELETE_RIGHT, SELECT_RIGHT} from '../action-type/rightType';

const INITIAL_STATE = {
    rights : [],
    selectedRight : {},
};

const RightReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_RIGHTS : {
            return { ...state, rights: action.payload };
        }
        case ADD_RIGHT: {
            if (action.payload) {
                return {
                    ...state,
                    rights: [ action.payload, ...state.rights],
                }
            }
            return state;
        }
        case ADD_RIGHTS: {
            if (action.payload) {
                const data = state.rights.concat(action.payload);
                return { ...state, rights: data, }
            }
            return state;
        }
        case UPDATE_RIGHT: {
            if (action.payload) {
                const i = state.rights.findIndex(elm => elm.id === action.payload.id);
                state.rights[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_RIGHT: {
            if (action.payload) {
                let {rights} = state;
                const i = rights.findIndex(elm => elm.id === action.payload);
                rights.splice(i, 1);
                return {...state, rights}
            }
            return state;
        }
        case SELECT_RIGHT: {
            return {
                ...state,
                selectedRight: action.payload
            }
        }
        default: return state;
    }
}

export default RightReducer;