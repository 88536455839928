import React from 'react';
import { Outlet } from 'react-router-dom';
import Left from "../../views/navlink";
import Content from "../../views/content";

import "./main.css";

export const main = () => {
    return (
        <div className="main-layout">
            <Left />
            <Content>
                <Outlet />
            </Content>    
        </div>
    )
}


export default main;
