import { 
    SET_CASHIER_OPERATIONS, SELECT_CASHIER_OPERATION, ADD_CASHIER_OPERATION, ADD_CASHIER_OPERATIONS, DELETE_CASHIER_OPERATION,
    SET_CASHIER_OPERATION_LINES, ADD_CASHIER_OPERATION_LINE, UPDATE_CASHIER_OPERATION_LINE, DELETE_CASHIER_OPERATION_LINES, SELECT_CASHIER_OPERATION_LINE,
} from '../action-type/cashierType';

export const setCashierOperations = (data) => ({
    type: SET_CASHIER_OPERATIONS,
    payload: data
})

export const selectCashierOperation = (data) => ({
    type: SELECT_CASHIER_OPERATION,
    payload: data
})

export const deleteCashierOperation = (id) => ({
    type: DELETE_CASHIER_OPERATION,
    payload: id
})

export const addCashierOperation = (data) => ({
    type: ADD_CASHIER_OPERATION,
    payload: data
})

export const addCashierOperations = (data) => ({
    type: ADD_CASHIER_OPERATIONS,
    payload: data
})

export const setCashierOperationLines = (data) => ({
    type: SET_CASHIER_OPERATION_LINES,
    payload: data
})

export const selectCashierOperationLines = (data) => ({
    type: SELECT_CASHIER_OPERATION_LINE,
    payload: data
})

export const addCashierOperationLine = (data) => ({
    type: ADD_CASHIER_OPERATION_LINE,
    payload: data
})

export const updateCashierOperationLine = (data) => ({
    type: UPDATE_CASHIER_OPERATION_LINE,
    payload: data
})

export const deleteCashierOperationLine = (data) => ({
    type: DELETE_CASHIER_OPERATION_LINES,
    payload: data
})
