import {SET_DEFAULT_ITEM, SET_ACTIVE_PAGE, SET_ACTIVE_PAGE_BY_ROUTE_NAME, TOGGLE_NAVLINK } from '../action-type/navType';

export const setDefaultItem = (elementIndex) => ({
    type: SET_DEFAULT_ITEM,
    payload: elementIndex,
});

export const setActivePage = (i) => ({
    type: SET_ACTIVE_PAGE,
    payload: i,
});

export const setActivePageByPathRoute = (routeName) => ({
    type: SET_ACTIVE_PAGE_BY_ROUTE_NAME,
    payload: routeName,
});

export const toggleNavlink = () => ({
    type: TOGGLE_NAVLINK,
    payload: null,
});