import { 
    SET_ORDER_OPERATIONS, SELECT_ORDER_OPERATION, ADD_ORDER_OPERATION, ADD_ORDER_OPERATIONS, DELETE_ORDER_OPERATION,
    SET_ORDER_OPERATION_LINES, ADD_ORDER_OPERATION_LINE,UPDATE_ORDER_OPERATION, UPDATE_ORDER_OPERATION_LINE, DELETE_ORDER_OPERATION_LINES, SELECT_ORDER_OPERATION_LINE,
} from '../action-type/orderType';

export const setOrderOperations = (data) => ({
    type: SET_ORDER_OPERATIONS,
    payload: data
})

export const selectOrderOperation = (data) => ({
    type: SELECT_ORDER_OPERATION,
    payload: data
})

export const updateOrderOperation = (data) => ({
    type: UPDATE_ORDER_OPERATION,
    payload: data
})

export const deleteOrderOperation = (id) => ({
    type: DELETE_ORDER_OPERATION,
    payload: id
})

export const addOrderOperation = (data) => ({
    type: ADD_ORDER_OPERATION,
    payload: data
})

export const addOrderOperations = (data) => ({
    type: ADD_ORDER_OPERATIONS,
    payload: data
})

export const setOrderOperationLines = (data) => ({
    type: SET_ORDER_OPERATION_LINES,
    payload: data
})

export const selectOrderOperationLines = (data) => ({
    type: SELECT_ORDER_OPERATION_LINE,
    payload: data
})

export const addOrderOperationLine = (data) => ({
    type: ADD_ORDER_OPERATION_LINE,
    payload: data
})

export const updateOrderOperationLine = (data) => ({
    type: UPDATE_ORDER_OPERATION_LINE,
    payload: data
})

export const deleteOrderOperationLine = (data) => ({
    type: DELETE_ORDER_OPERATION_LINES,
    payload: data
})
