import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getCountCustomers = async () => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_COUNT_CUSTOMERS } };
    return await axios.get(`dashboard/customers`, config);
}

export const _getCountSellers = async () => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_COUNT_EMPLOYEES } };
    return await axios.get(`dashboard/sellers`, config);
}

export const _getCountProducts = async () => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_COUNT_PRODUCTS_INVENTORY } };
    return await axios.get(`dashboard/products`, config);
}

export const _getCountAvailableProducts = async () => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY } };
    return await axios.get(`dashboard/available-products`, config);
}

export const _getCountServices = async () => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_COUNT_SERVICES } };
    return await axios.get(`dashboard/services`, config);
}

export const _getMostSoldProducts = async (startDate, endDate, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_PRODUCTS } };
    return await axios.get(`dashboard/mosts-sold-products?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getMostSoldServices = async (startDate, endDate, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_SERVICES } };
    return await axios.get(`dashboard/mosts-sold-services?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getBestSellers = async (startDate, endDate, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_BEST_SELLERS } };
    return await axios.get(`dashboard/best-sellers?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getBestCustomers = async (startDate, endDate, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_BEST_CUSTOMERS } };
    return await axios.get(`dashboard/best-customers?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getDailyIncomes = async (startDate, endDate) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES } };
    return await axios.get(`dashboard/daily-incomes?startDate=${startDate}&endDate=${endDate}`, config);
}

export const _getMonthlyIncomes = async (startDate, endDate) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES } };
    return await axios.get(`dashboard/monthly-incomes?startDate=${startDate}&endDate=${endDate}`, config);
}

export const _getAnnualIncomes = async (startDate, endDate) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_ANNUAL_INCOMES } };
    return await axios.get(`dashboard/annual-incomes?startDate=${startDate}&endDate=${endDate}`, config);
}

export const _getDailyIncomesInMounth = async (startDate, endDate) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES_MONTH } };
    return await axios.get(`dashboard/daily-incomes-month?startDate=${startDate}&endDate=${endDate}`, config);
}

export const _getMonthlyIncomesInYear = async (startDate, endDate) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES_YEAR } };
    return await axios.get(`dashboard/monthly-incomes-year?startDate=${startDate}&endDate=${endDate}`, config);
}