import {
  SET_CUSTOMERS,
  ADD_CUSTOMERS, UPDATE_CUSTOMERS, SELECT_CUSTOMERS, DELETE_CUSTOMERS
} from '../action-type/customersType';

export const setCustomers = (data) => ({
  type: SET_CUSTOMERS,
  payload: data,
});

export const addCustomer = (data) => ({
  type: ADD_CUSTOMERS,
  payload: data,
});

export const updateCustomer = (data) => ({
  type: UPDATE_CUSTOMERS,
  payload: data,
});

export const selectCustomer = (data) => ({
  type: SELECT_CUSTOMERS,
  payload: data,
});

export const deleteCustomer = (data) => ({
  type: DELETE_CUSTOMERS,
  payload: data
})
