import { SET_DEFAULT_ITEM, SET_ACTIVE_PAGE, SET_ACTIVE_PAGE_BY_ROUTE_NAME, TOGGLE_NAVLINK } from "../action-type/navType";
// import {FUNCTIONALITIES_CODE} from "../../constants/AuthConstants";
import { ROUTES_APP } from "../../routes/routes-list";

const INITIAL_STATE = {
    items: [ ...ROUTES_APP ],
    activeItem: { ...ROUTES_APP[0] },
    isVisible: false,
};

const NavReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DEFAULT_ITEM : {
            let {items, activeItem} = state;
            const i = items.findIndex(elm => elm.id === action.payload); // This is for remove the "user" Navlink...
            // if(i >= 0) items.splice(i, 1); // that create the overflow in the Navlink bar.
            items.map((element) => (
                element.isActive = false
            ))
            items[i].isActive = true;
            activeItem = items[i];
            state = {...state, items, activeItem };
            return state; 
        }
        case SET_ACTIVE_PAGE : {
            let {items, activeItem} = state;
            items.map((element) => (
                element.isActive = false
            ))
            items[action.payload].isActive = true;
            activeItem = items[action.payload];

            return {...state, items, activeItem }; 
        }
        case SET_ACTIVE_PAGE_BY_ROUTE_NAME : {
            let {items, activeItem} = state;
            items.map((element) => (
                element.isActive = false
            ))
            const i = items.findIndex(elm => elm.path === action.payload);
            if(i >= 0) {
                items[i].isActive = true;
                activeItem = items[i]; 
            }
            return {...state, items, activeItem }; 
        }
        case TOGGLE_NAVLINK : {
            return { ...state, isVisible: !state.isVisible };
        }
        default: 
            return state;
    };
};

export default NavReducer;