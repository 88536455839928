import {
  SET_EMPLOYEES,
  ADD_EMPLOYEES, UPDATE_EMPLOYEES, SELECT_EMPLOYEES, DELETE_EMPLOYEES
} from '../action-type/sellersType';

export const setSellers = (data) => ({
  type: SET_EMPLOYEES,
  payload: data,
});

export const addSeller = (data) => ({
  type: ADD_EMPLOYEES,
  payload: data,
});

export const updateSeller = (data) => ({
  type: UPDATE_EMPLOYEES,
  payload: data,
});

export const selectSeller = (data) => ({
  type: SELECT_EMPLOYEES,
  payload: data,
});

export const deleteSeller = (data) => ({
  type: DELETE_EMPLOYEES,
  payload: data
});
