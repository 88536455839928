import React from "react";
import Label from "../label";
import { FcInfo,  } from "react-icons/fc";
import { IoIosClose } from "react-icons/io";

import "./modalRight.css";

function ModalRight({className, title, isVisible, content, footer, style, onClose}) {

    return ( isVisible &&
        <div className={`right-content-page ${className ? className : ""}`} style={style ? style : {}}>
            <div className="header-right">
                <Label icon={FcInfo} statement={`${title}`} />
                <button type="button" className="btn" onClick={(e) => onClose(e)}>
                    <IoIosClose className="icon-close" />
                </button>
            </div>
            { content && <div className="content-right"> {content} </div>}
            { footer && <div className="footer-right"> {footer} </div>}
        </div>
    )
}

export default ModalRight;
