import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getEmployeeFunctions = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_FUNCTION_EMPLOYEE } };
    return await axios.get(`employee-functions?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _createEmployeeFunction = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_FUNCTION_EMPLOYEE } };
    return await axios.post(`employee-functions`, data, config);
}

export const _updateEmployeeFunction = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_FUNCTION_EMPLOYEE } };
    return await axios.put(`employee-functions/${id}`, data, config);
}


export const _deleteEmployeeFunction = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_FUNCTION_EMPLOYEE } };
    return await axios.delete(`employee-functions/${id}`, config);
}