export const DEVICES = {
    mobile: 'mobile'
}
export const ATUH_TAG = {
    forgot: 'forgot',
    confirm: 'confirm',
}

export const FUNCTIONALITIES_CODE = {

    MENU_BILLS : "MENU_BILLS",
    
    DASHBOARD: 'DASHBOARD',
    DASHBOARD_COUNT_CUSTOMERS: 'DASHBOARD_COUNT_CUSTOMERS',
    DASHBOARD_COUNT_EMPLOYEES: 'DASHBOARD_COUNT_EMPLOYEES',
    DASHBOARD_COUNT_PRODUCTS_INVENTORY: 'DASHBOARD_COUNT_PRODUCTS_INVENTORY',
    DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY: 'DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY',
    DASHBOARD_COUNT_SERVICES: 'DASHBOARD_COUNT_SERVICES',
    DASHBOARD_MOST_SOLD_PRODUCTS: 'DASHBOARD_MOST_SOLD_PRODUCTS',
    DASHBOARD_MOST_SOLD_SERVICES: 'DASHBOARD_MOST_SOLD_SERVICES',
    DASHBOARD_BEST_CUSTOMERS: 'DASHBOARD_BEST_CUSTOMERS',
    DASHBOARD_BEST_SELLERS: 'DASHBOARD_BEST_SELLERS',
    DASHBOARD_DAILY_INCOMES: 'DASHBOARD_DAILY_INCOMES',
    DASHBOARD_MONTHLY_INCOMES: 'DASHBOARD_MONTHLY_INCOMES',
    DASHBOARD_ANNUAL_INCOMES: 'DASHBOARD_ANNUAL_INCOMES',
    DASHBOARD_DAILY_INCOMES_MONTH: 'DASHBOARD_DAILY_INCOMES_MONTH',
    DASHBOARD_MONTHLY_INCOMES_YEAR: 'DASHBOARD_MONTHLY_INCOMES_YEAR',
    DASHBOARD_INCOMES_OF_YEARS: 'DASHBOARD_INCOMES_OF_YEARS',
    
    SHOP : "SHOP",
    SHOP_LIST : "SHOP_LIST",
    SHOP_CREATE : "SHOP_CREATE",
    SHOP_UPDATE : "SHOP_UPDATE",
    SHOP_DELETE : "SHOP_DELETE",
    
    INVOICES : "INVOICES",
    INVOICES_CREATE : "INVOICES_CREATE",
    INVOICES_DELETE : "INVOICES_DELETE",
    INVOICES_UPDATE : "INVOICES_UPDATE",
    INVOICES_LIST : "INVOICES_LIST",
    
    BILL : "BILL",
    BILL_CREATE : "BILL_CREATE",
    BILL_DELETE : "BILL_DELETE",
    BILL_UPDATE : "BILL_UPDATE",
    BILL_LIST : "BILL_LIST",
    
    SALE : "SALE",
    // ACCOUNT FEATURES
    ACCOUNT_MANAGEMENT: 'ACCOUNT_MANAGEMENT',

    USERS_ACCOUNTS: "USERS_ACCOUNTS",
    CREATE_USER_ACCOUNT: "CREATE_USER_ACCOUNT",
    UPDATE_USER_ACCOUNT: "UPDATE_USER_ACCOUNT",
    DELETE_USER_ACCOUNT: "DELETE_USER_ACCOUNT",
    CHANGE_STATUS_USER_ACCOUNT: "CHANGE_STATUS_USER_ACCOUNT",
    LIST_USER_ACCOUNT: "LIST_USER_ACCOUNT",
    RESET_PASSWORD_USER_ACCOUNT: "RESET_PASSWORD_USER_ACCOUNT",

    GROUP_RIGHT: "GROUP_RIGHT",
    CREATE_GROUP: "CREATE_GROUP",
    UPDATE_GROUP: "UPDATE_GROUP",
    DELETE_GROUP: "DELETE_GROUP",
    LIST_GROUP: "LIST_GROUP",

    RIGHT: "RIGHT",
    CREATE_RIGHT: "CREATE_RIGHT",
    UPDATE_RIGHT: "UPDATE_RIGHT",
    DELETE_RIGHT: "DELETE_RIGHT",
    LIST_RIGHT: "LIST_RIGHT",

    ASSIGN_GROUP_RIGHT_TO_USER: "ASSIGN_GROUP_RIGHT_TO_USER",
    ASSIGN_RIGHTS_TO_GROUP_RIGHT: "ASSIGN_RIGHTS_TO_GROUP_RIGHT",
    ASSIGN_RIGHTS_TO_USER_ACCOUNT: "ASSIGN_RIGHTS_TO_USER_ACCOUNT",

    CUSTOMER: "CUSTOMER",
    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
    DELETE_CUSTOMER: "DELETE_CUSTOMER",
    LIST_CUSTOMER: "LIST_CUSTOMER",
    PREVIEW_CUSTOMER: "PREVIEW_CUSTOMER",
    HISTORY_CUSTOMER: "HISTORY_CUSTOMER",
    PRINT_HISTORY_CUSTOMER: "PRINT_HISTORY_CUSTOMER",

    CUSTOMER_SHOP: "CUSTOMER_SHOP",
    CREATE_CUSTOMER_ORDER: "CREATE_CUSTOMER_ORDER",
    UPDATE_CUSTOMER_ORDER: "UPDATE_CUSTOMER_ORDER",
    DELETE_CUSTOMER_ORDER: "DELETE_CUSTOMER_ORDER",
    LIST_CUSTOMER_ORDER: "LIST_CUSTOMER_ORDER",
    PREVIEW_CUSTOMER_ORDER: "PREVIEW_CUSTOMER_ORDER",

    EMPLOYEE: "EMPLOYEE",
    CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
    UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
    DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
    LIST_EMPLOYEE: "LIST_EMPLOYEE",
    PREVIEW_EMPLOYEE: "PREVIEW_EMPLOYEE",
    HISTORY_EMPLOYEE: "HISTORY_EMPLOYEE",
    PRINT_HISTORY_EMPLOYEE: "PRINT_HISTORY_EMPLOYEE",

    FUNCTION_EMPLOYEE: "FUNCTION_EMPLOYEE",
    CREATE_FUNCTION_EMPLOYEE: "CREATE_FUNCTION_EMPLOYEE",
    UPDATE_FUNCTION_EMPLOYEE: "UPDATE_FUNCTION_EMPLOYEE",
    DELETE_FUNCTION_EMPLOYEE: "DELETE_FUNCTION_EMPLOYEE",
    LIST_FUNCTION_EMPLOYEE: "LIST_FUNCTION_EMPLOYEE",

    CASHIER: "CASHIER",
    PRINT_CASHIER_TICKET: "PRINT_CASHIER_TICKET",
    CREATE_CASHIER_OPERATION: "CREATE_CASHIER_OPERATION",
    UPDATE_CASHIER_OPERATION: "UPDATE_CASHIER_OPERATION",
    DELETE_CASHIER_OPERATION: "DELETE_CASHIER_OPERATION",
    LIST_CASHIER_OPERATION: "LIST_CASHIER_OPERATION",
    PREVIEW_CASHIER_OPERATION: "PREVIEW_CASHIER_OPERATION",

    INVENTORY: "INVENTORY",
    CREATE_INVENTORY_PRODUCT: "CREATE_INVENTORY_PRODUCT",
    ADD_INVENTORY_PRODUCT: "ADD_INVENTORY_PRODUCT",
    UPDATE_INVENTORY_PRODUCT: "UPDATE_INVENTORY_PRODUCT",
    DELETE_INVENTORY_PRODUCT: "DELETE_INVENTORY_PRODUCT",
    LIST_INVENTORY_PRODUCT: "LIST_INVENTORY_PRODUCT",
    LIST_OPERATIONS: "LIST_OPERATIONS",
    PREVIEW_INVENTORY_PRODUCT: "PREVIEW_INVENTORY_PRODUCT",
    INVENTORY_PRODUCT_IN: "INVENTORY_PRODUCT_IN",
    INVENTORY_PRODUCT_OUT: "INVENTORY_PRODUCT_OUT",

    PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
    CREATE_PRODUCT_CATEGORY: "CREATE_PRODUCT_CATEGORY",
    UPDATE_PRODUCT_CATEGORY: "UPDATE_PRODUCT_CATEGORY",
    DELETE_PRODUCT_CATEGORY: "DELETE_PRODUCT_CATEGORY",
    LIST_PRODUCT_CATEGORY: "LIST_PRODUCT_CATEGORY",
    PREVIEW_PRODUCT_CATEGORY: "PRODUCT_CATEGORY",

    SERVICE: "SERVICE",
    CREATE_SERVICE: "CREATE_SERVICE",
    UPDATE_SERVICE: "UPDATE_SERVICE",
    DELETE_SERVICE: "DELETE_SERVICE",
    LIST_SERVICE: "LIST_SERVICE",
    PREVIEW_SERVICE: "SERVICE",

    SMS: "SMS",
    SEND_SMS: "SEND_SMS",
    DELETE_SMS: "DELETE_SMS",
    LIST_SMS: "LIST_SMS",
    PREVIEW_SMS: "PREVIEW_SMS",

    SETTING_PARAMS: "SETTING_PARAMS",
    CREATE_SETTING_PARAMS: "CREATE_SETTING_PARAMS",
    UPDATE_SETTING_PARAMS: "UPDATE_SETTING_PARAMS",
    DELETE_SETTING_PARAMS: "DELETE_SETTING_PARAMS",
    LIST_SETTING_PARAMS: "LIST_SETTING_PARAMS",
}

export const ACCOUNT_ROLES = {
    MASTER : "MASTER",
    ADMIN : "ADMIN",
    USER : "USER",
}

export const PATHS = {
    UNAUTHORIZED_PATH: "/main/dashboard",
    AUTH_FAILED_URL: "/",
}