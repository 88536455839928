import { ADD_PRODUCT, SET_PRODUCTS, UPDATE_PRODUCT, DELETE_PRODUCT, SELECT_PRODUCT, SET_OPERATIONS, SELECT_OPERATION} from '../action-type/inventoryType';

const INITIAL_STATE = {
    products : [],
    operations : [],
    selectedProduct : {},
    selectedOperation : {},
};

const InventoryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_PRODUCTS : {
            return { ...state, products: action.payload };
        }
        case ADD_PRODUCT: {
            if (action.payload) {
                return {
                    ...state,
                    products: [ action.payload, ...state.products],
                }
            }
            return state;
        }
        case UPDATE_PRODUCT: {
            if (action.payload) {
                const i = state.products.findIndex(elm => elm.id === action.payload.id);
                state.products[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_PRODUCT: {
            if (action.payload) {
                const {products} = state;
                const i = products.findIndex(elm => elm.id === action.payload);
                products.splice(i, 1);
                return {...state, products};
            }
            return state;
        }
        case SELECT_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload
            }
        }
        case SET_OPERATIONS : {
            return { ...state, operations: action.payload };
        }
        case SELECT_OPERATION: {
            return {
                ...state,
                selectedOperation: action.payload
            }
        }
        default: return state;
    }
}

export default InventoryReducer;