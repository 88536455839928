export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';

export const ADD_PRODUCT_QUANTITY = 'ADD_PRODUCT_QUANTITY';
export const REMOVE_PRODUCT_QUANTITY = 'REMOVE_PRODUCT_QUANTITY';
export const SET_OPERATIONS = 'SET_OPERATIONS';
export const SELECT_OPERATION = 'SELECT_PRODUCT';
