import React from 'react';

import "./shop.css";

export default function shop() {
    return (
        <div className='shop'>
            <h1>CREATE SHOP</h1>
        </div>
    )
}
