import { ADD_EMPLOYEE_FUNCTION, SET_EMPLOYEE_FUNCTION, UPDATE_EMPLOYEE_FUNCTION, DELETE_EMPLOYEE_FUNCTION, SELECT_EMPLOYEE_FUNCTION} from '../action-type/employeeFunctionType';

const INITIAL_STATE = {
    employeeFunctions : [],
    selectedemployeeFunction : {},
};

const CategoryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_EMPLOYEE_FUNCTION : {
            return { ...state, employeeFunctions: action.payload };
        }
        case ADD_EMPLOYEE_FUNCTION: {
            if (action.payload) {
                return {
                    ...state,
                    employeeFunctions: [ action.payload, ...state.employeeFunctions],
                }
            }
            return state;
        }
        case UPDATE_EMPLOYEE_FUNCTION: {
            if (action.payload) {
                const i = state.employeeFunctions.findIndex(elm => elm.id === action.payload.id);
                state.employeeFunctions[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_EMPLOYEE_FUNCTION: {
            if (action.payload) {
                const {employeeFunctions} = state;
                const i = employeeFunctions.findIndex(elm => elm.id === action.payload);
                employeeFunctions.splice(i, 1);
                return {...state, employeeFunctions};
            }
            return state;
        }
        case SELECT_EMPLOYEE_FUNCTION: {
            return {
                ...state,
                selectedemployeeFunction: action.payload
            }
        }
        default: return state;
    }
}

export default CategoryReducer;
