import moment from "moment";
import { numberFormat } from "./Number";

export function PrintOrderOperationTicket(payload, socialNetworks, user){
        
    var contentWidth = 264;
    var strWindowFeatures = "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width="+contentWidth+",top=200,left=200";
    var mywindow = window.open(this, 'MAKAYLA FASHION : PRINT TICKET', strWindowFeatures);

    mywindow.document.write('<html style="width: '+contentWidth+'px;"><head><title>' + payload.ticketNumber  + '</title>');
    mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
    mywindow.document.write('<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>');
    mywindow.document.write('<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap" rel="stylesheet">');
    mywindow.document.write('<style> .content{position: relative; font-family: "IBM Plex Mono", monospace; width: '+contentWidth+'; border: thin solid #333333; padding: 3px; font-size: 0.55rem;} .title-content{font-weight: bold; text-align: center;} .text-center{text-align: center;} table{font-size: 0.55rem;} thead th{font-size: 0.55rem; text-transform: capitalize;} .netopay{position: relative; display: flex; align-items: center; justify-content: space-between; padding: 10px 5px; border-top: thin dashed #333333; border-bottom: thin dashed #333333; font-size: 1rem;}.social-item{display: flex, margin: 5px 0 0 5px;}.separator{border-bottom: thin dashed #333333; padding: 1px;} </style>');
    mywindow.document.write('</head><body style="width: '+contentWidth+'px;">');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    // mywindow.document.write(document.getElementById(payload).innerHTML);

    // body
    mywindow.document.write('<div class="content">');
    mywindow.document.write('<p class="text-center">'+document.getElementById("logo").innerHTML+'</p>');
    mywindow.document.write('<h3 class="title-content">MAKAYLA FASHION</h3>');
    mywindow.document.write('<p class="text-center">Situé à nsimeyong. 100m station Nepturne.</br>Tel: +237 657 699 154 . 272 191 235</br>Email: receptionmakayla@gmail.com</p>');
    mywindow.document.write('<div style="margin: 10px 0 0 5px;">Seller : '+(payload?.seller ? payload?.seller?.name : "")+'</div>');
    mywindow.document.write('<div style="margin: 0 0 10px 5px;">Ticket No : '+payload.ticketNumber+'</div>');
    mywindow.document.write('<table style="width: 100%;">');
    mywindow.document.write('<thead><tr><th align="left">CD</th><th align="center">QTY</th><th align="left">NAME</th><th align="left">PRICE</th><th align="left">PROM</th><th align="right">U.P</th><th align="right">TIP</th><th align="right">DISC</th><th align="right">T.P</th></tr></thead>');
    payload.orderOperationLines.forEach((item, i) => {
        mywindow.document.write('<tbody><tr><td align="left">'+item.codeID+'</td><td align="center">'+numberFormat(item.quantity, 0, ".", " ")+'</td><td align="left">'+item.designation+'</td><td align="right">'+numberFormat(item.price, 0, ".", " ")+'</td><td align="right">'+numberFormat(item.promo, 0, ".", " ")+'</td><td align="right">'+numberFormat(item.unitPrice, 0, ".", " ")+'</td><td align="right">'+ (item.tip ? numberFormat(item.tip, 0, ".", " ") : '-')+'</td><td align="right">'+ (item.discount ? "-"+numberFormat(item.discount, 0, ".", " ")+"%" : '-')+'</td><td align="right">'+numberFormat(item.totalPrice, 0, ".", " ")+'</td></tr></tbody>');
    })
    mywindow.document.write('</table>');

    mywindow.document.write('<div class="netopay" style="margin: 25px 0 25px 5px;"> <span> NET TO PAY </span>  <span>XAF '+numberFormat(payload.totalTTC, 2, ".", " ")+'</span></div>');

    mywindow.document.write('<div style="margin: 0 0 5px 5px;">Service by : '+ user.firstname +' '+ user.lastname +'</div>');
    mywindow.document.write('<div style="margin: 0 0 25px 5px;">Customer code : '+(payload.customer ? payload.customer?.tradeRegister : "") +'</div>');
    mywindow.document.write(socialNetworks.innerHTML);

    mywindow.document.write('<p class="text-center">'+moment(new Date()).format("DD/MM/YYYY  HH : mm")+'<br/> Thanks for your visit. See you soon!</p>');
    mywindow.document.write('<div class="separator" style="margin: 10px 0 10px 5px;"></div>');
    mywindow.document.write('<p class="text-center"> Your beauty institute Makayla Fashion</p>');

    // footer
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}

export function PrintCashierOperationTicket(payload, socialNetworks, user){
        
    var contentWidth = 264;
    var strWindowFeatures = "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=600,width="+contentWidth+",top=200,left=200";
    var mywindow = window.open(this, 'MAKAYLA FASHION : PRINT TICKET', strWindowFeatures);

    mywindow.document.write('<html style="width: '+contentWidth+'px;"><head><title>' + payload.ticketNumber  + '</title>');
    mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
    mywindow.document.write('<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>');
    mywindow.document.write('<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap" rel="stylesheet">');
    mywindow.document.write('<style> .content{position: relative; font-family: "IBM Plex Mono", monospace; width: '+contentWidth+'; border: thin solid #333333; padding: 3px; font-size: 0.55rem;} .title-content{font-weight: bold; text-align: center;} .text-center{text-align: center;} table{font-size: 0.55rem;} thead th{font-size: 0.55rem; text-transform: capitalize;} .netopay{position: relative; display: flex; align-items: center; justify-content: space-between; padding: 10px 5px; border-top: thin dashed #333333; border-bottom: thin dashed #333333; font-size: 1rem;}.social-item{display: flex, margin: 5px 0 0 5px;}.separator{border-bottom: thin dashed #333333; padding: 1px;} </style>');
    mywindow.document.write('</head><body style="width: '+contentWidth+'px;">');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    // mywindow.document.write(document.getElementById(payload).innerHTML);

    // body
    mywindow.document.write('<div class="content">');
    mywindow.document.write('<p class="text-center">'+document.getElementById("logo").innerHTML+'</p>');
    mywindow.document.write('<h3 class="title-content">MAKAYLA FASHION</h3>');
    mywindow.document.write('<p class="text-center">Situé à nsimeyong. 100m station Nepturne.</br>Tel: +237 657 699 154 . 272 191 235</br>Email: receptionmakayla@gmail.com</p>');
    mywindow.document.write('<div style="margin: 10px 0 0 5px;">Seller : '+(payload?.seller ? payload?.seller?.name : "")+'</div>');
    mywindow.document.write('<div style="margin: 0 0 10px 5px;">Ticket No : '+payload.ticketNumber+'</div>');
    mywindow.document.write('<table style="width: 100%;">');
    mywindow.document.write('<thead><tr><th align="left">CD</th><th align="center">QTY</th><th align="left">NAME</th><th align="left">PRICE</th><th align="left">PROM</th><th align="right">U.P</th><th align="right">TIP</th><th align="right">DISC</th><th align="right">T.P</th></tr></thead>');
    payload.cashierOperationLines.forEach((item, i) => {
        mywindow.document.write('<tbody><tr><td align="left">'+item.codeID+'</td><td align="center">'+numberFormat(item.quantity, 2, ".", " ")+'</td><td align="left">'+item.designation+'</td><td align="right">'+numberFormat(item.price, 0, ".", " ")+'</td><td align="right">'+numberFormat(item.promo, 0, ".", " ")+'</td><td align="right">'+numberFormat(item.unitPrice, 0, ".", " ")+'</td><td align="right">'+ (item.tip ? numberFormat(item.tip, 0, ".", " ") : '-')+'</td><td align="right">'+ (item.discount ? "-"+numberFormat(item.discount, 0, ".", " ")+"%" : '-')+'</td><td align="right">'+numberFormat(item.totalPrice, 0, ".", " ")+'</td></tr></tbody>');
    })
    mywindow.document.write('</table>');

    mywindow.document.write('<div class="netopay" style="margin: 25px 0 25px 5px;"> <span> NET TO PAY </span>  <span>XAF '+numberFormat(payload.totalTTC, 2, ".", " ")+'</span></div>');

    mywindow.document.write('<div style="margin: 0 0 5px 5px;">Service by : '+ user.firstname +' '+ user.lastname +'</div>');
    mywindow.document.write('<div style="margin: 0 0 25px 5px;">Customer code : '+(payload.customer ? payload.customer?.tradeRegister : "") +'</div>');
    mywindow.document.write(socialNetworks.innerHTML);

    mywindow.document.write('<p class="text-center">'+moment(new Date()).format("DD/MM/YYYY  HH : mm")+'<br/> Thanks for your visit. See you soon!</p>');
    mywindow.document.write('<div class="separator" style="margin: 10px 0 10px 5px;"></div>');
    mywindow.document.write('<p class="text-center"> Your beauty institute Makayla Fashion</p>');

    // footer
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}