import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getServices = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_SERVICE } };
    return await axios.get(`services?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _createService = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_SERVICE } };
    return await axios.post(`services`, data, config);
}

export const _updateService = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_SERVICE } };
    return await axios.put(`services/${id}`, data, config);
}


export const _deleteService = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_SERVICE } };
    return await axios.delete(`services/${id}`, config);
}