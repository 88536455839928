import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { NavLink, useLocation, Outlet, useNavigate } from "react-router-dom";
import PageLayout from "../../../layouts/defaultContentPage";
import Label from "../../../Components/label";

import "./services.css";

function InventoryPage ({items}) {

    let {pathname} = useLocation();
    let [links, setLinks] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        let part = pathname.split("/", 3);
        let link = items.find(item => (item.path.split("/",1))[0] === part[2]);
        setLinks(link?.children); 
    },[pathname, links, items]);

    useEffect(() => {
        navigate("all");
        // eslint-disable-next-line
    },[]);

    const renderSubMenu = () => {
        return (
            <ul className="subMenu">
                {
                    links?.map((link, i) => {
                        return (
                            <li key={i}>
                                <NavLink 
                                    to={link.path}
                                    className={({ isActive }) => isActive ? "sub-menu active" : "sub-menu"}
                                >
                                    <Label icon={link.icon} statement={link.name} />
                                </NavLink>
                            </li>
                        )
                    }) 
                }
            </ul>
        )
    }

    return (
        <PageLayout
        sublinks={renderSubMenu()}
        rightElement={null}
        isRightVisible={false}
        >
            <Outlet />
        </PageLayout>
    )
}

const mapStateToProps = (state) => {
    const {nav} = state;
    return { items : nav.items }
}

export default connect(mapStateToProps, {})(InventoryPage)
