
const noConnectionErrorMsg = 'Votre requête a échoué, veuillez vérifier votre connexion internet.';
const noAuthorizationRessourceErrorMsg = "Vous n'avez pas l'autorisation pour accéder à cette resource.";
const basicErrors = [403, 404, 500];

export const isValidURL = (url) => {
    const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
    return !!pattern.test(url);
}

export const maskEmail = (email) => {
  return email.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );
};

export const getMessageError = (errorStatusCode) => {

  if(!errorStatusCode)
    return noConnectionErrorMsg;

  switch(errorStatusCode)
  {
    case 403:
      return noAuthorizationRessourceErrorMsg;
    case 404:
      return "La ressource demandée est introuvable.";
    case 500:
      return 'Une erreur est survenue. Veuillez réessayer plus tard ou contacter votre administrateur si le problème persiste.';
    default:
      return 'Une erreur est survenue. Veuillez réessayer plus tard ou contacter votre administrateur si le problème persiste.';
  }
}

export const queryValues = (url) => {
  // url | format: param1=x&param2=y...
  return new URLSearchParams(url); // *var*.get("query_name") --> to get the query value.
}

export const isBasicError = (errorStatusCode) => {
  return basicErrors.find((item) => { return item === errorStatusCode });
}