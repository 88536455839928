import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Container, Row, Col } from "react-bootstrap";
import { setAuthData, setCurrentAuthUser, setAuthToken } from "../../redux/action/AuthAction";
import { _createCustomerUser } from "../../services/Customers";
import { _extractPayloadFromToken } from "../../helpers/jwt";
import { SESSION_STORAGE_KEYS,PATHS } from '../../constants/constants';
import CustomerForm from "./customerForm";
import Logo from "../../assets/images/logo.png";

import "./customer.css";

function CustomerPage(props){
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const createCustomerUser = async (payload) => {
        sessionStorage.clear();
        setLoading(true);
        try{
            const res = await _createCustomerUser(payload);
            if(res.status !== 200){
                toastr.error('Error', "An error occure. please try later", {timeOut: 5000});
            }else{
                sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, JSON.stringify(res.data.token));
                const authData = _extractPayloadFromToken(res.data.token);
                console.log({authData});
                props.setAuthData(authData);
                props.setCurrentAuthUser(authData.user);
                props.setAuthToken(res.data.token);
                // todo: add Shpo data to store
                // navigate to...
                navigate(PATHS.UNAUTHORIZED_PATH);
            }
        }catch(e){
            toastr.error('Error', e.message, {timeOut: 5000});
        }finally{
            setLoading(false);
        }
    }

    return (
        <div>
            <Container className="customer-page">
                <Row>
                    <Col>
                        <div className="title-customeer-page fade-in-top">
                            <img src={Logo} alt="Logo" width="120px" height="auto" id="logo" />
                            <h5 className="text-white mt-2">CREATE CUSTOMER ACCOUNT</h5>
                        </div>
                        <div className="customer-form-content fade-in-top-light">
                            <CustomerForm loading={loading} onSubmit={(e, payload) => createCustomerUser(payload)} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {
        auth,
    }
}

export default connect(mapStateToProps, {setAuthData, setCurrentAuthUser, setAuthToken })(CustomerPage);
