import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import './InputButton.css'

class InputButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.id,
            name: this.props.name,
            type: this.props.type,
            disabled: this.props.disabled,
            value: this.props.value,
            variant: this.props.variant,
            leftIcon: this.props.leftIcon,
            rightIcon: this.props.rightIcon,
            className: this.props.className,
        }
    }

    handleClick = (e) => {
        e.preventDefault()
        if (this.props.onClick)
            this.props.onClick(e)
    } 

    handleVariant = () => {
        let {variant} = this.props
        switch(variant) {
            case 'default': return 'inputBtn-default'
            case 'success': return 'inputBtn-sucess'
            case 'warning': return 'inputBtn-warning'
            case 'error': return 'inputBtn-error'
            case 'primary': return 'inputBtn-primary'
            default: return 'inputBtn-default'
        }
    }

    renderStatement = () => {
        const { value, loading } = this.props;
        if(loading){
            return(
                <Spinner
                    as="span"
                    animation="grow"
                    size="md"
                    role="status"
                    aria-hidden="true"
                />
            )
        }else{
            return value;
        }
    }

    render () {
        let { id, name, type, disabled, leftIcon, rightIcon, className} = this.props;
        const drawableLeft = leftIcon ? <img src={leftIcon} alt='left icon' className="inputBtn-img left"/> :  ''
        const drawableRight = rightIcon ? <img src={rightIcon} alt='right icon' className="inputBtn-img right"/> : '' 
        return (
            <button 
                id={id} 
                name={name} 
                type={type}
                disabled={disabled? disabled: false} 
                className={'inputBtn ' + this.handleVariant() + ' ' + className} 
                onClick={this.handleClick}>
                    <div className="inputBtn-content">
                        <span className="inputBtn-text">{drawableLeft} <span> {this.renderStatement()} </span> {drawableRight}</span>
                    </div>
            </button> 
        )
    }
}

export default InputButton