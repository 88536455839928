import React from 'react';
import Header from "../header";
import Footer from "../footer";

import "./content.css";

function Content(props) {
    return (
        <div className="content-main">
            <Header />
            <div className="content-page">
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default Content;
