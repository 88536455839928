import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getRights = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_RIGHT } };
    return await axios.get(`rights?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _createRight = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_RIGHT } };
    const payload = { "rights" : data }
    return await axios.post(`rights`, payload, config);
}

export const _updateRight = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_RIGHT } };
    return await axios.put(`rights/${id}`, data, config);
}


export const _deleteRight = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_RIGHT } };
    return await axios.delete(`rights/${id}`, config);
}