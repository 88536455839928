import React, {useEffect} from "react";
import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import {_checkAccessOrRedirect} from "../../services/Auth";
import {FUNCTIONALITIES_CODE} from "../../constants/AuthConstants";
import { getCustomers } from "../../services/Customers";
import { setCustomers } from "../../redux/action/CustomersAction";
import { _getEmployeeFunctions } from "../../services/employeeFunction";
import { setEmployeeFunctions  } from "../../redux/action/employeeFunctionAction";
import { getSellers } from "../../services/Sellers";
import { setSellers } from "../../redux/action/SellersAction";
import { _getCategories } from "../../services/categoryProduct";
import { setCategories } from "../../redux/action/categoryProductAction";
import { _getCategories as _getServiceCategories } from "../../services/categoryService";
import { _getProducts } from "../../services/Inventory";
import { setProducts } from "../../redux/action/InventoryAction";
import { _getServices } from "../../services/services";
import { setServices } from "../../redux/action/serviceAction";
import { setOperations } from "../../redux/action/InventoryAction";
import { _getOperations, } from "../../services/Inventory";
import { setCashierOperations } from "../../redux/action/cashierAction";
import { _getCashierOperations } from "../../services/cashier";
import { _getRights } from "../../services/Rights";
import { setRights } from "../../redux/action/rightAction";
import { _getGroups  } from "../../services/Group";
import { setGroups } from "../../redux/action/GroupAction";
import { getUsersAccounts } from "../../services/Account";
import { setAccounts } from "../../redux/action/AccountAction";
import {setCampaigns} from "../../redux/action/smsAction";
import { setSmsCount } from "../../redux/action/shopAction";
import { _getCampaign,  _getSmsCountByShopId, } from "../../services/sms";
import { PATHS } from '../../constants/constants';
import Loader from "../../Components/Loader";
import "./style.css";

function FetchData (props) {
    let navigate = useNavigate();

        const getcustomers = async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER)) {
            try {
              const res = await getCustomers(filter, pageSize, page);
              if (res.status === 200) {
                props.setCustomers(res.data.rows);
              }
            } catch (e) {
              console.error("Erreur: ", e);
            }
          }
        };

        const getEmployeeFunctions = async (filter="", pageSize=20, page=1) => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_FUNCTION_EMPLOYEE)){
                try {
                    const res = await _getEmployeeFunctions(filter, pageSize, page);
                   
                    if(res.status === 200){
                        props.setEmployeeFunctions(res.data.rows);
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                }
            }
        };

        const getsellers = async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_EMPLOYEE)) {
            try {
              const res = await getSellers(filter, pageSize, page);
              if (res.status === 200) {
                props.setSellers(res.data.rows);
              }
            } catch (e) {
              console.error("Erreur: ", e);
            }
          }
        }

        const getCategories = async (filter="", pageSize=20, page=1) => {
          // Check permission on action
          if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
              try {
                const res = await _getCategories(filter, pageSize, page);
                if(res.status === 200){
                  props.setCategories(res.data.rows);
                }
              }catch(e){
                console.error("Erreur: ", e.message);
              }
          }
      };

      const getServiceCategories = async (filter="", pageSize=20, page=1) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
            try {
                const res = await _getServiceCategories(filter, pageSize, page);
                if(res.status === 200){
                    props.setCategories(res.data.rows);
                }
            }catch(e){
                console.error('Erreur', e.message);
            }
        }
      };

      const getProducts = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT)) {
          try {
            const res = await _getProducts(filter, pageSize, page);
            if (res.status === 200) {
              props.setProducts(res.data.rows);
            }
          } catch (e) {
            console.error("Erreur: ", e);
          }
        }
      };

      const getServices = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SERVICE)) {
          try {
            const res = await _getServices(filter, pageSize, page);
            if (res.status === 200) {
              props.setServices(res.data.rows);
            }
          } catch (e) {
            console.error("Erreur: ", e);
          }
        }
      };

      const getOperations = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_OPERATIONS)) {
          try {
            const res = await _getOperations(filter, pageSize, page);
            if (res.status === 200) {
              props.setOperations(res.data.rows);
            }
          } catch (e) {
            console.error("Erreur: ", e);
          }
        }
      };

      const getCashierOperations = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CASHIER_OPERATION)) {
          try {
            const res = await _getCashierOperations(filter, pageSize, page);
            if (res.status === 200) {
              props.setCashierOperations(res.data.rows);
            }
          } catch (e) {
            console.error("Erreur: ", e);
          }
        }
      };

      const getRights = async (filter="", pageSize=20, page=1) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_RIGHT)){
            try {
                const res = await _getRights(filter, pageSize, page);
                if(res.status === 200){
                  props.setRights(res.data.rows);
                }
            }catch(e){
              console.error("Erreur: ", e);
            }
        }
    };

    const getGroups = async (filter="", pageSize=20, page=1) => {
      // Check permission on action
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_GROUP)){
          try {
            const res = await _getGroups(filter, pageSize, page);
            if(res.status === 200){
              props.setGroups(res.data.rows);
            }
          }catch(e){
            console.error("Erreur: ", e);
          }
      }
    };

    const getUsers = async (filter="", pageSize=20, page=1) => {
      // Check permission on action
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT)){
          try {
              const res = await getUsersAccounts(filter, pageSize, page);
              if(res.status === 200){
                props.setAccounts(res.data.rows);
              }
          }catch(e){
            console.error("Erreur: ", e);
          }
      }
    }

    const getCampaigns = async (filter="", pageSize=20, page=1) => {
      // Check permission on action
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SMS)){
          try {
              const res = await _getCampaign(filter, pageSize, page);
              if(res.status === 200){
                props.setCampaigns(res.data.rows);
              }
          }catch(e){
            console.error("Erreur: ", e);
          }
      }
    }

    const getSmsCountOfShop = async () => {
      if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SMS)) {
        try {
          const res = await _getSmsCountByShopId(+props.shop.id);
          if (res.status === 200) {
            props.setSmsCount(res.data);
          }
        } catch (e) {
          console.error("Erreur: ", e);
        }
      }
    }

    // Promise all
    useEffect(() => {
        (async () => {
            await Promise.all([
              getcustomers("",40,1),
              getEmployeeFunctions("",40,1),
              getsellers("",40,1),
              getCategories("",40,1),
              getServiceCategories("",40,1),
              getProducts("",40,1),
              getServices("",40,1),
              getOperations("",40,1),
              getCashierOperations("",40,1),
              getRights("",40,1),
              getGroups("",40,1),
              getUsers("",40,1),
              getCampaigns("",40,1),
              getSmsCountOfShop("",40,1),
            ]);

             // navigate to...
             navigate(PATHS.UNAUTHORIZED_PATH);
        })();
        // eslint-disable-next-line
    },[])

    return (
        <div className="fetch-data-page">
          <Loader loading />
          <div className="title ml-2">Fetching data, please wait...</div>
        </div>
    )
}

const mapStateToProps = (state) => {
  const {auth} = state;
  return {
      auth,
      shop : auth?.authData?.shop,
  }
}
  
export default connect(() => mapStateToProps, {
    setCustomers,
    setEmployeeFunctions,
    setSellers,
    setCategories,
    setProducts,
    setServices,
    setOperations,
    setCashierOperations,
    setRights,
    setGroups,
    setAccounts,
    setCampaigns,
    setSmsCount,
})(FetchData);
