import React from 'react';

import "./defaultContentPageLayout.css";



function DefaultContentPageLayout({children, sublinks, rightElement, rightElementClassName, isRightVisible, className}) {

    const renderRight = (visible) => {
        if(visible){
            return (
                <div className={`right-content-page ${rightElementClassName ? rightElementClassName : ''}`}>
                    { rightElement }
                </div>
            )
        }
    }

    return (
        <div className={`page-content-layout ${className ? className : ''}`}>
            <div className="left-content-page">
                { sublinks ?
                    <div className="header-page">
                        <div className="sub-navlink">{sublinks}</div>
                    </div> : <></>
                }
                <div className="page-content">
                    { children }
                </div>
            </div>
            { renderRight(isRightVisible) }
        </div>
    )
}

export default DefaultContentPageLayout;
