import { ADD_EMPLOYEE_FUNCTION, SET_EMPLOYEE_FUNCTION, UPDATE_EMPLOYEE_FUNCTION, DELETE_EMPLOYEE_FUNCTION, SELECT_EMPLOYEE_FUNCTION} from '../action-type/employeeFunctionType';

export const addEmployeeFunction = (data) => ({
    type: ADD_EMPLOYEE_FUNCTION,
    payload: data
})

export const setEmployeeFunctions = (data) => ({
    type: SET_EMPLOYEE_FUNCTION,
    payload: data
})

export const updateEmployeeFunction = (data) => ({
    type: UPDATE_EMPLOYEE_FUNCTION,
    payload: data
})

export const deleteEmployeeFunction = (id) => ({
    type: DELETE_EMPLOYEE_FUNCTION,
    payload: id
})

export const selectEmployeeFunction = (data) => ({
    type: SELECT_EMPLOYEE_FUNCTION,
    payload: data
})