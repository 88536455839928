import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import "./navlink.css";

function NavItem({ path, icon, name }) {
    const Icon = icon;
    return (
        <NavLink 
            to={path} 
            className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}
        >
            <span className="item-icon">{Icon ? <Icon className="nav-icon" /> : <></>}</span>
            <span className="route-name">{name ? name : "Link"}</span>      
        </NavLink>
    )
}

const mapStateToProps = (state) => {
    const {nav} = state;
    return { ActiveLink : nav.activeItem }
}

export default connect(mapStateToProps, {  })(NavItem)
