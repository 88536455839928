import { ADD_RIGHT, ADD_RIGHTS, SET_RIGHTS, UPDATE_RIGHT, DELETE_RIGHT, SELECT_RIGHT} from '../action-type/rightType';

export const addRight = (data) => ({
    type: ADD_RIGHT,
    payload: data
})

export const addRights = (data) => ({
    type: ADD_RIGHTS,
    payload: data
})

export const setRights = (data) => ({
    type: SET_RIGHTS,
    payload: data
})

export const updateRight = (data) => ({
    type: UPDATE_RIGHT,
    payload: data
})

export const deleteRight = (id) => ({
    type: DELETE_RIGHT,
    payload: id
})

export const selectRight = (data) => ({
    type: SELECT_RIGHT,
    payload: data
})