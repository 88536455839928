import React, {useState, useEffect, Suspense} from "react";
import PageLayout from "../../../layouts/defaultContentPage";
import {PAGE} from "../../../constants/constants";
import {_checkAccessOrRedirect} from "../../../services/Auth";
import Loader from "../../../Components/Loader";
import Input from "../../../Components/CustomInput/CustomInput";
import { numberFormat } from "../../../helpers/Number";
import { _getOrderOperations, _cancelOrderOperation } from "../../../services/order";
import { _createCashierOperationFromOrder, _createCashierOperation } from "../../../services/cashier";
import {toastr} from "react-redux-toastr";
import Table from "../../../Components/Table/GenericTable";
import {connect} from "react-redux";
import {
    setOrderOperations,
    selectOrderOperation,
    updateOrderOperation,
    deleteOrderOperation,
} from "../../../redux/action/ordderAction";
import ModalRight from "../../../Components/ModalRight";
import LabelStatus from "../../../Components/label/labelStatus";
import {Pagination} from "react-bootstrap";
import PaginationSize from "../../../Components/paginationSize";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";
import moment from "moment";
import { FcDeleteRow, FcPrint, FcAcceptDatabase, FcRefresh, } from "react-icons/fc";
import { SESSION_STORAGE_KEYS } from "../../../constants/constants";
import Storage from "../../../helpers/Storage";
import { PrintOrderOperationTicket } from "../../../helpers/printer";
import { IoLogoFacebook, IoLogoInstagram, IoGlobe, } from "react-icons/io5";

import "./cashier.css";

function OrderOperationsView (props) {
    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState();
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [stateData, setStateData] = useState({});
    let [refresh, setRefresh] = useState(true);
    let [, setDisplayForm] = useState(null);
    // eslint-disable-next-line
    const [processInterval, setProcessInterval] = useState(true);

    useEffect(() => {
        (async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER)) {
            setLoadingData(true);
            try {
              const res = await _getOrderOperations(filter, pageSize, page);
              setStateData({
                currentPage: res.data.pager.currentPage,
                pageSize: res.data.pager.pageSize,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
              });
              if (res.status === 200) {
                setLoadingData(false);
                props.setOrderOperations(res.data.rows);
              } else {
                setLoadingData(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingData(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, [refresh]);

      useEffect(() => {
        // if(processInterval){
        //     const intervalId = setInterval(() => {
        //         createCashierOperations(intervalId);
        //     }, 1000 * 60);
        // }
        // eslint-disable-next-line
    },[]);

    // Process after offline
    // eslint-disable-next-line
    const createCashierOperations = async (intervalId) => {
        const cashierOperations = Storage.Get(SESSION_STORAGE_KEYS.CASHIER_OPERATIONS);
        if(cashierOperations){
            try {
                const result = await Promise.all(
                    cashierOperations.map( async (item) => {
                        const res = await _createCashierOperation(item);
                        if (res.status === 200) {}
                    })
                );
                if(result){
                    toastr.info('NOTE', "Cashier operation saved!", {timeOut: 1000});
                    Storage.Clear(SESSION_STORAGE_KEYS.CASHIER_OPERATIONS);
                    clearInterval(intervalId);
                    setProcessInterval(false);
                }
            } catch (e) {
                if(e.message !== "Network Error"){ 
                    toastr.error('Error', e.message, {timeOut: 5000});
                }
            }
        }
    }
    
      const getFilterProducts = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER)) {
          setLoading(true);
          try {
            const res = await _getOrderOperations(filter, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoading(false);
              props.setOrderOperations(res.data.rows);
            } else {
              setLoading(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
          }
        }
      };

      const cancelOrderOp = async (data) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_CUSTOMER_ORDER)) {
            setLoading(true);
            try {
              const res = await _cancelOrderOperation(data, +data.id);
              if (res.status === 200) {
                props.deleteOrderOperation(+data.id);
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
            }finally{
                setLoading(false);
            }
          }
      }

      const cancelOrderOperation = (e, item) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_CUSTOMER_ORDER)) {
          const toastrConfirmOptions = {
            onOk: () => cancelOrderOp(item),
            onCancel: () => {}
          };
          toastr.confirm("Do you really want to cancel this operation ?", toastrConfirmOptions);
        }else{
            toastr.error("ERROR", "You do not have permission for this action!");
        }
      }
    
      const renderDetailOperation = () => {
        return (
          <div className="p-2">
            <div className="item-group">
              <div className="item-left">Status</div>
              <div className="item-right"><LabelStatus status={props.selectedOperation?.status} /></div>
            </div>

            { props.selectedOperation?.rendezvousAt ? <div className="item-group">
              <div className="item-left">Rendez-vous at</div>
              <div className="item-right" style={{color: "#ff0077"}}>  
                <b>{moment(props.selectedOperation?.rendezvousAt).format("YYYY-MM-DD, HH : mm")}</b> 
              </div>
            </div> : <></>}

            <div className="item-group">
              <div className="item-left">Ticket number</div>
              <div className="item-right">  
                {props.selectedOperation?.ticketNumber} 
              </div>
            </div>

            <div className="item-group">
              <div className="item-left">Payment mode</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.paymentMode}</b></div>
            </div>
            <div className="item-group">
              <div className="item-left">Total HT</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{`${numberFormat(props.selectedOperation?.totalHT, 2, ".", " ")} XAF`}</b></div>
            </div>

            <div className="item-group">
              <div className="item-left">Total TTC</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{`${numberFormat(props.selectedOperation?.totalTTC, 2, ".", " ")} XAF`}</b></div>
            </div>

            <div className="item-group">
              <div className="item-left">Operation Date</div>
              <div className="item-right" style={{color: "red"}} >{moment(props.selectedOperation?.createdAt).format("YYYY-MM-DD, HH : mm") }</div>
            </div>

            <h5 className="title-5 mt-3">Client Concerned</h5>

            <div className="item-group">
              <div className="item-left"> Client name: </div>
              <div className="item-right">{props.selectedOperation?.customer?.name}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Trade register</div>
              <div className="item-right">{props.selectedOperation?.customer?.tradeRegister}</div>
            </div>
                
            <h5 className="title-5 mt-3">Employee Concerned</h5>

            <div className="item-group">
                <div className="item-left">Employee name</div>
                <div className="item-right">{props.selectedOperation?.seller?.name}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Employee function</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.seller?.employeeFunction?.name}</b> </div>
            </div>

            <h5 className="title-5 mt-3">Operation elements</h5>
            {
                props.selectedOperation?.orderOperationLines?.map((item, i) => {
                    return (
                        <div className="item-group" key={i}>
                            <div className="item-left"><b style={{ marginRight: 5 }}>({item.codeID})</b> {item.designation} </div>
                            <div className="item-right"><b style={{color: "#1179AD", marginRight: 5, fontSize: "0.8rem"}}>({item.quantity})</b> <span style={{fontSize: "0.8rem"}}>{`${numberFormat(item.totalPrice, 2, ".", " ")} XAF`}</span>  </div>
                        </div>
                    )
                })
            }
            
          </div>
        )
      }
    
      const selectElement = (item) => {
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_CUSTOMER_ORDER)) {
          props.selectOrderOperation(item?.originalData);
          setIsRightVisible(true);
          setDisplayForm("preview");
        }else{
            console.log("not have permission!");
        }
      }

      const saveOp = (e, operation) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_CUSTOMER_ORDER)) {
          const toastrConfirmOptions = {
            onOk: () => saveOperation(operation),
            onCancel: () => {}
          };
          toastr.confirm("Do you really want to proceed?", toastrConfirmOptions);
        }else{
            toastr.error("ERROR", "You do not have permission for this action!");
        }
      }

      const cleanPayload = (payload) => {
        delete payload.user;
        delete payload.status;
        const orderOperationLines = payload.orderOperationLines.map(line => {
            delete line.id;
            return line;
        });
        return { ...payload, orderOperationLines };
      }

      const saveOperation = async (operation) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION)) {
            setLoading(true);
            const payload = cleanPayload(operation);
            try {
              const res = await _createCashierOperationFromOrder(payload);
              if (res.status === 200) {
                operation = Object.assign(operation, res.data);
                props.updateOrderOperation(operation);
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
                if(e.message === "Network Error"){ 
                    // saveCashierOperationToStorage(payload);
                    // toastr.info('NOTE', "Data saved locally!", {timeOut: 5000});
                    // setProcessInterval(true);
                    toastr.warning('Warning', "There is no internet access. Try leter!", {timeOut: 5000});
                }else{
                    toastr.error('Error', e.message, {timeOut: 5000});
                }
            }finally{
                setLoading(false);
            }
          }

      }

      const printTicket = (e, payload) => {
        e.stopPropagation();
        let socialNetworks = document.getElementById("socialnetworks");
        PrintOrderOperationTicket(payload, socialNetworks, props.currentUser);
      }

      // eslint-disable-next-line 
      const saveCashierOperationToStorage = (payload) => {
        // test if key exist
        const cashierOperations = Storage.Get(SESSION_STORAGE_KEYS.CASHIER_OPERATIONS);
        if(cashierOperations){
            cashierOperations.push(payload);
            Storage.Set(SESSION_STORAGE_KEYS.CASHIER_OPERATIONS, cashierOperations);
        }else{
            Storage.Set(SESSION_STORAGE_KEYS.CASHIER_OPERATIONS, [payload]);
        }
    }

      const renderAction = (item) => {
        return (
          <div>
            {
              _checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_CUSTOMER_ORDER) ?
              <>
              <button className="btn btn-action" onClick={(e) => printTicket(e, item)}  title='Print operation'>
                  <FcPrint/>
              </button>
              { item?.status === "PENDING" ?
                <button className="btn btn-action" onClick={(e) => saveOp(e, item)}  title='Print operation'>
                  <FcAcceptDatabase/>
                </button> : <></>
              }
              <button className="btn btn-action" onClick={(e) => cancelOrderOperation(e, item)}  title='Cancel Order Operation'>
                  <FcDeleteRow/>
              </button>
              </> :
              <></>
            }
          </div>
        )
      }
  
      const headerData = [
        {
          title: <div className="size-30 flex-center  rounded"> # </div>,
          align: "center",
          className: '',
          width: 50,
          name: "number"
        },
        {title: "Date", align: "left", className: 'pl-15', width: "150px", name: "date"},
        // {title: "Ticket number", align: "left", className: 'pl-15', width: "auto", name: "ticketNumber"},
        {title: "Payment mode", align: "left", className: 'pl-15', width: "100px", name: "paymentMode"},
        {title: "Elements", align: "right", className: 'pl-15', width: "100px", name: "orderOperationLines"},
        {title: "Total TTC", align: "right", className: 'pl-15', width: "auto", name: "totalTTC"},
        {title: "Customer", align: "left", className: 'pl-15 md-hide', width: "auto", name: "customer"},
        {title: "Employee", align: "left", className: 'pr-15 md-hide', width: "auto", name: "employee"},
        {title: "Status", align: "left", className: 'pr-15 md-hide', width: "100px", name: "status"},
        {title: "Rendez-vous", align: "left", className: 'pr-15 md-hide', width: "auto", name: "rendezvous"},
        {title: <> Actions </>, align: "center", className: 'pr-2', width: "150px", name: "action"},
      ];
    
      const formatBodyData = (data) => {
        return data?.map((item, i) => {
          let resData = {
            originalData: item,
            number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
            // ticketNumber: item?.ticketNumber,
            date: <b style={{color: "#1179AD"}}>{moment(item?.updatedAt).format("YYYY-MM-DD, HH : mm")}</b>,
            paymentMode: item?.paymentMode,
            orderOperationLines: item?.orderOperationLines?.length,
            totalTTC: <b style={{color: "green"}}>{`${numberFormat(item.totalTTC, 2, ".", " ")} XAF`}</b> ,
            customer: <b style={{color: "#1179AD"}}>{item?.customer?.name}</b> ,
            employee: <b style={{color: "#444444"}}>{item?.seller?.name}</b> ,
            rendezvous: <b style={{color: "#ff0077"}}>{ item?.rendezvousAt ? moment(item?.rendezvousAt).format("YYYY-MM-DD, HH : mm") : <></>}</b> ,
            status: <LabelStatus status={item?.status} />,
            action: renderAction(item),
          }
          return resData;
        })
      }
    
      const setPage = async (page) => {
        if (stateData.currentPage !== page) {
          await getFilterProducts("", stateData.pageSize, page);
        }
      }
    
      const setSize = async (pageSize) => {
        await getFilterProducts("", pageSize, 1);
      }
    
      const renderPaginarion = () => {
        if (stateData.totalPages > 1) {
          let items = [];
          for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
                className="pointer">
                {number}
              </Pagination.Item>,
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Pagination>
                <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.startPage)}/>
                <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage - 1)}/>
                {items}
                <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage + 1)}/>
                <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.endPage)}/>
              </Pagination>
              <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
            </div>
          )
        } else {
          return <></>
        }
      }
    
      const handleFilterChange = async (e) => {
        setFilter(e.target.value);
        await getFilterProducts(e.target.value);
      }


    return (
        <PageLayout
        title={PAGE.CUSTOMERS}
        rightElement={
            <ModalRight
                title="Order operation details"
                content={renderDetailOperation()}
                isVisible={isRightVisible}
                onClose={(e) => setIsRightVisible(false)}
            />
        }
        isRightVisible={isRightVisible}
        >
    <>
    <div className="order-list-view">
        <div className="page-action">
            <div className="d-flex aligns-items-center">
                { loading ? <Loader loading={loading}/> : <button className='btn btn-refresh border' onClick={() => setRefresh(!refresh)}> <FcRefresh /> </button>}
            </div>
            <div className="search-content-form">
                <Input
                    type="text"
                    globalClassName="search-input"
                    name="filter"
                    value={filter}
                    placeholder="Enter a keyword..."
                    onChange={(e) => handleFilterChange(e)}
                />
            </div>
        </div>

        <Suspense fallback={<Loader loading/>}>
            <Table
            header={headerData}
            body={formatBodyData(props.operations)}
            className='mt-1 mr-3'
            hoverColor="rgba(0, 0, 0, 0.05)"
            isLoading={loadingData}
            asLoader={<Loader loading/>}
            onSelect={(item) => selectElement(item)}
            selectedClass="active"
            />
            {renderPaginarion()}
        </Suspense>
    </div>
    {/* renderRightModal() */}
    <div className="social-networks hidden" id="socialnetworks" style={{display: "none" }}>
        <div className="social-item"> <IoLogoFacebook /> <span>makaylafashion</span> </div> 
        <div className="social-item">  <IoLogoInstagram /> <span>makaylafashion.mf</span> </div> 
        <div className="social-item"> <IoGlobe /> <span>www.makaylafashion.com</span> </div> 
    </div>
</>
</PageLayout>
    )
}

const mapStateToProps = (state) => {
    const { order, auth } = state;
    return {
      operations: order.orderOperations, 
      selectedOperation: order.selectedOrderOperation,
      currentUser : auth.currentAuth,
    };
  }
  
  export default connect(mapStateToProps, {
    setOrderOperations,
    selectOrderOperation,
    updateOrderOperation,
    deleteOrderOperation,
  })(OrderOperationsView);
