/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import Datepicker from "react-datepicker";

import "./CustomInput.css";

class CustomInput extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    renderHasError = () => {
        return this.props.hasError ? "error-input" : "";
    }

    renderClassName = () => {
        const errorClass = this.renderHasError();
        if(errorClass){
            return (this.props.className ? `${this.props.className} ${errorClass}` : "");
        } else {
            return (this.props.className ? this.props.className : "");
        }
    }

    renderVariant = (prefix = "") => {
        if(this.props.variant){
            return (`${prefix}${this.props.variant}`);
        }
    }

    renderId = () => {
        return this.props.id ? this.props.id : new Date() + "-" + Math.random(10000,99999);
    }

    renderPlaceholder = () => {
        return this.props.placeholder ? this.props.placeholder : "";
    }

    renderIcon = () => {
        const icon = this.props.withIcon ? this.props.withIcon : null;
        if(icon){
            return (
                <div className={`input-icon ${this.props.iconClassName ? this.props.iconClassName : ""}`} >{icon}</div>
            )
        }
    }

    handleChange = (date) => {
        if(this.props.onChange && date) this.props.onChange(date);
    }

    renderInput = () => {
        switch (this.props.type){
            case ("button") : {
                return (
                    <button 
                        type={this.props.type}
                        className={`form-control btn ${this.renderVariant("btn-")} ${this.renderClassName()}`}
                        name={this.props.name}
                        id={this.renderId()}
                        onClick={this.props.onClick}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                    >
                        {this.props.value}
                    </button>
                )
            }
            case ("submit") : {
                return (
                    <button 
                        type={this.props.type}
                        className={`form-control btn ${this.renderVariant("btn-")} ${this.renderClassName()}`}
                        name={this.props.name}
                        id={this.renderId()}
                        onClick={this.props.onClick}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                    >
                        {this.props.value}
                    </button>
                )
            }
            case ("textarea") : {
                return (
                    <textarea
                        className={`form-control ${this.renderClassName()}`}
                        name={this.props.name}
                        id={this.renderId()}
                        placeholder={this.props.placeholder}
                        row={this.props.row ? this.props.row : 5}
                        col={this.props.col ? this.props.col : 10}
                        onFocus={this.props.onFocus}
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        defaultValue={this.props.value}
                    >
                    </textarea>
                )
            }
            // case ("select") : {
            //     return (
            //         <DropdownButton id="dropdown-basic-button" title={this.props.value ? this.props.value  : this.props.placeholder ? this.props.placeholder : "Selectionnez un element"}>
            //             <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            //             <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            //             <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            //         </DropdownButton>
            //     )
            // }
            case("date") : {
                return (
                    <Datepicker 
                        selected={this.props.value ? this.props.value : null}
                        onChange={date => this.handleChange(date)}
                        onBlur={this.props.onBlur}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy/MM/dd"
                        todayButton="Aujourd'hui"
                        placeholderText={this.props.placeholder ? this.props.placeholder : "Selectionner une date"}
                    />
                )
            }
            default: {
                return (
                    <input
                        type={this.props.type} 
                        className={`form-control input-text ${this.renderClassName()}`}
                        name={this.props.name}
                        id={this.renderId()}
                        placeholder={this.renderPlaceholder()}
                        value={this.props.value}
                        required={this.props.required}
                        onChange={this.props.onChange}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                        onClick={this.props.onClick}
                    />
                )
            };
        }
    }

    render() {
        return (
            <div className={`CustomInput ${this.props.globalClassName ? this.props.globalClassName : ""}`}>
                {this.renderIcon()}
                {this.renderInput()}
            </div>
        );
    }
}

CustomInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    className: PropTypes.string,
    globalClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "number", "email", "password", "file", "date", "datetime", "select", "radio", "checkbox", "button", "textarea"]),
    variant: PropTypes.oneOf(["default", "primary", "secondary", "warning", "info", "error"]),
    withIcon: PropTypes.any,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSelect: PropTypes.func,
}

export default CustomInput;