import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getCategories = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY } };
    return await axios.get(`category-services?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _createCategory = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_PRODUCT_CATEGORY } };
    return await axios.post(`category-services`, data, config);
}

export const _updateCategory = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_PRODUCT_CATEGORY } };
    return await axios.put(`category-services/${id}`, data, config);
}


export const _deleteCategory = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_PRODUCT_CATEGORY } };
    return await axios.delete(`category-services/${id}`, config);
}