import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {containsClass, addClass, removeClass} from "../../helpers/DOM";
import "./generic-table.css";

const Action = (props) => {
    return (
        <input type="radio" name="action" value={props.value} />
    )
}

const onSelect = (item) => {
}

// #region component=
const propTypes = {
    header: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.func]),
            align: PropTypes.oneOf(["left","center","right","auto","justified"]),
            className: PropTypes.string,
            width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string, // this attribute is targetting the column at the BODY which has to be display;
            formater: PropTypes.func, // function to format the column
        })
    ),
    body: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    hoverColor: PropTypes.string,
    withFooter: PropTypes.bool,
    asLoader: PropTypes.element,
    isLoading: PropTypes.bool,
    selectedColor: PropTypes.string,
    onSelect: PropTypes.func,
};

const defaultProps = {
    header: [
        { title: 'ID', align: "left", className: '', name: "id", width: "5%", height: "auto"}, 
        { title: 'Nom & Prenom', align: "left", className: '', name: "fullName", formater: (val) => val + "**"}, 
        { title: 'Email', align: "left", className: 'hide-xs-responsive', name: "email"}, 
        { title: 'Telephone', align: "left", className: 'hide-xs-responsive', name: "phone"},
        { title: 'Actions', align: "center", className: '', name: "action"}
    ],
    className: "",
    hoverColor: "rgba(112, 112, 112, 0.05)",
    selectedColor: "rgba(112, 112, 112, 0.4)",
    widthFooter: false,
    isLoading: false,
    asLoader: <div>Loadind, please wait...</div>,
    onSelect: onSelect,
    body: [
        {id: "001", fullName: "John DOE", email: "john.doe@domain.xyz", phone: "555 - 241 744", action: <Action value={1} />},
        {id: "002", fullName: "Jane DOE", email: "jane.doe@domain.xyz", phone: "555 - 233 546", action: <Action value={2} />},
        {id: "003", fullName: "Jamy FOX", email: "jamy.fox@domain.xyz", phone: "555 - 317 094", action: <Action value={3} />},
        {id: "004", fullName: "Boyl CURRY", email: "boyl.curry@domain.xyz", phone: "555 - 210 224", action: <Action value={4} />},
        {id: "005", fullName: "Pepga NDOUMCHE", email: "pepga.ndoumche@domain.xyz", phone: "555 - 301 004", action: <Action value={5} />},
    ],
};

/** Generic Table
 * 
 */
class GenericTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rendable: false,
            body: this.props.body,
            header: this.props.header,
            selectedItem: null
        };
    }

    componentDidMount() {
        if(this.props.onRef) this.props.onRef(this);
    }
    componentWillUnmount() {
        if(this.props.onRef) this.props.onRef(undefined);
    }

    unSelectAll = () => {
        let elements = document.querySelectorAll("table.generic-table > tbody > tr");
        elements.forEach((el) => {
            removeClass(el, this.props.selectedClass);
        });
    }

    processRow = (i = 0) => {
        let element = document.querySelector("table.generic-table > tbody > tr#tr-" + i);
        if(element){
            if(!containsClass(element, this.props.selectedClass)){
                this.unSelectAll();
                addClass(element, this.props.selectedClass);
            }
        }
    }

    onSelect = (value, idx) => {
        if(this.props.onSelect){
            this.props.onSelect(value);
        }
        this.processRow(idx);
    }
    unSelect = () => {
        this.unSelectAll();
    }

    isSelected = (item) => {
        const result = item && this.state.selectedItem && (item[this.props.key] === this.state.selectedItem[this.props.key]);
        return result;
    }

    renderHeader = () => {
        return(
            <thead>
                <tr>
                    {
                        this.props.header.map((item, key) => {
                            return(
                                <th style={{textAlign: item.align}} className={`${item.className}`} key={key} width={item.width} height={item.height}>{item.title}</th>
                            )
                        })
                    }
                </tr>
            </thead>
        );
    }

    renderBody = () => {
        const {header, body} = this.props;
        if(body.length){
            return (
                <tbody>
                    {
                        body.map((item, idx) => {
                            return (
                                <tr key={idx} onClick={this.onSelect.bind(this, item, idx)} id={"tr-"+idx}>
                                {   header.map((col, i) => {
                                    return (
                                        <td  style={{textAlign: col.align}} className={col.className} key={i}>
                                            { col.formater ? col.formater(item[col.name]) : item[col.name]}
                                        </td>
                                    )
                                })}
                                </tr>
                            )
                        })
                        
                    }
                </tbody>
            );
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan={(header.length)} className="text-center-h">NO RESULT</td>
                    </tr>
                </tbody>
            )
        }
    }

    renderFooter = () => {
        if(this.props.widhFooter){
            return(
                <tfoot>
                    <tr>
                        {
                            this.props.header.map((item, key) => {
                                return(
                                    <th style={{textAlign: item.align}} className={`${item.className}`} key={key}>{item.title}</th>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            );
        }
    }

    renderLoader = () => {
        if(this.props.asLoader){
            return ( this.props.asLoader );
        }else{
            return "Loading...";
        }
    }

    render() {
        if(this.props.isLoading){
            return ( <div className="loader-generic-table"> { this.renderLoader() } </div> );
        }else{
            return (
                <table 
                    className={`generic-table ${this.props.className ? this.props.className : ''}`}
                    style={{"--hover-color": this.props.hoverColor, "--selected-color": this.props.selectedColor}}
                >
                    { this.renderHeader() }
                    { this.renderBody() }
                    { this.renderFooter() }
                </table>
            );
        }
    }
}

GenericTable.propTypes = propTypes;
GenericTable.defaultProps = defaultProps;
// #endregion

export default GenericTable;

// Header: the "name" attribute is targetting the column at the BODY which has to be display;
// Ex: 
/* <GenericTable 
    header={dataHeader} --> data that populate the header *required
    body={dataBody} --> data that populate the body  *required
    className="my-custom-class" --> the className that stylize the global table. *not required
    hoverColor="#FFFFFF" --> hover color (default value: rgba(112, 112, 112, 0.2)) *not required
    withFooter --> set to "true" (or just add 'widthFooter' to props ) if you which to display the table footer (default value: false) *not required
    asLoader=<LoaderComponent />, --> to set the custom loader component. *not required
    isLoading --> set to "true" (or just add 'isLoading' to props ) if you which to display the loader *not required
    onSelect={this.selectedData} --> function handler when trigging a click on a body's row *not required
/> */