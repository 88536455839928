import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getGroups = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_GROUP } };
    return await axios.get(`groups?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getGroupById = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_GROUP } };
    return await axios.get(`groups/${+id}`, config);
}

export const _createGroup = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_GROUP } };
    return await axios.post(`groups`, data, config);
}

export const _updateGroup = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_GROUP } };
    return await axios.put(`groups/${id}`, data, config);
}


export const _deleteGroup = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_GROUP } };
    return await axios.delete(`groups/${id}`, config);
}

export const _assagnRightsToGroup = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT } };
    return await axios.post(`groups/${id}/rights`, data, config);
}

export const _removeRightsfromGroup = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT } };
    return await axios.put(`groups/${id}/rights`, data, config);
}

export const _assagnGroupsToUser = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER } };
    return await axios.post(`users/${id}/groups`, data, config);
}

export const _removeGroupsfromUser = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER } };
    return await axios.put(`users/${id}/groups`, data, config);
}
