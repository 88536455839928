import React, {useState, useEffect, Suspense} from "react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import Label from "../../../../Components/label";
import Loader from "../../../../Components/Loader";
// eslint-disable-next-line
// import Label from "../../../Components/label";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import { _getOperationsByProductId } from "../../../../services/Inventory";
import {
  setOperations,
  selectOperation,
} from "../../../../redux/action/InventoryAction";
import {toastr} from "react-redux-toastr";
import { GoSignIn, GoSignOut } from "react-icons/go";
// eslint-disable-next-line
import { FcPrint, FcPrevious, } from "react-icons/fc";
import Table from "../../../../Components/Table/GenericTable";
import {Pagination} from "react-bootstrap";
import PaginationSize from "../../../../Components/paginationSize";

import "./product.css";

function ProductHistory (props) {
    // eslint-disable-next-line
    let [loading, setLoading] = useState(false);
    // let [assignRights, setAssignRights] = useState([]);
    // eslint-disable-next-line
    let [loadingData, setLoadingData] = useState(false);
    // eslint-disable-next-line
    let [stateData, setStateData] = useState({});
    let navigate = useNavigate();
    let params = useParams();

    const goback = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    useEffect(() => {
      (async (pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_OPERATIONS)) {
          setLoadingData(true);
          try {
            const res = await _getOperationsByProductId(+params.id, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoadingData(false);
              props.setOperations(res.data.rows);
            } else {
              setLoadingData(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e, {timeOut: 5000});
            setLoadingData(false);
          }
        }
      })()
      // eslint-disable-next-line
    }, []);

    const getFilterProducts = async (id, pageSize = 20, page = 1) => {

        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT)) {
          try {
            setLoadingData(true);
            const res = await _getOperationsByProductId(id, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoadingData(false);
              props.setOperations(res.data.rows);
            } else {
              setLoadingData(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e, {timeOut: 5000});
            setLoadingData(false);
          }
        }
      
    }

    const headerData = [
      {
        title: <div className="size-30 flex-center  rounded"> # </div>,
        align: "center",
        className: '',
        width: 50,
        name: "number"
      },
      {title: "Date", align: "left", className: 'pl-15', width: "150px", name: "date"},
      {title: "Type", align: "left", className: 'pl-15', width: "150px", name: "operationType"},
      {title: "Initial stock", align: "right", className: 'pl-15 sm-hide', width: "50px", name: "initialStock"},
      {title: "Stock In", align: "right", className: 'pl-15', width: "auto", name: "stockIn"},
      {title: "Stock Out", align: "right", className: 'pl-15', width: "auto", name: "stockOut"},
      {title: "Final stock", align: "right", className: 'pr-15', width: "auto", name: "finalStock"},
    ];

    const formatBodyData = (data) => {
      return data?.map((item, i) => {
        let resData = {
          originalData: item,
          number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
          date: <b style={{color: "#1179AD"}}>{moment(item?.inventoryProduct?.createdAt).format("YYYY-MM-DD, HH : mm")}</b>,
          operationType: <Label 
              statement={item.operationType} 
              icon={ item?.operationType === "IN" ? GoSignIn : GoSignOut}
              style={{width: "50px", color: item?.operationType === "IN" ? "green" : "red"}}
          /> ,
          initialStock: <b style={{color: "#1179AD"}}>{item?.initialStock}</b> ,
          stockIn: <b style={{color: "green"}}>{item.stockIn}</b> ,
          stockOut: <b style={{color: "red"}}>{item.stockOut}</b> ,
          finalStock: <b style={{color: "#444444"}}>{item.finalStock}</b> ,
        }
        return resData;
      })
    }
    
      const setPage = async (page) => {
        if (stateData.currentPage !== page) {
          await getFilterProducts(+params.id, stateData.pageSize, page);
        }
      }
    
      const setSize = async (pageSize) => {
        await getFilterProducts(+params.id, pageSize, 1);
      }

      const selectElement = (item) => {
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE)) {
          
        }
      }
    
      /* const renderAction = (item) => {
        return (
          <div>
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PRINT_HISTORY_EMPLOYEE) ?
              <button className="btn btn-action" onClick={() => {}}>
                <FcPrint color="blue"/>
              </button> :
              <></>}
          </div>
        )
      } */
    
      const renderPaginarion = () => {
        if (stateData.totalPages > 1) {
          let items = [];
          for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
                className="pointer">
                {number}
              </Pagination.Item>,
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Pagination>
                <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.startPage)}/>
                <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage - 1)}/>
                {items}
                <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage + 1)}/>
                <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.endPage)}/>
              </Pagination>
              <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
            </div>
          )
        } else {
          return <></>
        }
      }

    return (
        <div className="employee-history">
            <div className="page-action">
                <div className="d-flex align-items-center">
                    <button className="btn btn-prev mr-2" onClick={goback}> <FcPrevious /> <span style={{ marginLeft: 10, color: "#1179AD" }} >Go back</span> </button>
                    <Loader loading={loading} />
                </div>
                <div className="title-right">
                    <span className="sub-title-page ">{`Product history`}</span>
                </div>
            </div>

            <Suspense fallback={<Loader loading/>}>
            <Table
              header={headerData}
              body={formatBodyData(props.operations)}
              className='mt-1 pr-5'
              hoverColor="rgba(0, 0, 0, 0.05)"
              isLoading={loadingData}
              asLoader={<Loader loading/>}
              onSelect={(item) => selectElement(item)}
              selectedClass="active"
            />
            {renderPaginarion()}
          </Suspense>

        </div>
    )
}

const mapStateToProps = (state) => {
  const {inventory } = state;
  return {
    operations: inventory.operations, 
    selectedOperation: inventory.selectedProduct,
  };
}

export default connect(mapStateToProps, {
  setOperations,
  selectOperation,
})(ProductHistory);
