import React, {useState, useEffect, lazy, Suspense } from "react";
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Pagination } from "react-bootstrap";
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import { _getCategories, _createCategory, _updateCategory, _deleteCategory } from "../../../../services/categoryProduct";
import { setCategories, addCategory, updateCategory, deleteCategory, selectCategory } from "../../../../redux/action/categoryProductAction";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Input from "../../../../Components/CustomInput";
import InputCategoryForm from "../../../../Components/InputGroupForm";
import PaginationSize from "../../../../Components/paginationSize"; 
import { IoTrash } from "react-icons/io5";
import "./categoryProuct.css";

const Table = lazy(() => import("../../../../Components/Table/GenericTable") );

function CategoryProduct (props) {
    let [filter, setFilter] = useState("");
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [stateCategory, setStateCategory] = useState({});
    // eslint-disable-next-line
    let [stateData, setStateData] = useState({});
    let [isUpdate, setIsUpdate] = useState(false);
    let [id, setId] = useState(0);

    const handleFilterChange = async (e) => {
        setFilter(e.target.value); // filter
        await getCategories(e.target.value);
    }

    const handleChange = (e) => {
        stateCategory = {  ...stateCategory, [e.target.name] : e.target.value }
        setStateCategory(stateCategory);
    }

    const getCategories = async (filter="", pageSize=20, page=1) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
            setLoadingData(true);
            try {
                const res = await _getCategories(filter, pageSize, page);
                setStateData({
                    currentPage: res.data.pager.currentPage,
                    pageSize: res.data.pager.pageSize,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                });
                if(res.status === 200){
                    setLoadingData(false);
                    props.setCategories(res.data.rows);
                }else{
                    setLoadingData(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e.message, {timeOut: 5000});
                setLoadingData(false);
            }
        }
    };

    const createCategory = async (e) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_PRODUCT_CATEGORY)){
            setLoading(true);
            if(!loading){
                try{
                    console.log({stateCategory});
                    const res = await _createCategory(stateCategory);
                    console.log({res});
                    if(res.status === 200){
                        props.addCategory(res.data);
                        setStateCategory({name: '', description: ''});
                    }else{
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                    setLoading(false);
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e.message, {timeOut: 5000});
                    setLoading(false);
                }
            }
        }else{
            toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
    }

    const readValue = (item) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_PRODUCT_CATEGORY)){
            let stateCategory = {
                name : item.originalData?.name,
                description: item.originalData?.description,
            };
            setStateCategory(stateCategory);
            setId(item.originalData?.id);
            setIsUpdate(true);
        }
    }

    const updateCategory = async (e) => {
        e.stopPropagation();
        setLoading(true);
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_PRODUCT_CATEGORY)){
            if(!loading){
                try{
                    const res = await _updateCategory(stateCategory, id);
                    if(res.status === 200){
                        props.updateCategory(res.data);
                        setStateCategory({name: '', description: ''});
                        setIsUpdate(false);
                    }else{
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                    setLoading(false);
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e.message, {timeOut: 5000});
                    setLoading(false);
                }
            }
        }else{
            toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
            setLoading(false);
        }
    }

    const deleteCategory = async (e, id) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_PRODUCT_CATEGORY)){
            const toastrConfirmOptions = {
                onOk: () => delCategory(id),
                onCancel: () => {}
            };
            toastr.confirm("Do you really want to delete this group?", toastrConfirmOptions);
        }
    }
    const delCategory = async (id) => {
        setLoading(true);
        try{
            const res = await _deleteCategory(id);
            if(res.status === 200){
                setLoading(false);
                props.deleteCategory(id);
            }else{
                setLoading(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
        }catch(e){
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
        }
    }

    const resetForm = () => {
        setStateCategory({name: '', description: ''});
        setIsUpdate(false);
    }

    const renderAction = (item) => {
        return (
            <div className="size-30 pointer"> 
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_PRODUCT_CATEGORY) ? 
                <button className="btn btn-action"  onClick={(e) => deleteCategory(e, item.id)}> <IoTrash /> </button> : 
                <></>} 
            </div>
        )
    }

    const headerData = [
        { title: <div className="size-30 flex-center  rounded"> # </div> , align: "center", className: '', width: 50, name: "number"}, 
        { title: "Name"  , align: "left", className: 'pl-15', width: "auto", name: "name"},
        { title: "Description"  , align: "left", className: 'pl-15', width: "50%", name: "description"},
        { title: <> Actions </> , align: "left", className: '', width: "5%", name: "actions"}, 
    ];

    const formatBodyData = (data) => {
        return data?.map((item, i) => {
            let resData = {
                originalData: item,
                number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i+1} </div>,
                name: item.name,
                description: item.description,
                actions: renderAction(item),
            }
            return resData;
        })
    }

    const setSize = async (pageSize) => {
        await getCategories("", pageSize, 1);
    }

    const setPage = async (page) => {
        if(stateData.currentPage !== page){
            await getCategories("", stateData.pageSize, page);
        }
    }

    const renderPaginarion = () => {
        if(+stateData.totalPages <= 1) return;
        let items = [];
        for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)} className="pointer">
                {number}
                </Pagination.Item>,
            );
        }
        return(
            <div className="d-flex align-items-center justify-content-between mt-4">
                <Pagination>
                    <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}  onClick={() => setPage(+stateData.startPage)}/>
                    <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage} onClick={() => setPage(stateData.currentPage - 1)} />
                    {items}
                    <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage} onClick={() => setPage(stateData.currentPage + 1)} />
                    <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage} onClick={() => setPage(+stateData.endPage)} />
                </Pagination>
                <PaginationSize value={stateData.pageSize} onChange={setSize} className="" />
            </div>
        )
    }

    useEffect(() => {
        (async (filter="", pageSize=20, page=1) => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
                setLoadingData(true);
                try {
                    const res = await _getCategories(filter, pageSize, page);
                    setStateData({
                        currentPage: res.data.pager.currentPage,
                        pageSize: res.data.pager.pageSize,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                    });
                    if(res.status === 200){
                        setLoadingData(false);
                        props.setCategories(res.data.rows);
                    }else{
                        setLoadingData(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur ici', e, {timeOut: 5000});
                    setLoadingData(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);

    return (
        <div className="groups-view">
            <div className="page-action">
                <div className="d-flex align-items-center">
                    <Loader loading={loading} />
                </div>
                <div className="search-content-form">
                    <Input 
                        type="text" 
                        globalClassName="search-input" 
                        name="filter"
                        value={filter} 
                        placeholder="Enter a keyword..."
                        onChange={handleFilterChange} 
                    />
                </div>
            </div>

            <InputCategoryForm
                nameKey="name"
                name={stateCategory?.name}
                descriptionKey="description"
                description={stateCategory?.description}
                onNameChange={handleChange}
                onDescChange={handleChange}
                onSubmit={createCategory}
                onSubmitUpdate={updateCategory}
                onReset={() => resetForm()}
                isUpdate={isUpdate}
            />

            <Suspense fallback={<Loader loading />} >
                    <Table
                        header={headerData}
                        body={formatBodyData(props.categories)}
                        className=''
                        hoverColor="rgba(0, 0, 0, 0.05)"
                        isLoading={loadingData}
                        asLoader={<Loader loading />}
                        onSelect={(item) => readValue(item)}
                        selectedClass="active"
                    />
                { renderPaginarion() }
            </Suspense>


        </div>
    )
}

const mapStateToProps = (state) => {
    const {categoryProducts} = state;
    return { 
        categories : categoryProducts.categories,
        selectedCategory : categoryProducts.selectedCategory,
    }
}

export default connect(mapStateToProps, { setCategories, addCategory, updateCategory, deleteCategory, selectCategory })(CategoryProduct);
