import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _createCampaigns = async (shopId, data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.SEND_SMS } };
    return await axios.post(`sms`, data, config);
}

export const _getCampaign = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_SMS } };
    return await axios.get(`sms?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getSmsCountByShopId = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_SMS } };
    return await axios.get(`shops/${id}/sms-count`, config);
}

export const _deleteCampaign = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_SMS } };
    return await axios.delete(`sms/${id}`, config);
}