import React, {useState, useEffect, Suspense} from "react";
import PageLayout from "../../../../layouts/defaultContentPage";
import {PAGE} from "../../../../constants/constants";
import {_checkAccessOrRedirect} from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Input from "../../../../Components/CustomInput/CustomInput";
import Label from "../../../../Components/label";
import { GoSignIn, GoSignOut } from "react-icons/go";
import { _getOperations, } from "../../../../services/Inventory";
  import {toastr} from "react-redux-toastr";
  import Table from "../../../../Components/Table/GenericTable";
  import {connect} from "react-redux";
  import {
    setOperations,
    selectOperation,
  } from "../../../../redux/action/InventoryAction";
  import ModalRight from "../../../../Components/ModalRight";
  import {Pagination} from "react-bootstrap";
  import PaginationSize from "../../../../Components/paginationSize";
  import {FUNCTIONALITIES_CODE} from "../../../../constants/AuthConstants";
  import moment from "moment";

import "./operation.css";

function Operation (props) {
    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState();
    let [loadingData, setLoadingData] = useState(false);
    let [stateData, setStateData] = useState({});
    let [displayForm, setDisplayForm] = useState(null);


    useEffect(() => {
        (async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_OPERATIONS)) {
            setLoadingData(true);
            try {
              const res = await _getOperations(filter, pageSize, page);
              setStateData({
                currentPage: res.data.pager.currentPage,
                pageSize: res.data.pager.pageSize,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
              });
              if (res.status === 200) {
                setLoadingData(false);
                props.setOperations(res.data.rows);
              } else {
                setLoadingData(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingData(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, []);
    
      const getFilterProducts = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_OPERATIONS)) {
          setLoadingData(true);
          try {
            const res = await _getOperations(filter, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoadingData(false);
              props.setOperations(res.data.rows);
            } else {
              setLoadingData(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoadingData(false);
          }
        }
      };
    
      const renderDetailOperation = () => {
        return (
          <div className="p-2">
            <div className="item-group">
              <div className="item-left">Operation Date</div>
              <div className="item-right" style={{color: "red"}} >{moment(props.selectedOperation?.createdAt).format("YYYY-MM-DD, HH : mm") }</div>
            </div>

            <div className="item-group">
              <div className="item-left">Operation type</div>
              <div className="item-right"> 
                <Label 
                    statement={props.selectedOperation?.operationType} 
                    icon={ props.selectedOperation?.operationType === "IN" ? GoSignIn : GoSignOut}
                    style={{width: "50px"}}
                /> 
              </div>
            </div>

            <div className="item-group">
              <div className="item-left">Initial stock</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.initialStock ? props.selectedOperation?.initialStock : 0}</b></div>
            </div>
            <div className="item-group">
              <div className="item-left">Stock In</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.stockIn}</b></div>
            </div>

            <div className="item-group">
              <div className="item-left">Stock Out</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.stockOut}</b></div>
            </div>

            <div className="item-group">
              <div className="item-left">Final Out</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.finalStock}</b></div>
            </div>

            <h5 className="title-5 mt-3">Product Concerned</h5>

            <div className="item-group">
              <div className="item-left">Designation</div>
              <div className="item-right">{props.selectedOperation?.inventoryProduct?.designation}</div>
            </div>

            <div className="item-group">
              <div className="item-left">Code ID</div>
              <div className="item-right">{props.selectedOperation?.inventoryProduct?.codeID}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Designation</div>
              <div
                className="item-right">{props.selectedOperation?.inventoryProduct?.designation}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Conditionnement</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.inventoryProduct?.conditionnement}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Initial Stock</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.inventoryProduct?.initialStock}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Unit Price</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{props.selectedOperation?.inventoryProduct?.unitPrice} XAF</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Product category</div>
              <div className="item-right">{props.selectedOperation?.inventoryProduct?.categoryProduct?.name}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Created At</div>
              <div className="item-right">{moment(props.selectedOperation?.categoryProduct?.createdAt).format("YYYY-MM-DD, HH : mm")}</div>
            </div>
            
          </div>
        )
      }
    
      const renderRightModal = () => {
        switch (displayForm) {
          case "preview" : {
            return (
              <ModalRight
                title="Operation detail"
                content={renderDetailOperation()}
                isVisible={isRightVisible}
                onClose={(e) => setIsRightVisible(false)}
              />
            )
          }
    
          default :
            return <></>
        }
      }
    
      const selectElement = (item) => {
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_OPERATIONS)) {
          props.selectOperation(item?.originalData);
          setIsRightVisible(true);
          setDisplayForm("preview");
        }else{
            console.log("not have permission!");
        }
      }
  
      const headerData = [
        {
          title: <div className="size-30 flex-center  rounded"> # </div>,
          align: "center",
          className: '',
          width: 50,
          name: "number"
        },
        {title: "Date", align: "left", className: 'pl-15', width: "150px", name: "date"},
        {title: "Type", align: "left", className: 'pl-15', width: "150px", name: "operationType"},
        {title: "Initial stock", align: "right", className: 'pl-15 sm-hide', width: "50px", name: "initialStock"},
        {title: "Stock In", align: "right", className: 'pl-15', width: "auto", name: "stockIn"},
        {title: "Stock Out", align: "right", className: 'pl-15', width: "auto", name: "stockOut"},
        {title: "Final stock", align: "right", className: 'pl-15', width: "auto", name: "finalStock"},
        {title: "Products", align: "left", className: 'pr-15 md-hide', width: "auto", name: "product"},
      ];
    
      const formatBodyData = (data) => {
        return data?.map((item, i) => {
          let resData = {
            originalData: item,
            number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
            date: <b style={{color: "#1179AD"}}>{moment(item?.createdAt).format("YYYY-MM-DD, HH : mm")}</b>,
            operationType: <Label 
                statement={item.operationType} 
                icon={ item?.operationType === "IN" ? GoSignIn : GoSignOut}
                style={{width: "50px", color: item?.operationType === "IN" ? "green" : "red"}}
            /> ,
            initialStock: <b style={{color: "#1179AD"}}>{item?.initialStock}</b>,
            stockIn: <b style={{color: "green"}}>{item.stockIn > 0 ? item.stockIn : ""}</b> ,
            stockOut: <b style={{color: "red"}}>{item.stockOut > 0 ? item.stockOut : ""}</b> ,
            finalStock: <b style={{color: "#444444"}}>{item.finalStock}</b> ,
            product: item?.inventoryProduct?.designation,
          }
          return resData;
        })
      }
    
      const setPage = async (page) => {
        if (stateData.currentPage !== page) {
          await getFilterProducts("", stateData.pageSize, page);
        }
      }
    
      const setSize = async (pageSize) => {
        await getFilterProducts("", pageSize, 1);
      }
    
      const renderPaginarion = () => {
        if (stateData.totalPages > 1) {
          let items = [];
          for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
                className="pointer">
                {number}
              </Pagination.Item>,
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Pagination>
                <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.startPage)}/>
                <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage - 1)}/>
                {items}
                <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage + 1)}/>
                <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.endPage)}/>
              </Pagination>
              <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
            </div>
          )
        } else {
          return <></>
        }
      }
    
      const handleFilterChange = async (e) => {
        setFilter(e.target.value);
        await getFilterProducts(e.target.value);
      }


    return(
        <PageLayout
            title={PAGE.INVENTORY}
            rightElement={null}
            isRightVisible={false}
        >
      <>
        <div className="product-list-view">
            <div className="page-action">
                <div className="d-flex aligns-items-center">
                    <Loader loading={false}/>
                </div>
                <div className="search-content-form">
                    <Input
                        type="text"
                        globalClassName="search-input"
                        name="filter"
                        value={filter}
                        placeholder="Enter a keyword..."
                        onChange={(e) => handleFilterChange(e)}
                    />
                </div>
            </div>

            <Suspense fallback={<Loader loading/>}>
                <Table
                header={headerData}
                body={formatBodyData(props.operations)}
                className='mr-3'
                hoverColor="rgba(0, 0, 0, 0.05)"
                isLoading={loadingData}
                asLoader={<Loader loading/>}
                onSelect={(item) => selectElement(item)}
                selectedClass="active"
                />
                {renderPaginarion()}
            </Suspense>
        </div>
        {renderRightModal()}
    </>
    </PageLayout>
    )
}


const mapStateToProps = (state) => {
    const {inventory } = state;
    return {
      operations: inventory.operations, 
      selectedOperation: inventory.selectedProduct,
    };
  }
  
  export default connect(mapStateToProps, {
    setOperations,
    selectOperation,
  })(Operation);
