import { SET_SHOP, SET_SMS_COUNT } from '../action-type/shopType';

const INITIAL_STATE = {
    shop: {},
    smsCount: {}
};

const ShopReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_SHOP: {
        if (action.payload) {
          return {
            ...state,
            shop: action.payload
          }
        }
        return state;
      }

      case SET_SMS_COUNT: {
        if (action.payload) {
          return {
            ...state,
            smsCount: action.payload
          }
        }
        return state;
      }
  
      default:
        return state
    }
}
  
export default ShopReducer;
