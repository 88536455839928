export const SET_CASHIER_OPERATIONS = 'SET_CASHIER_OPERATIONS';
export const ADD_CASHIER_OPERATION = 'ADD_CASHIER_OPERATION';
export const ADD_CASHIER_OPERATIONS = 'ADD_CASHIER_OPERATIONS';
export const UPDATE_CASHIER_OPERATION = 'UPDATE_CASHIER_OPERATION';
export const DELETE_CASHIER_OPERATION = 'DELETE_CASHIER_OPERATION';
export const SELECT_CASHIER_OPERATION = 'SELECT_CASHIER_OPERATION';

export const SET_CASHIER_OPERATION_LINES = 'SET_CASHIER_OPERATION_LINES';
export const ADD_CASHIER_OPERATION_LINE = 'ADD_CASHIER_OPERATION_LINE';
export const UPDATE_CASHIER_OPERATION_LINE = 'UPDATE_CASHIER_OPERATION_LINE';
export const DELETE_CASHIER_OPERATION_LINES = 'DELETE_CASHIER_OPERATION_LINES';
export const SELECT_CASHIER_OPERATION_LINE = 'SELECT_CASHIER_OPERATION_LINE';