import React, {useState, useEffect, Suspense} from "react";
import moment from "moment";
import PageLayout from "../../../../layouts/defaultContentPage";
import {PAGE} from "../../../../constants/constants";
import BtnAdd from "../../../../Components/BtnAdd";
import {_checkAccessOrRedirect} from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Input from "../../../../Components/CustomInput/CustomInput";
// import Label from "../../../../Components/label";
import {
    _getServices,
    _createService,
    _updateService,
    _deleteService,
  } from "../../../../services/services";
import { _getCategories } from "../../../../services/categoryService";
  import {toastr} from "react-redux-toastr";
  import {IoTrash, IoPencil} from "react-icons/io5";
  import Table from "../../../../Components/Table/GenericTable";
  import {connect} from "react-redux";
  import {
    addService,
    setServices,
    updateService,
    deleteService,
    selectService,
  } from "../../../../redux/action/serviceAction";
import { setCategories } from "../../../../redux/action/categoryProductAction";
  import ModalRight from "../../../../Components/ModalRight";
  import Button from "../../../../Components/InputButton/InputButton";
  import {Pagination} from "react-bootstrap";
  import PaginationSize from "../../../../Components/paginationSize";
  import {FUNCTIONALITIES_CODE} from "../../../../constants/AuthConstants";
  // import moment from "moment";
  import { numberFormat } from "../../../../helpers/Number";

import "./service.css";

function Service (props) {
    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState();
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [loadingForm, setLoadingForm] = useState(false);
    let [stateService, setStateService] = useState({ 
      codeID: "", 
      name: "", 
      amount: 0, 
      categoryProductId: "",
      startPromoDate: "",
      endPromoDate: "",
    });
    let [stateErrorForm, setStateErrorForm] = useState(
      { 
        codeID: {valid: false, error: ""}, 
        name: {valid: false, error: ""}, 
        amount: {valid: false, error: ""}, 
        categoryProductId: {valid: false, error: ""} 
    });
    let [stateData, setStateData] = useState({});
    let [displayForm, setDisplayForm] = useState(null);
    let [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
      setStateService({ 
        codeID: "", 
        name: "", 
        amount: 0, 
        categoryProductId: "",
        startPromoDate: "",
        endPromoDate: "", 
      });
      // eslint-disable-next-line
    },[])

    useEffect(() => {
        (async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SERVICE)) {
            setLoadingData(true);
            try {
              const res = await _getServices(filter, pageSize, page);
              setStateData({
                currentPage: res.data.pager.currentPage,
                pageSize: res.data.pager.pageSize,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
              });
              if (res.status === 200) {
                setLoadingData(false);
                console.log("services: ", res.data.rows);
                props.setServices(res.data.rows);
              } else {
                setLoadingData(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingData(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, []);

      // categories
      useEffect(() => {
        (async (filter="", pageSize=1000, page=1) => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
                setLoadingData(true);
                try {
                    const res = await _getCategories(filter, pageSize, page);
                    
                    if(res.status === 200){
                        setLoadingData(false);
                        props.setCategories(res.data.rows);
                    }else{
                        setLoadingData(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur ici', e.message, {timeOut: 5000});
                    setLoadingData(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);
    
      const getFilterServices = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SERVICE)) {
          setLoadingData(true);
          try {
            const res = await _getServices(filter, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoadingData(false);
              props.setServices(res.data.rows);
            } else {
              setLoadingData(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoadingData(false);
          }
        }
      };
    
      // Handle change for create customer form
      const handleCSChange = (e) => {
        stateService = {...stateService, [e.target.name]: e.target.value}
        setStateService(stateService);
      }

      const handleCSChangeDate = (e) => {
        stateService = {...stateService, [e.target.name]: e.target.value };
        setStateService(stateService);
      }

      const validateForm = () => {
        const testCodeID = stateService?.codeID?.length ? true : false;
        const testName = stateService?.name?.length ? true : false;
        const testAmount = +stateService?.amount >= 0 ? true : false;
        const testProductService = +stateService?.categoryServiceId > 0 ? true : false;

        if(!testCodeID){ setStateErrorForm({...stateErrorForm, codeID : { valid: !!testCodeID, error: "This fild is required!" } })  } else { setStateErrorForm({...stateErrorForm, codeID : { valid: true, error: "" } }) }
        if(!testName){ setStateErrorForm({...stateErrorForm, name : { valid: !!testName, error: "This fild is required!" } }) } else { setStateErrorForm({...stateErrorForm, name : { valid: true, error: "" } }) }
        if(!testAmount){ setStateErrorForm({...stateErrorForm, amount : { valid: !!testAmount, error: "This fild has not to be null" } })  } else { setStateErrorForm({...stateErrorForm, amount : { valid: true, error: "" } }) }
        if(!testProductService){ setStateErrorForm({...stateErrorForm, categoryServiceId : { valid: !!testProductService, error: "This fild is required!" } })  } else { setStateErrorForm({...stateErrorForm, categoryServiceId : { valid: true, error: "" } }) }
        return testCodeID && testName && testProductService && testAmount;

      }
    
      const createService = async (e) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_SERVICE)) {
          if (!loading && validateForm()) {
            if(stateService.startPromoDate){
              if(!stateService.endPromoDate){
                toastr.error('Error', "You have to set the END promo date", {timeOut: 3000});
                return;
              }
            }

            if(stateService.endPromoDate){
              if(!stateService.startPromoDate){
                toastr.error('Error', "You have to set the START promo date", {timeOut: 3000});
                return;
              }
            }

            if(stateService.startPromoDate && stateService.endPromoDate && (stateService.endPromoDate < stateService.startPromoDate)){
              toastr.error('Error', "The START promo date has to be less or equal to END promo date", {timeOut: 3000});
              return;
            }

            try {
              setLoadingForm(true);
              stateService = { ...stateService, shopId: +props.shop.id };
              const res = await _createService(stateService);
              if (res.status === 200) {
                props.addService(res.data);
                setStateService({});
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
              setLoadingForm(false);
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingForm(false);
            }
          }else{
            toastr.error('Error', "Please fill all the required fields!", {timeOut: 3000});
          }
        } else {
          toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
      }
    
      const editService = async (e) => {
        e.stopPropagation();
        setLoadingForm(true);
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_SERVICE)) {
          if (!loading) {
            try {
                delete stateService.categoryService;
                delete stateService.operationInventories;
              const res = await _updateService(stateService, stateService.id);
              if (res.status === 200) {
                props.updateService(res.data);
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
              setLoadingForm(false);
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingForm(false);
            }
          }
        } else {
          toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
      }

      const renderCategories = () => {
          return props?.categories?.map((item, i) => {
              return (
                  <option value={item.id} key={i}>{item.name}</option>
              )
          })
      }

      const calculatePromo = () => {
        if(stateService.discount && +stateService.discount > 0 && +stateService.discount <= 100){
          const discountPercent = stateService.discount ? +stateService.discount : 0;
          const valueDiscount = (stateService.amount * discountPercent) / 100;
          const priceDiscount = +stateService.amount - valueDiscount;
          stateService = {...stateService, promo: priceDiscount };
          setStateService(stateService);
        }else{
          stateService = {...stateService, promo: 0, discount: 0 };
          setStateService(stateService);
        }
      }

      const handleDiscount = (e) => {
        const val = (e.target.value && +e.target.value > 0) ? e.target.value : 0;
        stateService = {...stateService, [e.target.name]: val};
        setStateService(stateService);
        calculatePromo();
      }

      const handleUnitPrice = (e) => {
        stateService = {...stateService, [e.target.name]: e.target.value};
        setStateService(stateService);
        calculatePromo();
      }
    
      const renderFormCreate = () => {
        return (
          <form name="sellerCreateForm" className="m-2">
            <div style={{color: "#555555"}} className="mb-2">Fields with (<b style={{color: "red"}}>*</b>) sign are required!</div>

            <div className="form-group">
              <label htmlFor="categoryServiceId" className="label-input">Service category: <b style={{color: "red"}}>*</b> </label>
              <select className="form-control select" name="categoryServiceId" id="categoryServiceId" onChange={handleCSChange} defaultValue={stateService.categoryServiceId || ''}>
                  <option value="">Select a category...</option>
                  { renderCategories() }
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="codeID" className="label-input">Code ID: <b style={{color: "red"}}>*</b> </label>
              <input className="form-control" type="text" name="codeID" id="codeID" value={stateService.codeID || ''}
                     onChange={handleCSChange} required/>
            </div>
    
            <div className="form-group">
              <label htmlFor="name" className="label-input">Designation: <b style={{color: "red"}}>*</b> </label>
              <input className="form-control" type="text" name="name" id="name" value={stateService.name || ''}
                     onChange={handleCSChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="amount" className="label-input">Amount: <b style={{color: "red"}}>*</b> </label>
              <input className="form-control" type="number" name="amount" id="amount" value={stateService.amount || ''}
                     onChange={handleUnitPrice} required/>
            </div>

            <div className="form-group">
              <label htmlFor="discount" className="label-input">Discount (%): </label>
              <input className="form-control" type="number" name="discount" id="discount" value={stateService.discount || ""}
                     onChange={handleDiscount}/>
            </div>

            <div className="form-group">
              <label htmlFor="startPromoDate" className="label-input">Start promotion date: </label>
              <input className="form-control" type="date" name="startPromoDate" id="startPromoDate" value={stateService.startPromoDate || ''}
                onChange={handleCSChangeDate}/>
            </div>

            <div className="form-group">
              <label htmlFor="endPromoDate" className="label-input">End promotion date: </label>
              <input className="form-control" type="date" name="endPromoDate" id="endPromoDate" value={stateService.endPromoDate || ''}
                onChange={handleCSChangeDate}/>
            </div>

            <div className="form-group">
              <label htmlFor="promo" className="label-input">Promotion price: </label>
              <input className="form-control" type="number" name="promo" id="promo" value={stateService.promo || ""} disabled />
            </div>
    
            <div className="separator mb-2 mt-2"></div>
            <div className="form-group">
              <Button
                variant="primary"
                value={isEdit ? "Update" : "Submit"}
                className="m-0 form-control"
                loading={loadingForm}
                onClick={isEdit ? editService : createService}
              />
            </div>
          </form>
        )
      }
    
      const renderDetailService = () => {
        return (
          <div className="p-2">
             <div className="item-group">
              <div className="item-left">Service category</div>
              <div className="item-right">{props.selectedService?.categoryService?.name}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Code ID</div>
              <div className="item-right">{props.selectedService.codeID}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Designation</div>
              <div className="item-right">{props.selectedService.name}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Amount</div>
              <div
                className="item-right"><b style={{color: "green"}}>{`${numberFormat(props.selectedService.amount, 2, ".", " ")} XAF`}</b> </div>
            </div>

            <div className="item-group">
              <div className="item-left">Discount</div>
              <div
                className="item-right"><b style={{color: "red"}}>{`-${numberFormat(props.selectedService.discount, 2, ".", " ")} %`}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Start promo date</div>
              <div className="item-right">{`${moment(props.selectedService.startPromoDate).format("YYYY-MM-DD")}`} </div>
            </div>
            <div className="item-group">
              <div className="item-left">End promo date</div>
              <div className="item-right">{`${moment(props.selectedService.endPromoDate).format("YYYY-MM-DD")}`} </div>
            </div>
            <div className="item-group">
              <div className="item-left">Promotion price</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{`${numberFormat(props.selectedService.promo, 2, ".", " ")} XAF`}</b> </div>
            </div>
           
          </div>
        )
      }
    
      const renderRightModal = () => {
        switch (displayForm) {
          case "new" : {
            return (
              <ModalRight
                title={isEdit ? "Edit Service" : "New Service"}
                content={renderFormCreate()}
                isVisible={isRightVisible}
                onClose={(e) => setIsRightVisible(false)}
              />
            )
          }
          case "preview" : {
            return (
              <ModalRight
                title="Service detail"
                content={renderDetailService()}
                isVisible={isRightVisible}
                onClose={(e) => setIsRightVisible(false)}
              />
            )
          }
    
          default :
            return <></>
        }
      }
    
      const displayCreateServiceForm = (e) => {
        e.preventDefault();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_SERVICE)) {
          setIsRightVisible(true);
          setIsEdit(false);
          setStateService({});
          setDisplayForm("new");
        }
      }
    
      const deleteService = async (e, id) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_SERVICE)) {
          const toastrConfirmOptions = {
            onOk: () => delService(id),
            onCancel: () => {
            }
          };
          toastr.confirm("Do you really want to delete this service ?", toastrConfirmOptions);
        }
      }
    
      const delService = async (id) => {
        setLoading(true);
        try {
          const res = await _deleteService(id);
          if (res.status === 200) {
            setLoading(false);
            props.deleteService(id);
          } else {
            setLoading(false);
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoading(false);
        }
      }
    
      const displayEditServiceForm = (e, item) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_SERVICE)) {
          // const {id, ...customer} = item
          setIsEdit(true);
          setStateService(item);
          setIsRightVisible(true);
          setDisplayForm("new");
        }
      }
    
      const selectElement = (item) => {
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SERVICE)) {
          props.selectService(item?.originalData);
          setIsRightVisible(true);
          setDisplayForm("preview");
        }
      }
    
      const renderAction = (item) => {
        return (
          <div>
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_SERVICE) ?
              <button className="btn btn-action" onClick={(e) => displayEditServiceForm(e, item)}>
                <IoPencil color="blue"/>
              </button> :
              <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_SERVICE) ?
              <button className="btn btn-action" onClick={(e) => deleteService(e, item.id)}><IoTrash/></button> :
              <></>}
          </div>
        )
      }
    
      const headerData = [
        {
          title: <div className="size-30 flex-center  rounded"> # </div>,
          align: "center",
          className: '',
          width: 50,
          name: "number"
        },
        {title: "Designation", align: "left", className: 'pl-15', width: "auto", name: "designation"},
        {title: "Code ID", align: "left", className: 'pl-15 sm-hide', width: "150px", name: "codeID"},
        {title: "Category", align: "left", className: 'pl-15', width: "auto", name: "category"},
        {title: "Amount", align: "right", className: 'pr-5', width: "auto", name: "amount"},
        {title: "Discount", align: "center", className: '', width: "50px", name: "discount"},
        {title: "Promo price", align: "right", className: 'pr-2', width: "auto", name: "promo"},
        {title: "Promo period", align: "left", className: 'pr-2', width: "auto", name: "promoPeriod"},
        {title: <> Actions </>, align: "center", className: '', width: "150px", name: "action"},
      ];
    
      const formatBodyData = (data) => {
        return data?.map((item, i) => {
          let resData = {
            originalData: item,
            number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
            codeID: item.codeID,
            designation: item.name,
            category: item?.categoryService?.name,
            amount: <b style={{color: "green"}}>{`${numberFormat(item.amount, 2, ".", " ")} XAF`}</b>,
            discount: +item.discount > 0 ? <b style={{color: "red"}}>{`-${numberFormat(item.discount, 2, ".", " ")} %`}</b> : "",
            promo: item.promo > 0 ? <b style={{color: "#1179AD"}}>{`${numberFormat(item.promo, 2, ".", " ")} XAF`}</b> : "",
            promoPeriod: (item.startPromoDate && item.endPromoDate )? <><b style={{color: "#999999"}}>{`${moment(item.startPromoDate).format("YYYY-MM-DD")}`}</b> - <b style={{color: "#666666"}}>{`${moment(item.endPromoDate).format("YYYY-MM-DD")}`}</b></> : "",
            action: renderAction(item),
          }
          return resData;
        })
      }
    
      const setPage = async (page) => {
        if (stateData.currentPage !== page) {
          await getFilterServices("", stateData.pageSize, page);
        }
      }
    
      const setSize = async (pageSize) => {
        await getFilterServices("", pageSize, 1);
      }
    
      const renderPaginarion = () => {
        if (stateData.totalPages > 1) {
          let items = [];
          for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
                className="pointer">
                {number}
              </Pagination.Item>,
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Pagination>
                <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.startPage)}/>
                <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage - 1)}/>
                {items}
                <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage + 1)}/>
                <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.endPage)}/>
              </Pagination>
              <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
            </div>
          )
        } else {
          return <></>
        }
      }
    
      const handleFilterChange = async (e) => {
        setFilter(e.target.value);
        let filter = e.target.value ? e.target.value : "";
        await getFilterServices(filter, 20, 1);
      }


    return(
        <PageLayout
            title={PAGE.SERVICES}
            rightElement={null}
            isRightVisible={false}
        >
      <>
        <div className="service-list-view">
            <div className="page-action">
                <div className="d-flex aligns-items-center">
                    <BtnAdd
                        statement="New service"
                        onClick={(e) => displayCreateServiceForm(e)}
                        disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_SERVICE)}
                        className="mr-2"
                        style={{marginRight: 5}}
                    />
                    <Loader loading={loading}/>
                </div>
                <div className="search-content-form">
                    <Input
                        type="text"
                        globalClassName="search-input"
                        name="filter"
                        value={filter}
                        placeholder="Enter a keyword..."
                        onChange={(e) => handleFilterChange(e)}
                    />
                </div>
            </div>

            <Suspense fallback={<Loader loading/>}>
                <Table
                header={headerData}
                body={formatBodyData(props.services)}
                className=''
                hoverColor="rgba(0, 0, 0, 0.05)"
                isLoading={loadingData}
                asLoader={<Loader loading/>}
                onSelect={(item) => selectElement(item)}
                selectedClass="active"
                />
                {renderPaginarion()}
            </Suspense>
        </div>
        {renderRightModal()}
    </>
    </PageLayout>
    )
}


const mapStateToProps = (state) => {
    const {services, categoryProducts, auth} = state;
  
    return {
        services: services.services, 
        selectedService: services.selectedService,
        categories : categoryProducts.categories,
        shop : auth?.authData?.shop,
    };
  }
  
  export default connect(mapStateToProps, {
    addService,
    setServices,
    updateService,
    deleteService,
    selectService,
    setCategories,
  })(Service);
