import React, {useState, useEffect, lazy, Suspense } from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import { Pagination } from "react-bootstrap";
import {toastr} from 'react-redux-toastr';
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import { _getGroups, _createGroup, _updateGroup, _deleteGroup } from "../../../../services/Group";
import { setGroups, addGroup, updateGroup, deleteGroup, selectGoup } from "../../../../redux/action/GroupAction";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Input from "../../../../Components/CustomInput";
import InputGroupForm from "../../../../Components/InputGroupForm";
import PaginationSize from "../../../../Components/paginationSize"; 
import { FcTreeStructure } from "react-icons/fc";
import { IoTrash } from "react-icons/io5";
import "./groups.css";

const Table = lazy(() => import("../../../../Components/Table/GenericTable") );

function Groups (props) {
    let [filter, setFilter] = useState('');
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [stateGroup, setStateGroup] = useState({});
    // eslint-disable-next-line
    let [stateData, setStateData] = useState({});
    let [isUpdate, setIsUpdate] = useState(false);
    let [id, setId] = useState();

    const handleFilterChange = async (e) => {
        setFilter(e.target.value);
    }

    const handleChange = (e) => {
        stateGroup = {  ...stateGroup, [e.target.name] : e.target.value }
        setStateGroup(stateGroup);
    }

    const getGroup = async (filter="", pageSize=20, page=1) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_GROUP)){
            setLoadingData(true);
            try {
                const res = await _getGroups(filter, pageSize, page);
                setStateData({
                    currentPage: res.data.pager.currentPage,
                    pageSize: res.data.pager.pageSize,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                });
                if(res.status === 200){
                    setLoadingData(false);
                    props.setGroups(res.data.rows);
                }else{
                    setLoadingData(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e.message, {timeOut: 5000});
                setLoadingData(false);
            }
        }
    };

    const createGroup = async (e) => {
        e.stopPropagation();
        setLoading(true);
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_GROUP)){
            if(!loading){
                try{
                    const res = await _createGroup(stateGroup);
                    if(res.status === 200){
                        props.addGroup(res.data);
                        setStateGroup({name: '', description: ''});
                    }else{
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                    setLoading(false);
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e.message, {timeOut: 5000});
                    setLoading(false);
                }
            }
        }else{
            toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
        }
    }

    const readValue = (item) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_GROUP)){
            let stateGroup = {
                name : item.originalData?.name,
                description: item.originalData?.description,
            };
            setStateGroup(stateGroup);
            setId(item.originalData?.id);
            setIsUpdate(true);
            props.selectGoup(item.originalData);
        }
    }

    const updateGroup = async (e) => {
        e.stopPropagation();
        setLoading(true);
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_GROUP)){
            if(!loading){
                try{
                    const res = await _updateGroup(stateGroup, id);
                    if(res.status === 200){
                        props.updateGroup(res.data);
                        setStateGroup({name: '', description: ''});
                        setIsUpdate(false);
                    }else{
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                    setLoading(false);
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e.message, {timeOut: 5000});
                    setLoading(false);
                }
            }
        }else{
            toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
            setLoading(false);
        }
    }

    const deleteGroup = async (e, id) => {
        e.stopPropagation();
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_GROUP)){
            const toastrConfirmOptions = {
                onOk: () => delGroup(id),
                onCancel: () => {}
            };
            toastr.confirm("Do you really want to delete this group?", toastrConfirmOptions);
        }
    }
    const delGroup = async (id) => {
        setLoading(true);
        try{
            const res = await _deleteGroup(id);
            if(res.status === 200){
                setLoading(false);
                props.deleteGroup(id);
            }else{
                setLoading(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
        }catch(e){
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoading(false);
        }
    }

    const gotoAssignRightToGroup = (e) => {
        e.stopPropagation();
    }

    const resetForm = () => {
        setStateGroup({name: '', description: ''});
        setIsUpdate(false);
    }

    const renderAction = (item) => {
        return (
            <div className="size-30"> 
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT) ? 
                <button className="btn btn-action mr-1" title="Assign rights"  onClick={(e) => gotoAssignRightToGroup(e)}> 
                    <Link to={`${item.id}`}>
                        <FcTreeStructure />
                    </Link>
                </button> : 
                <></>}
                {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_GROUP) ? 
                <button className="btn btn-action mr-1" title="Delete item?"  onClick={(e) => deleteGroup(e, item.id)}> <IoTrash /> </button> : 
                <></>} 
            </div>
        )
    }

    const headerData = [
        { title: <div className="size-30 flex-center  rounded"> # </div> , align: "center", className: '', width: 50, name: "number"}, 
        { title: "Name"  , align: "left", className: 'pl-15', width: "auto", name: "name"},
        { title: "Description"  , align: "left", className: 'pl-15 sm-hide', width: "50%", name: "description"},
        { title: "Nb rights"  , align: "center", className: 'pl-15', width: 75, name: "nbrights"},
        { title: <> Actions </>, width: 100 , name: "actions"}, 
    ];

    const formatBodyData = (data) => {
        return data?.map((item, i) => {
            let resData = {
                originalData: item,
                number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i+1} </div>,
                name: item.name,
                description: item.description,
                nbrights: <b>{ item?.groupRights ? Object.keys(item?.groupRights).length : "0"}</b>,
                actions: renderAction(item),
            }
            return resData;
        })
    }

    const setSize = async (pageSize) => {
        await getGroup("", pageSize, 1);
    }

    const setPage = async (page) => {
        if(stateData.currentPage !== page){
            await getGroup("", stateData.pageSize, page);
        }
    }

    const renderPaginarion = () => {
        if(stateData.totalPages > 1) {
            let items = [];
            for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
                items.push(
                    <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)} className="pointer">
                    {number}
                    </Pagination.Item>,
                );
            }
            return(
                <div className="d-flex align-items-center justify-content-between mt-4">
                    <Pagination>
                        <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}  onClick={() => setPage(+stateData.startPage)}/>
                        <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage} onClick={() => setPage(stateData.currentPage - 1)} />
                        {items}
                        <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage} onClick={() => setPage(stateData.currentPage + 1)} />
                        <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage} onClick={() => setPage(+stateData.endPage)} />
                    </Pagination>
                    <PaginationSize value={stateData.pageSize} onChange={setSize} className="" />
                </div>
            )
        }else{
            return <></>
        }
    }

    useEffect(() => {
        (async () => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_GROUP)){
                setLoadingData(true);
                try {
                    const res = await _getGroups(filter, 20, 1);
                    setStateData({
                        currentPage: res.data.pager.currentPage,
                        pageSize: res.data.pager.pageSize,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                    });
                    if(res.status === 200){
                        setLoadingData(false);
                        props.setGroups(res.data.rows);
                    }else{
                        setLoadingData(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e, {timeOut: 5000});
                    setLoadingData(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, [filter]);

    return (
        <div className="groups-view">
            <div className="page-action">
                <div className="d-flex align-items-center">
                    <Loader loading={loading} />
                </div>
                <div className="search-content-form">
                    <Input 
                        type="text" 
                        globalClassName="search-input" 
                        name="filter"
                        value={filter} 
                        placeholder="Enter a keyword..."
                        onChange={handleFilterChange} 
                    />
                </div>
            </div>

            <InputGroupForm
                nameKey="name"
                name={stateGroup?.name}
                descriptionKey="description"
                description={stateGroup?.description}
                onNameChange={handleChange}
                onDescChange={handleChange}
                onSubmit={createGroup}
                onSubmitUpdate={updateGroup}
                onReset={() => resetForm()}
                isUpdate={isUpdate}
            />

            <Suspense fallback={<Loader loading />} >
                    <Table
                        header={headerData}
                        body={formatBodyData(props.groups)}
                        className=''
                        hoverColor="rgba(0, 0, 0, 0.05)"
                        isLoading={loadingData}
                        asLoader={<Loader loading />}
                        onSelect={(item) => readValue(item)}
                        selectedClass="active"
                    />
                { renderPaginarion() }
            </Suspense>


        </div>
    )
}

const mapStateToProps = (state) => {
    const {groups} = state;
    return { 
        groups : groups.groups,
        selectedGroup : groups.selectedGroup,
    }
}

export default connect(mapStateToProps, { setGroups, addGroup, updateGroup, deleteGroup, selectGoup })(Groups);
