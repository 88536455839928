import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP, DELETE_GROUP, SELECT_GROUP} from '../action-type/GroupType';

const INITIAL_STATE = {
    groups : [],
    selectedGroup : {},
};

const GroupReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_GROUPS : {
            return { ...state, groups: action.payload };
        }
        case ADD_GROUP: {
            if (action.payload) {
                return {
                    ...state,
                    groups: [ action.payload, ...state.groups],
                }
            }
            return state;
        }
        case UPDATE_GROUP: {
            if (action.payload) {
                const i = state.groups.findIndex(elm => elm.id === action.payload.id);
                state.groups[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_GROUP: {
            if (action.payload) {
                const {groups} = state;
                const i = groups.findIndex(elm => elm.id === action.payload);
                groups.splice(i, 1);
                return { ...state, groups }
            }
            return {...state};
        }
        case SELECT_GROUP: {
            return {
                ...state,
                selectedGroup: action.payload
            }
        }
        default: return state;
    }
}

export default GroupReducer;