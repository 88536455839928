import {
    SET_SERVICES, ADD_SERVICE, UPDATE_SERVICE, SELECT_SERVICE, DELETE_SERVICE
  } from '../action-type/serviceType';
  
  const INITIAL_STATE = {
    services: [],
    selectedService: {}
  };
  
  const ServiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_SERVICES: {
        if (action.payload) {
          return {
            ...state,
            services: action.payload
          }
        }
        return state;
      }
      case ADD_SERVICE: {
        if (action.payload) {
          return {
            ...state,
            services: [action.payload, ...state.services]
          }
        }
        return state;
      }
      case UPDATE_SERVICE: {
        if (action.payload) {
          const i = state.services.findIndex(elm => elm.id === action.payload.id);
          state.services[i] = action.payload;
          return state;
        }
        return state;
      }
      case SELECT_SERVICE: {
        return {
          ...state,
          selectedService: action.payload
        }
      }
      case DELETE_SERVICE: {
        if (action.payload) {
          let {services} = state;
          const i = services.findIndex(elm => elm.id === action.payload);
          services.splice(i, 1);
          return {...state, services};
        }
        return state;
      }
  
      default:
        return state
    }
  }
  
  export default ServiceReducer;
  