import React from "react";

const ToggleMenuComponent = () => (
  <svg viewBox="0 0 32.077 28.708" width={(32.077 * 2 )/3} height={(28.708 * 2 )/3}>
    <defs>
      <style>
        {
          ".a-menu{fill:#ffbfdd;}.a-menu,.c-menu{opacity:0.5;}.b-menu{fill:#ffa0cc;}.c-menu{fill:#ffa5cf;}"
        }
      </style>
    </defs>
    <g transform="translate(-4 -6.2)">
      <path
        className="a-menu"
        d="M24.233,11.865H37.324a2.826,2.826,0,0,0,2.833-2.833h0A2.826,2.826,0,0,0,37.324,6.2H24.233A2.826,2.826,0,0,0,21.4,9.033h0A2.826,2.826,0,0,0,24.233,11.865Z"
        transform="translate(-4.079)"
      />
      <path
        className="b-menu"
        d="M6.833,26.965H33.168A2.826,2.826,0,0,0,36,24.133h0A2.826,2.826,0,0,0,33.168,21.3H6.833A2.826,2.826,0,0,0,4,24.133H4A2.876,2.876,0,0,0,6.833,26.965Z"
        transform="translate(0 -3.54)"
      />
      <path
        className="c-menu"
        d="M15.432,41.965H35.259a2.826,2.826,0,0,0,2.833-2.833h0A2.826,2.826,0,0,0,35.259,36.3H15.432A2.826,2.826,0,0,0,12.6,39.133h0A2.778,2.778,0,0,0,15.432,41.965Z"
        transform="translate(-2.015 -7.057)"
      />
    </g>
  </svg>
);

export default ToggleMenuComponent;
