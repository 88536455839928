import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthData, setCurrentAuthUser, setAuthToken } from "../../redux/action/AuthAction";
import { SESSION_STORAGE_KEYS,PATHS } from '../../constants/constants';
import InputButton from "../../Components/InputButton";
import CustomInput from "../../Components/CustomInput";
import LockPad from "../../Components/icons/LockClosedOutlineComponent";
import './Auth.css'
import { Navigate } from "react-router-dom";
import {toastr} from 'react-redux-toastr';
import { _extractPayloadFromToken } from "../../helpers/jwt";
import { _authenticate, _resetPassword } from "../../services/Auth";
import Logo from "../../assets/images/logo.png";

class Auth extends Component {
    constructor(props){
        super(props);
        this.state = {
            userTypeId: null,
            userTypeValue: '',
            email: "",
            password: "",
            forgotPassword: false,
            loading: false,
            redirect:"",
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = async (event) => {
        sessionStorage.clear();
        const { email, password } = this.state;
        const credentials = {email, password};
        try{
            this.setState({ loading: true });
            const res = await _authenticate(credentials);
            if(res.status !== 200){
                toastr.error('Erreur', "Email ou mot de passe incorrect!.", {timeOut: 5000}); 
                this.setState({loading: false});
                return;
            }else{
                sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, JSON.stringify(res.data.token));
                const authData = _extractPayloadFromToken(res.data.token);
                this.props.setAuthData(authData);
                this.props.setCurrentAuthUser(authData.user);
                this.props.setAuthToken(res.data.token);
                // todo: add Shpo data to store
                this.setState({loading: false});
                this.setState({redirect: PATHS.FETCH_DATA});
            }
        }catch(e){
            console.log("Error: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            this.setState({loading: false});
        }
    }

    handleSubmitForgot = async () => {
        this.setState({ loading: true });
        try{
            const res = await _resetPassword(this.state.email);
            if(res.status === 200){
                this.setState({ loading: false, email: "", forgotPassword: false });
                toastr.success('Bravo!', "Demande bien prise en compte. Un email a ete envoye a voutre adresse email!", {timeOut: 5000}); 
            }else{
                this.setState({ loading: false });
            }
        }catch(e){
            this.setState({ loading: false });
        }
        
    }

    gotoMain = () => {
        this.setState({redirect: "/"});
    }

    goto = (path) => {
        this.setState({redirect: path});
    }

    gotoForgotPwd = () => {
        this.setState({
            forgotPassword: true,
            loading: false,
        })
    }

    gotoCnx = () => {
        this.setState({
            forgotPassword: false,
            loading: false,
        })
    }
        
    renderRecoveryPassword = () => {
        const { loading } = this.state;
            return (
                <div className="part fade-in-right-light">
                    <h4 className="text-center hightlited" >FORGOT PASSWORD</h4>
                    <CustomInput 
                        name="email" id="email" 
                        type="email"
                        placeholder="Your  email..." 
                        value={this.state.email} 
                        globalClassName="mt-3"
                        onChange={this.handleChange}
                        withIcon={<span style={{color: "#ff0077"}} >@</span>}
                        hasError={false}
                    />
                    <div className="options d-flex align-items-center justify-content-between w-full height-50 mt-2">
                        <label onClick={this.gotoCnx} className="hightlited cursor-pointer">Sign in</label>
                    </div>
                    <InputButton 
                        id="submitForm" 
                        name="submitForm" 
                        variant="primary"
                        value="Submit"  
                        onClick={this.handleSubmitForgot}
                        className="btn-custom-primary full-width no-margin"
                        loading={loading}
                    />
                </div>
            )
    }

    renderAuth = () => {
        const { loading } = this.state;
        return (
            <>
            <div className="part fade-in-right-light">
                <h4 className="text-center hightlited" >SIGN IN</h4>
                <CustomInput 
                    name="email" id="email" 
                    type="email"
                    placeholder="Email or Username" 
                    value={this.state.email} 
                    globalClassName="mt-3"
                    onChange={this.handleChange}
                    withIcon={<span style={{color: "#ff0077"}} >@</span>}
                    hasError={false}
                />
                <CustomInput 
                    name="password" id="password"
                    type="password"
                    placeholder="Password or Phone number" 
                    value={this.state.password} 
                    globalClassName="mt-3 mb-3"
                    onChange={this.handleChange}
                    withIcon={<LockPad color="#ff0077" />}
                    hasError={false}
                />
                {/* <div className="options d-flex align-items-center justify-content-between w-full height-50">
                    <label htmlFor="rememberme" className="d-flex align-items-center cursor-pointer ">
                        <input type="checkbox" className="checkBox" value={true} name="rememberme" id="rememberme" />
                        <span className="ml-2"> Remember me</span>
                    </label>
                    <label onClick={this.gotoForgotPwd} className="highlighted cursor-pointer">Forgot password?</label>
                </div> */}
                <InputButton 
                    id="submitForm" 
                    name="submitForm" 
                    variant="primary"
                    value="Sign in"  
                    onClick={this.handleSubmit}
                    className="full-width no-margin"
                    loading={loading}
                />
                <div className='d-flex align-items-center'> <div className="separator flex-1">&nbsp;</div> <div className='ml-1 mr-1 flex-1'>OR</div>  <div className="separator flex-1">&nbsp;</div> </div>
                <InputButton 
                    id="CustomerBtn" 
                    name="CustomerBtn" 
                    variant="secondary"
                    value="CREATE CUSTOMER ACCOUNT"  
                    onClick={(e) => {this.goto("/customer")}}
                    className="full-width no-margin"
                />
            </div>
            
            </>
        )
    }

    render() {
        if(this.state.redirect) return <Navigate to={this.state.redirect} />
        return (
            <div className="auth-page">
                <div className="left-side">
                    <span onClick={this.gotoMain} className="cursor-pointer">
                        <img src={Logo} alt="logo" height={150} width={"auto"} className="mb-5 fade-in-left-light" />
                    </span>
                </div>
                <div className="content-auth-account">
                    {/* <span onClick={this.gotoMain} className="cursor-pointer logo"><img src={Logo} alt="logo" height={30} width={"auto"} className="mb-5 fade-in-top-light" /></span> */}
                    {
                        this.state.forgotPassword ? this.renderRecoveryPassword() : this.renderAuth()
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {
        auth,
    }
}

export default connect(mapStateToProps, {setAuthData, setCurrentAuthUser, setAuthToken })(Auth);
