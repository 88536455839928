import { SET_SHOP, SET_SMS_COUNT } from '../action-type/shopType';

export const setShop = (data) => ({
    type: SET_SHOP,
    payload: data,
});

export const setSmsCount = (data) => ({
    type: SET_SMS_COUNT,
    payload: data,
});