import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import { connect } from 'react-redux';
import { setActivePage, setActivePageByPathRoute } from "../../redux/action/NavAction";
import { toggleNavlink } from "../../redux/action/NavAction";
import NavItem from "./navItem";
import Logo from "../../assets/images/logo.png";
import { IoIosClose } from "react-icons/io";
// import { ROUTES_APP } from "../../routes/routes-list";
import { _checkAuth, _checkAccessOrRedirect } from "../../services/Auth";

import "./navlink.css";



function Navlink(props) {

    const processLinkItemAction = (i) => {
        props.setActivePage(i);
        props.toggleNavlink();
    }
    const renderLinks = (items) => {
        return items.map((link, i) => {
            if(_checkAuth() && _checkAccessOrRedirect(link?.right)){
                return <li key={`${i}-${link.id}`} onClick={() => { processLinkItemAction(i) }} className={link.className}> <NavItem key={`${i}-${link.id}`} { ...link } /> </li>
            }
            return <></>
        });
    }
    
    let {pathname} = useLocation();
    let {setActivePageByPathRoute} = props;
    // eslint-disable-next-line 
    useEffect(() => {
        let part = pathname.split("/", 3);
        setActivePageByPathRoute(part[2]);
    }, [pathname, setActivePageByPathRoute]);

    return (
        <aside className={`aside-nav ${props.isVisible ? 'show' : ''}`}>
            <button type="button" className="btn toggle-navlink" onClick={(e) => props.toggleNavlink()}>
                <IoIosClose className="icon-close" />
            </button>
            <div className="logo">
                <img src={Logo} alt="Logo" width="90%" height="auto" id="logo" />
            </div>
            <nav className="nav">
                <ul>
                    { renderLinks(props.items) }
                </ul>
            </nav>
            <div className="footer-aside-left">
                <span className="version">By <b>Karlax v1.0.0</b></span>
            </div>
        </aside>
    )
}

const mapStateToProps = (state) => {
    return { items : state.nav.items, isVisible: state.nav.isVisible }
}

export default connect(mapStateToProps, { setActivePage, setActivePageByPathRoute, toggleNavlink })(Navlink)
