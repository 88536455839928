export const SET_COUNT_CUSTOMERS = 'SET_COUNT_CUSTOMERS';
export const SET_COUNT_SELLERS = 'SET_COUNT_SELLERS';
export const SET_COUNT_PRODUCTS = 'SET_COUNT_PRODUCTS';
export const SET_COUNT_AVAILABLE_PRODUCTS = 'SET_COUNT_AVAILABLE_PRODUCTS';
export const SET_COUNT_SERVICES = 'SET_COUNT_SERVICES';
export const SET_MOST_SOLD_PRODUCTS = 'SET_MOST_SOLD_PRODUCTS';
export const SET_MOST_SOLD_SERVICES = 'SET_MOST_SOLD_SERVICES';
export const SET_BEST_CUSTOMERS = 'SET_BEST_CUSTOMERS';
export const SET_BEST_SELLERS = 'SET_BEST_SELLERS';
export const SET_DAILY_INCOMES = 'SET_DAILY_INCOMES';
export const SET_MONTHLY_INCOMES = 'SET_MONTHLY_INCOMES';
export const SET_ANNUAL_INCOMES = 'SET_ANNUAL_INCOMES';
export const SET_DAILY_INCOMES_MONTH = 'SET_DAILY_INCOMES_MONTH';
export const SET_MONTHLY_INCOMES_YEAR = 'SET_MONTHLY_INCOMES_YEAR';
