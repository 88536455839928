import React from 'react';
import PropTypes from 'prop-types';

import "./PaginationSize.css"

// #region component
const propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    value: 20,
    className: "",
};

/**
 * 
 */
class PaginationSize extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            psize: this.props.value,
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setSize = (e) => {
        if(this.props.onChange){
            this.props.onChange(e.target.value);
        }
    }

    render() {
        return (
            <div className={`pagination-size ${this.props.className}`}>
                <input type="number" name="psize" className="i-size" value={this.state.psize || ""} onChange={this.handleChange} onBlur={this.setSize} placeholder="Go to page" />
                <select name="psize" value={this.props.value} className="s-size" defaultValue={this.props.value || 20} onChange={this.setSize}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
        );
    }
}

PaginationSize.propTypes = propTypes;
PaginationSize.defaultProps = defaultProps;
// #endregion

export default PaginationSize;