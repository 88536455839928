import {
    SET_SERVICES, ADD_SERVICE, UPDATE_SERVICE, SELECT_SERVICE, DELETE_SERVICE
  } from '../action-type/serviceType';
  
  export const setServices = (data) => ({
    type: SET_SERVICES,
    payload: data,
  });
  
  export const addService = (data) => ({
    type: ADD_SERVICE,
    payload: data,
  });
  
  export const updateService = (data) => ({
    type: UPDATE_SERVICE,
    payload: data,
  });
  
  export const selectService = (data) => ({
    type: SELECT_SERVICE,
    payload: data,
  });
  
  export const deleteService = (data) => ({
    type: DELETE_SERVICE,
    payload: data
  });
  