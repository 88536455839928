import {
    SET_CAMPAIGNS, ADD_CAMPAIGN, UPDATE_CAMPAIGN, SELECT_CAMPAIGN, DELETE_CAMPAIGN
} from '../action-type/smsType';

export const setCampaigns = (data) => ({
    type: SET_CAMPAIGNS,
    payload: data,
});
  
export const addCampaign = (data) => ({
    type: ADD_CAMPAIGN,
    payload: data,
});

export const updateCampaign = (data) => ({
    type: UPDATE_CAMPAIGN,
    payload: data,
});

export const selectCampaign = (data) => ({
    type: SELECT_CAMPAIGN,
    payload: data,
});

export const deleteCampaign = (data) => ({
    type: DELETE_CAMPAIGN,
    payload: data
});