import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {_updateUserProfile, _updatePassword} from "../../services/Auth";
import {toastr} from 'react-redux-toastr';
import md5 from "md5";
import {Modal, Container, Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import {setCurrentAuthUser} from "../../redux/action/AuthAction";
import { FcFilingCabinet } from "react-icons/fc";
import {IoPencil} from 'react-icons/io5';
import { _logout } from "../../services/Auth";
import { toggleNavlink } from "../../redux/action/NavAction";
import { Dropdown } from 'react-bootstrap';
import ToggleMenuIcon from "../../Components/icons/ToggleMenuComponent";
import arrowDown from "../../assets/svg/arrow-down.svg";
import userIonicon from "../../assets/svg/profile-icon.svg";
import Label from "../../Components/label";
import Loader from "../../Components/Loader";
import { 
  FcPortraitMode,
  FcAdvance,
  FcDownload,
  
} from "react-icons/fc";

import "./header.css";

function Header(props) {
    const location = useLocation();
    const navigate = useNavigate();

    let [showModalProfile, setShowModalProfile] = useState(false);
    let [loading, setLoading] = useState(false);
    let [loadingPassword, setLoadingPassword] = useState(false);
    let [isUpdate, setIsUpdate] = useState(false);
    let [stateProfile, setStateProfile] = useState();
    let [statePassword, setStatePassword] = useState({});
    const [oldPassword, setOldPassword] = useState(null);
    let [deferredPrompt, setDeferredPrompt] = useState(null);
    
    async function logout(){
      navigate("/", { from : location });
      try{
        const res = await _logout();
        if(res.status !== 200) {
          toastr.error('Erreur', res.statusText, {timeOut: 5000});
        }else{
          sessionStorage.removeItem("token");
        }
      }catch(e){
        console.error("Error: ", e.message);
      }
    }

    const toggleNavLink = (e) => {
      e.stopPropagation();
      props.toggleNavlink();
    }

    const handlePasswordChange = (e) => {
      setStatePassword({ ...statePassword, [e.target.name] : e.target.value });
    }
    const handleProfileChange = (e) => {
      setStateProfile({ ...stateProfile, [e.target.name] : e.target.value });
    }

    const updateUser = async (e) => {
      e.preventDefault();
      // _updateUserProfile
      try{
          setLoading(true);
          const res = await _updateUserProfile(stateProfile, stateProfile.id);
          if(res.status === 200){
              setLoading(false);
              props.setCurrentAuthUser(res.data);
          }else{
              setLoading(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
      }catch(e){
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoading(false);
      }
    }

    const updatePwd = (e) => {
      e.preventDefault();
      const toastrConfirmOptions = {
        onOk: () => updatePassword(),
        onCancel: () => {}
    };
    toastr.confirm("Do you really want to change password?", toastrConfirmOptions);
    }

    const updatePassword = async () => {
      if( oldPassword === md5(statePassword.oldpassword) ){
        if(statePassword.password === statePassword.confirmpassword){
          try{
            setLoadingPassword(true);
            const payload = { 
              oldpassword: props.currentUser?.password, 
              password: statePassword.password 
            };
            const res = await _updatePassword(payload, props.currentUser?.id);
            if(res.status === 200){
                setLoadingPassword(false);
                // sessionStorage.clear();
                sessionStorage.removeItem("token");
                navigate("/", { from : location });
            }else{
                setLoadingPassword(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          }catch(e){
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingPassword(false);
          }
        }else{
          toastr.error('Error', "Password does not match with the conformation!", {timeOut: 5000}); 
          return;
        }
      }else{
        toastr.error('Error', "Old Password does not match!", {timeOut: 5000}); 
        return;
      }
    }

    const renderContentForm = () => {
      if(isUpdate) {
        return (
          <>
          <Form onSubmit={updateUser}>
            <FloatingLabel controlId="floatingFirstname" label="Firstname" className='mt-2 mb-3'>
              <Form.Control 
                type="text" 
                name="firstname" 
                value={stateProfile?.firstname || ""} 
                onChange={handleProfileChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingLastname" label="Lastname" className='mb-3'>
              <Form.Control 
                type="text" 
                name="lastname" 
                value={stateProfile?.lastname || ""} 
                onChange={handleProfileChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingPhone" label="Phone" className='mb-3'>
              <Form.Control 
                type="text" 
                name="phone" 
                value={stateProfile?.phone || ""} 
                onChange={handleProfileChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingEmail" label="Email" className='mb-3'>
              <Form.Control 
                type="email" 
                name="email" 
                value={stateProfile?.email || ""} 
                onChange={handleProfileChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingUsername" label="Username" className='mb-3'>
              <Form.Control 
                type="text" 
                name="username" 
                value={stateProfile?.username || ""} 
                onChange={handleProfileChange}
              />
            </FloatingLabel>
            <Form.Group className="mb-3" controlId="exampleForm.submitPassword">
              { loading ?
                <Loader loading={loading} /> :
                <><Button type="submit" variant="primary" className="mr-5"> Update profile </Button>
              <Button type="button" variant="default" style={{border: "thin solid #1179AD"}} onClick={() => setIsUpdate(false)}> Cancel </Button></>}
            </Form.Group>
          </Form>
          </>
        )
      }else{
        return (
          <>
          <div className="item-group">
              <div className="item-left">Firstname</div>
              <div className="item-right">{props.currentUser?.firstname}</div>
          </div>
          <div className="item-group">
              <div className="item-left">Lastname</div>
              <div className="item-right">{props.currentUser?.lastname}</div>
          </div>
          <div className="item-group">
              <div className="item-left">Phone</div>
              <div className="item-right">{props.currentUser?.phone}</div>
          </div>
          <div className="item-group">
              <div className="item-left">Email</div>
              <div className="item-right">{props.currentUser?.email}</div>
          </div>
          <div className="item-group">
              <div className="item-left">Username</div>
              <div className="item-right">{props.currentUser?.username}</div>
          </div>
          <h3 className="title-3 mt-4">Roles</h3>
          {
            props.currentUser?.userGroups.map((item, idx) => {
              return (
                <Label icon={FcFilingCabinet} key={idx} statement={item?.group?.description} className="role-tag mr-2" />
              )
            })
          }
          </>
        )
      }
    }

    const checkAvailablityOfflineApp = () => {
      window.addEventListener('beforeinstallprompt', (e) => { setDeferredPrompt(e); });
    }

    const triggerDeferredPrompt = async () => {
      if (deferredPrompt !== null) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userCh; 
        if (outcome === 'accepted') {
          deferredPrompt = null;
        }
      }
    }

    useEffect(() => {
      setStateProfile(props.currentUser);
      setOldPassword(props.currentUser?.password);
      checkAvailablityOfflineApp();
      // eslint-disable-next-line
    }, []);


    return (
        <header className="header">
            {/* left */}
            <div className="left-side">
                <button type="button" className="toggle-menu mr-3" onClick={toggleNavLink}>
                    <ToggleMenuIcon />
                </button>
                <h3 className="page-title"> {props.ActivePage ? props.ActivePage.name : "Main"} </h3>
            </div>

            {/* right */}
            <div className="right-side user-actions">
                <Dropdown>
                    <Dropdown.Toggle 
                      as={ React.forwardRef(({ onClick }, ref) => (
                        <div 
                          className="user-dropdown-toggler"
                          ref={ref}
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <img src={userIonicon} alt="user icon" width={25} height={25}/>
                          <span className="user-info md-hide">{`${props.currentUser?.firstname} ${props.currentUser?.lastname}`}</span>
                          <img src={arrowDown} alt="arrow down" className='md-hide'/>
                        </div>
                      ))} 
                      id="dropdown-custom-components"
                    >
                      Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu bsPrefix="dropdown-menu mt-3">
                      <div className='dropdown-item md-show'>{`${props.currentUser?.firstname} ${props.currentUser?.lastname}`}</div>
                      <Dropdown.Item eventKey="1" onClick={() => setShowModalProfile(true)}> <Label icon={FcPortraitMode} statement="Profile" /> </Dropdown.Item>
                      {/*  _checkAccessOrRedirect(FUNCTIONALITIES_CODE.SETTING_PARAMS) ?
                        <Dropdown.Item eventKey="2"> <Label icon={FcSupport} statement="Params" /> </Dropdown.Item> :
                      <></>  */}
                      <Dropdown.Item eventKey="2" onClick={() => triggerDeferredPrompt()}> <Label icon={FcDownload} statement="Install" /> </Dropdown.Item>
                      <Dropdown.Item eventKey="3" onClick={() => logout()}> <Label icon={FcAdvance} statement="Logout" /> </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
            </div>
            {/* Modal for profile */}

            <Modal show={showModalProfile} fullscreen onHide={() => setShowModalProfile(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Profile : {`${props.currentUser?.firstname} ${props.currentUser?.lastname}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={8} sm={12}>
                      <Row>
                        <Col> 
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3 className="title-3">Profile infos</h3> 
                            { !isUpdate ? <button className="btn btn-action" style={{border: "thin solid #1179AD"}} onClick={(e) => setIsUpdate(true)}>
                              <IoPencil color="#1179AD"/>
                            </button> : <button type='button' className='btn btn-close' onClick={() => setIsUpdate(false)} /> }
                          </div>
                        </Col>
                      </Row>
                      <div className="separator"></div>
                      <Row>
                        <Col>
                          {renderContentForm()}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} sm={12}>
                      <Row>
                        <Col> <h3 className="title-3 mb-4">Change password</h3> </Col>
                      </Row>
                      <div className="separator"></div>
                      <Row>
                        <Col>
                        <Form onSubmit={updatePwd}>
                            
                            <FloatingLabel controlId="floatingOldPassword" label="Old password" className='mt-2 mb-3'>
                              <Form.Control 
                                type="password" 
                                placeholder="*******************" 
                                name="oldpassword" 
                                value={statePassword?.oldpassword || ""} 
                                onChange={handlePasswordChange}
                              />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingNewPassword" label="New password" className='mb-3'>
                              <Form.Control 
                                type="password" 
                                placeholder="*******************" 
                                name="password" 
                                value={statePassword?.password || ""} 
                                onChange={handlePasswordChange}
                                />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingConfirmPassword" label="Confirm password" className='mb-3'>
                              <Form.Control 
                                type="password" 
                                placeholder="*******************" 
                                name="confirmpassword" 
                                value={statePassword?.confirmpassword || ""} 
                                onChange={handlePasswordChange}
                              />
                            </FloatingLabel>
                            <Form.Group className="mb-3" controlId="exampleForm.submitPassword">
                              {
                                loadingPassword ?
                                <Loader loading={loading} /> :
                                <Button type="submit" variant="secondary"> Change password </Button>
                              }
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                </Container>
              </Modal.Body>
            </Modal>

        </header>
    )
}

const mapStateToProps = (state) => {
    const {auth, nav} = state;
    return { 
      currentUser : auth.currentAuth,
      ActivePage : nav.activeItem,
    }
}

export default connect(mapStateToProps, { toggleNavlink, setCurrentAuthUser })(Header)
