import React from "react";
import Label from "../label";
import { IoIosAdd } from "react-icons/io";

import "./btnadd.css";

function BtnAdd({statement, onClick, className, style, disabled}){

    return (
        <button className={`btn btn-add ${className}`} onClick={(e) => onClick(e)} disabled={disabled} style={style}>
            <Label icon={IoIosAdd} statement={statement} />
        </button>
    )
}

export default BtnAdd;