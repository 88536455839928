import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _createOrderOperation = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_CUSTOMER_ORDER } };
    return await axios.post(`order-operations`, data, config);
}

export const _cancelOrderOperation = async (payload, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_CUSTOMER_ORDER } };
    return await axios.put(`order-operations/${id}`, payload, config);
}

export const _getOrderOperations = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER } };
    return await axios.get(`order-operations?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getOrderOperationById = async (id, filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_CUSTOMER_ORDER } };
    return await axios.get(`order-operations/${+id}?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}