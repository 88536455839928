import { 
    SET_ORDER_OPERATIONS, SELECT_ORDER_OPERATION, ADD_ORDER_OPERATION, ADD_ORDER_OPERATIONS, DELETE_ORDER_OPERATION,
    SET_ORDER_OPERATION_LINES, ADD_ORDER_OPERATION_LINE, UPDATE_ORDER_OPERATION, UPDATE_ORDER_OPERATION_LINE, DELETE_ORDER_OPERATION_LINES, SELECT_ORDER_OPERATION_LINE,
} from '../action-type/orderType';

const INITIAL_STATE = {
    orderOperations : [],
    orderOperationLines : [],
    selectedOrderOperation : {},
    selectedOrderOperationLine : {},
};

const OrderReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_ORDER_OPERATIONS : {
            return { ...state, orderOperations: action.payload };
        }
        case SELECT_ORDER_OPERATION : {
            return { ...state, selectedOrderOperation: action.payload };
        }
        case ADD_ORDER_OPERATION : {
            if (action.payload) {
                return {
                    ...state,
                    orderOperations: [ ...state.orderOperations, action.payload],
                }
            }
            return state;
        }
        case ADD_ORDER_OPERATIONS : {
            if (action.payload) {
                const {orderOperations} = state;
                orderOperations.concat(action.payload);
                return {
                    ...state,
                    orderOperations,
                }
            }
            return state;
        }

        case UPDATE_ORDER_OPERATION: {
            if (action.payload) {
                const orderOperations = state.orderOperations;
                const idx = orderOperations.findIndex(elm => +elm.id === +action.payload?.id);
                orderOperations[idx] = action.payload;
                return { ...state, orderOperations }
            }
            return state;
        }

        case DELETE_ORDER_OPERATION: {
            if (action.payload) {
                const orderOperations = state.orderOperations;
                const idx = orderOperations.findIndex(elm => +elm.id === +action.payload);
                orderOperations.splice(idx, 1);
                return {...state, orderOperations};
            }
            return state;
        }
        case SET_ORDER_OPERATION_LINES : {
            return { ...state, orderOperationLines: action.payload };
        }
        case SELECT_ORDER_OPERATION_LINE : {
            return { ...state, orderOperationLines: action.payload };
        }
        case ADD_ORDER_OPERATION_LINE : {
            if (action.payload) {
                return {
                    ...state,
                    orderOperationLines: [ ...state.orderOperationLines, action.payload],
                }
            }
            return state;
        }
        case UPDATE_ORDER_OPERATION_LINE: {
            if (action.payload) {
                state.orderOperationLines[+action.payload.index] = action.payload.data;
                return state
            }
            return state;
        }
        case DELETE_ORDER_OPERATION_LINES: {
            if (action.payload) {
                state.orderOperationLines = action.payload.map((element) => {
                    return state.orderOperationLines.splice(element, 1);
                });
                return state;
            }
            return state;
        }
        default: return state;
    }
}

export default OrderReducer;
