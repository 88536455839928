import { ADD_CATEGORY, ADD_CATEGORIES, SET_CATEGORIES, UPDATE_CATEGORY, DELETE_CATEGORY, SELECT_CATEGORY} from '../action-type/categoryProductType';

export const addCategory = (data) => ({
    type: ADD_CATEGORY,
    payload: data
})

export const addCategories = (data) => ({
    type: ADD_CATEGORIES,
    payload: data
})

export const setCategories = (data) => ({
    type: SET_CATEGORIES,
    payload: data
})

export const updateCategory = (data) => ({
    type: UPDATE_CATEGORY,
    payload: data
})

export const deleteCategory = (id) => ({
    type: DELETE_CATEGORY,
    payload: id
})

export const selectCategory = (data) => ({
    type: SELECT_CATEGORY,
    payload: data
})