import {
    SET_CAMPAIGNS, ADD_CAMPAIGN, UPDATE_CAMPAIGN, SELECT_CAMPAIGN, DELETE_CAMPAIGN
} from '../action-type/smsType';

const INITIAL_STATE = {
    campaigns: [],
    selectedCampaign: {}
};

const SmsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_CAMPAIGNS: {
        if (action.payload) {
          return {
            ...state,
            campaigns: action.payload
          }
        }
        return state;
      }
      case ADD_CAMPAIGN: {
        if (action.payload) {
          return {
            ...state,
            campaigns: [action.payload, ...state.campaigns]
          }
        }
        return state;
      }
      case UPDATE_CAMPAIGN: {
        if (action.payload) {
          const i = state.campaigns.findIndex(elm => elm.id === action.payload.id);
          state.campaigns[i] = action.payload;
          return state;
        }
        return state;
      }
      case SELECT_CAMPAIGN: {
        return {
          ...state,
          selectedCampaign: action.payload
        }
      }
      case DELETE_CAMPAIGN: {
        if (action.payload) {
          let campaigns = state.campaigns;
          const i = campaigns.findIndex(elm => elm.id === action.payload);
          campaigns.splice(i, 1);
          return { ...state, campaigns };
        }
        return {...state};
      }
  
      default:
        return {...state}
    }
}
  
export default SmsReducer;