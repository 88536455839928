import React, {Suspense, useEffect, useState} from 'react';
import BtnAdd from "../../../Components/BtnAdd";
import {_checkAccessOrRedirect} from "../../../services/Auth";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";
import Loader from "../../../Components/Loader";
import Input from "../../../Components/CustomInput/CustomInput";
import { useNavigate } from "react-router-dom";
import './customers.css';
import {
  _createCustomer,
  _deleteCustomer,
  _updateCustomer,
  getCustomers
} from "../../../services/Customers";
import {toastr} from "react-redux-toastr";
import {IoPencil, IoTrash} from 'react-icons/io5';
import {FcPackage, FcServices} from "react-icons/fc";
import Table from "../../../Components/Table/GenericTable";
import {connect} from "react-redux";
import {
  addCustomer,
  deleteCustomer,
  selectCustomer,
  setCustomers,
  updateCustomer
} from "../../../redux/action/CustomersAction";
import ModalRight from "../../../Components/ModalRight";
import Button from "../../../Components/InputButton/InputButton";
import {Pagination} from "react-bootstrap";
import PaginationSize from "../../../Components/paginationSize";
import { checkCameroonPhoneNumber } from "../../../helpers/Number";

const CustomersPage = (props) => {
  let navigate = useNavigate();
  let [isRightVisible, setIsRightVisible] = useState(false);
  let [filter, setFilter] = useState();
  let [loading, setLoading] = useState(false);
  let [loadingData, setLoadingData] = useState(false);
  let [loadingForm, setLoadingForm] = useState(false);
  let [stateCustomer, setStateCustomer] = useState({});
  let [stateData, setStateData] = useState({});
  let [displayForm, setDisplayForm] = useState(null);
  let [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    (async (filter = "", pageSize = 20, page = 1) => {
      // Check permission on action
      if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER)) {
        setLoadingData(true);
        try {
          const res = await getCustomers(filter, pageSize, page);
          setStateData({
            currentPage: res.data.pager.currentPage,
            pageSize: res.data.pager.pageSize,
            endIndex: res.data.pager.endIndex,
            endPage: res.data.pager.endPage,
            pages: res.data.pager.pages,
            startIndex: res.data.pager.startIndex,
            startPage: res.data.pager.startPage,
            totalItems: res.data.pager.totalItems,
            totalPages: res.data.pager.totalPages,
          });
          if (res.status === 200) {
            setLoadingData(false);
            props.setCustomers(res.data.rows);
          } else {
            setLoadingData(false);
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          console.log({stateData});
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingData(false);
        }
      }
    })()
    // eslint-disable-next-line
  }, []);

  const getFilterCustomers = async (filter = "", pageSize = 20, page = 1) => {
    // Check permission on action
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER)) {
      setLoadingData(true);
      try {
        const res = await getCustomers(filter, pageSize, page);
        setStateData({
          currentPage: res.data.pager.currentPage,
          pageSize: res.data.pager.pageSize,
          endIndex: res.data.pager.endIndex,
          endPage: res.data.pager.endPage,
          pages: res.data.pager.pages,
          startIndex: res.data.pager.startIndex,
          startPage: res.data.pager.startPage,
          totalItems: res.data.pager.totalItems,
          totalPages: res.data.pager.totalPages,
        });
        if (res.status === 200) {
          setLoadingData(false);
          props.setCustomers(res.data.rows);
        } else {
          setLoadingData(false);
          toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
        }
        console.log({stateData});
      } catch (e) {
        console.error("Erreur: ", e);
        toastr.error('Erreur', e.message, {timeOut: 5000});
        setLoadingData(false);
      }
    }
  };

  // Handle change for create customer form
  const handleCCChange = (e) => {
    stateCustomer = {...stateCustomer, [e.target.name]: e.target.value}
    setStateCustomer(stateCustomer);
  }

  const createCustomer = async (e) => {
    e.stopPropagation();
        if(!checkCameroonPhoneNumber(stateCustomer.phone1)){ 
          toastr.error('Error', "phone number has to be in this format: 2376xxxxxxxx", {timeOut: 3000});
          return;
         }
    setLoadingForm(true);
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_CUSTOMER)) {
      if (!loading) {
        try {
          const res = await _createCustomer({...stateCustomer, shopId: props.shop.id});
          if (res.status === 200) {
            props.addCustomer(res.data);
            setStateCustomer({});
          } else {
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          setLoadingForm(false);
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingForm(false);
        }
      }
    } else {
      toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
    }
  }

  const editCustomer = async (e) => {
    e.stopPropagation();
    setLoadingForm(true);
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_CUSTOMER)) {
      if (!loading) {
        try {
          const res = await _updateCustomer(stateCustomer);
          if (res.status === 200) {
            props.updateCustomer(res.data);
            // stateCustomer = {}
            // setStateCustomer(stateCustomer);
            // setIsEdit(false);
            console.log(res.data)
          } else {
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          setLoadingForm(false);
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingForm(false);
        }
      }
    } else {
      toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
    }
  }

  const renderFormCreate = () => {
    return (
      <form name="customerCreateForm" className="m-2" onSubmit={createCustomer}>
        <div className="form-group">
          <label htmlFor="name" className="label-input">Name:</label>
          <input className="form-control" type="text" name="name" id="name" value={stateCustomer.name || ''}
                 onChange={handleCCChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="address" className="label-input">Address:</label>
          <input className="form-control" type="text" name="address" id="address" value={stateCustomer.address || ''}
                 onChange={handleCCChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="phone1" className="label-input">Phone 1: <span style={{fontSize: "0.7rem", color: "red"}}>2376XXXXXXXX</span> </label>
          <input className="form-control" type="text" name="phone1" id="phone1" value={stateCustomer.phone1 || ''}
                 onChange={handleCCChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="phone2" className="label-input">Phone 2: <span style={{fontSize: "0.7rem", color: "red"}}>2376XXXXXXXX</span> </label>
          <input className="form-control" type="text" name="phone2" id="phone1" value={stateCustomer.phone2 || ''}
                 onChange={handleCCChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="postalCode" className="label-input">Postal Code:</label>
          <input className="form-control" type="text" name="postalCode" id="phone1"
                 value={stateCustomer.postalCode || ''}
                 onChange={handleCCChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="email" className="label-input">Email:</label>
          <input className="form-control" type="email" name="email" id="email" value={stateCustomer.email || ''}
                 onChange={handleCCChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="country" className="label-input">Country:</label>
          <input className="form-control" type="text" name="country" id="country" value={stateCustomer.country || ''}
                 onChange={handleCCChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="city" className="label-input">City:</label>
          <input className="form-control" type="text" name="city" id="city" value={stateCustomer.city || ''}
                 onChange={handleCCChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="niu" className="label-input">NUI:</label>
          <input className="form-control" type="text" name="niu" id="niu" value={stateCustomer.niu || ''}
                 onChange={handleCCChange}/>
        </div>

       {/*  <div className="form-group">
          <label htmlFor="tradeRegister" className="label-input">Trade Register:</label>
          <input className="form-control" type="text" name="tradeRegister" id="tradeRegister"
                 value={stateCustomer.tradeRegister || ''}
                 onChange={handleCCChange}/>
        </div> */}

        <div className="separator mb-2 mt-2"></div>
        <div className="form-group">
          <Button
            variant="primary"
            value={isEdit ? "Update" : "Submit"}
            className="m-0 form-control"
            loading={loadingForm}
            onClick={isEdit ? editCustomer : createCustomer}
          />
        </div>
      </form>
    )
  }

  const renderDetailCustomer = () => {
    return (
      <div className="p-2">
        <div className="item-group">
          <div className="item-left">Name</div>
          <div className="item-right">{props.selectedCustomer.name}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Address</div>
          <div className="item-right">{props.selectedCustomer.address}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Phone 1</div>
          <div
            className="item-right">{props.selectedCustomer.phone1}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Phone 2</div>
          <div
            className="item-right">{props.selectedCustomer.phone2}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Postal Code</div>
          <div className="item-right">{props.selectedCustomer.postalCode}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Email</div>
          <div className="item-right">{props.selectedCustomer.email}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Country</div>
          <div className="item-right">{props.selectedCustomer.country}</div>
        </div>
        <div className="item-group">
          <div className="item-left">NIU</div>
          <div className="item-right">{props.selectedCustomer.niu}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Trade Register</div>
          <div className="item-right">{props.selectedCustomer.tradeRegister}</div>
        </div>
      </div>
    )
  }

  const renderRightModal = () => {
    switch (displayForm) {
      case "new" : {
        return (
          <ModalRight
            title={isEdit ? "Edit customer" : "New customer account"}
            content={renderFormCreate()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }
      case "preview" : {
        return (
          <ModalRight
            title="Customer detail"
            content={renderDetailCustomer()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }

      default :
        return <></>
    }
  }

  const displayCreateCustomerForm = (e) => {
    e.preventDefault();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_CUSTOMER)) {
      setIsRightVisible(true);
      setStateCustomer({});
      setIsEdit(false);
      setDisplayForm("new");
    }
  }

  const deleteCustomer = async (e, id) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_CUSTOMER)) {
      const toastrConfirmOptions = {
        onOk: () => delCustomer(id),
        onCancel: () => {
        }
      };
      toastr.confirm("Do you really want to delete this customer ?", toastrConfirmOptions);
    }
  }

  const delCustomer = async (id) => {
    setLoading(true);
    try {
      const res = await _deleteCustomer(id);
      if (res.status === 200) {
        setLoading(false);
        props.deleteCustomer(id);
      } else {
        setLoading(false);
        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
      }
    } catch (e) {
      console.error("Erreur: ", e);
      toastr.error('Erreur', e.message, {timeOut: 5000});
      setLoading(false);
    }
  }

  const displayEditCustomerForm = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_CUSTOMER)) {
      // const {id, ...customer} = item
      setIsEdit(true);
      setStateCustomer(item);
      setIsRightVisible(true);
      setDisplayForm("new");
    }
  }

  const gotoHistory = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_CUSTOMER)) {
      navigate(`${item.id}/history`);
    }
  }

  const gotoServicesHistory = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_CUSTOMER)) {
      navigate(`${item.id}/services-history`);
    }
  }

  const selectElement = (item) => {
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_CUSTOMER)) {
      props.selectCustomer(item?.originalData);
      setIsRightVisible(true);
      setDisplayForm("preview");
    }else{
      console.log("You don't have permissions!");
    }
  }

  const renderAction = (item) => {
    return (
      <div className="">
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_CUSTOMER) ?
        <button className="btn btn-action" onClick={(e) => gotoHistory(e, item)}  title='Products history'>
          <FcPackage/>
        </button> :
          <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_CUSTOMER) ?
        <button className="btn btn-action" onClick={(e) => gotoServicesHistory(e, item)}  title='Services history'>
          <FcServices/>
        </button> :
          <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_CUSTOMER) ?
          <button className="btn btn-action" onClick={(e) => displayEditCustomerForm(e, item)} title='Edit customer'>
            <IoPencil color="blue"/>
          </button> :
          <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_CUSTOMER) ?
          <button className="btn btn-action" onClick={(e) => deleteCustomer(e, item.id)}  title='Delete customer'><IoTrash/></button> :
          <></>}
      </div>
    )
  }

  const headerData = [
    {
      title: <div className="size-30 flex-center  rounded"> # </div>,
      align: "center",
      className: '',
      width: 50,
      name: "number"
    },
    {title: "Name", align: "left", className: 'pl-15', width: "auto", name: "name"},
    {title: "Trade Register", align: "left", className: 'pl-15 md-hide', width: "auto", name: "tradeRegidter"},
    {title: "Email", align: "left", className: 'pl-15 sm-hide', width: "auto", name: "email"},
    {title: "Phone", align: "left", className: 'pl-15', width: "auto", name: "phone1"},
    {title: <> Actions </>, align: "left", className: '', width: "150px", name: "action"},
  ];

  const formatBodyData = (data) => {
    return data?.map((item, i) => {
      let resData = {
        originalData: item,
        number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
        name: item.name,
        tradeRegidter: item.tradeRegister,
        email: item.email,
        phone1: item.phone1,
        action: renderAction(item),
      }
      return resData;
    })
  }

  const setPage = async (page) => {
    if (stateData.currentPage !== page) {
      await getFilterCustomers("", stateData.pageSize, page);
    }
  }

  const setSize = async (pageSize) => {
    await getFilterCustomers("", pageSize, 1);
  }

  const renderPaginarion = () => {
    if (stateData.totalPages > 1) {
      let items = [];
      for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
            className="pointer">
            {number}
          </Pagination.Item>,
        );
      }
      return (
        <div className="d-flex align-items-center justify-content-between mt-4">
          <Pagination>
            <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.startPage)}/>
            <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage - 1)}/>
            {items}
            <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage + 1)}/>
            <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.endPage)}/>
          </Pagination>
          <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
        </div>
      )
    } else {
      return <></>
    }
  }

  const handleFilterChange = async (e) => {
    setFilter(e.target.value);
    await getFilterCustomers(e.target.value);
  }


  return (
      <>
        <div className="customers-list-view">
          <div className="page-action">
            <div className="d-flex aligns-items-center">
              <BtnAdd
                statement="New Customer"
                onClick={(e) => displayCreateCustomerForm(e)}
                disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_CUSTOMER)}
                className="mr-2"
                style={{marginRight: 5}}
              />
              <Loader loading={false}/>
            </div>
            <div className="search-content-form">
              <Input
                type="text"
                globalClassName="search-input"
                name="filter"
                value={filter}
                placeholder="Enter a keyword..."
                onChange={(e) => handleFilterChange(e)}
              />
            </div>
          </div>

          <Suspense fallback={<Loader loading/>}>
            <Table
              header={headerData}
              body={formatBodyData(props.customers)}
              className='mt-1 pr-5'
              hoverColor="rgba(0, 0, 0, 0.05)"
              isLoading={loadingData}
              asLoader={<Loader loading/>}
              onSelect={(item) => selectElement(item)}
              selectedClass="active"
            />
            {renderPaginarion()}
          </Suspense>
        </div>
        {renderRightModal()}
      </>
  );
};

const mapStateToProps = (state) => {
  const {customers, auth} = state;

  return {
    customers: customers.customers, 
    selectedCustomer: customers.selectedCustomer,
    shop : auth?.authData?.shop,
  };
}

export default connect(mapStateToProps, {
  setCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  selectCustomer
})(CustomersPage);
