import React, {useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import {toastr} from 'react-redux-toastr';
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import { _getGroupById, _assagnRightsToGroup, _removeRightsfromGroup } from "../../../../services/Group";
import { _getRights } from "../../../../services/Rights";
import { selectGoup } from "../../../../redux/action/GroupAction";
import { setRights } from "../../../../redux/action/rightAction";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Label from "../../../../Components/label"
import LabelRadio from "../../../../Components/LabelRadio";
import { FcInspection, FcFilingCabinet, FcPrevious, FcRight, FcLeft } from "react-icons/fc";
import "./groups.css";

function GroupDetails (props) {
    let [loading, setLoading] = useState(false);
    let [rights, setRights] = useState([]);
    let [groupRights, setGroupRights] = useState([]);
    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        (async () => {
            await getGroupById(+params.id);
        })()
        // eslint-disable-next-line
    }, []);

    const getGroupById = async (id) => {
        // Check permission on action /// 653336344
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_GROUP)){
            setLoading(true);
            try {
                const res = await _getGroupById(+id);
                if(res.status === 200){
                    setLoading(false);
                    let gRights = res.data?.groupRights.map(item => {
                        return Object.assign(item, { ...item, right: { ...item.right, checked: false} });
                    });
                    const data = { ...res.data, groupRights: gRights};
                    props.selectGoup(data);
                    setGroupRights(data.groupRights);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        (async () => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_RIGHT)){
                setLoading(true);
                try {
                    const res = await _getRights("", 1000, 1);
                    if(res.status === 200){
                        setLoading(false);
                        let rights = res.data.rows.map(item => {
                            return Object.assign(item, {checked: false});
                        });
                        props.setRights(rights);
                        setRights(rights);
                    }else{
                        setLoading(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e, {timeOut: 5000});
                    setLoading(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);

    const handleLabelChange = (e, item) => {
        const newRights = [ ...rights ];
        const rightIndex = newRights.findIndex(elm => elm.id === item.id);
        newRights[rightIndex] = Object.assign(item, {checked: e.target.checked});
        setRights([...newRights]);
    }

    const handleLabelGroupChange = (e, item) => {
        const newGroupRights = [ ...groupRights ];
        const groupRightIndex = newGroupRights.findIndex(elm => elm.right.id === item.right.id);
        newGroupRights[groupRightIndex] = Object.assign(item, { ...item, right: { ...item.right, checked: e.target.checked} });
        setGroupRights([...newGroupRights]);
    }

    const checkAllRights = () => {
        const newRights = rights.map(item => Object.assign(item, {checked: true}));
        setRights(newRights);
    }

    const checkAllGroupRights = () => {
        const newGroupRights = groupRights.map(item => Object.assign(item, { ...item, right: { ...item.right, checked: true} }));
        setGroupRights(newGroupRights);
    }

    const uncheckAllRights = () => {
        const newRights = rights.map(item => Object.assign(item, {checked: false}));
        setRights(newRights);
    }

    const uncheckAllGroupRights = () => {
        const newGroupRights = groupRights.map(item => Object.assign(item, { ...item, right: { ...item.right, checked: false} }));
        setGroupRights(newGroupRights);
    }

    const toggleCheckRights = (e) => {
        if(e.target.checked){
            checkAllRights();
        }else{
            uncheckAllRights();
        }
    }

    const toggleCheckGroupRights = (e) => {
        if(e.target.checked){
            checkAllGroupRights();
        }else{
            uncheckAllGroupRights();
        }
    }

    const assignRightsToGroup = async (e) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT)){
            let checkedRights = rights.filter(elm => elm.checked === true);
            let assignedRights = [];
            checkedRights.forEach(elm => {
                assignedRights.push({ rightId : elm.id });
            });
            if(assignedRights.length <= 0) return;
            setLoading(true);
            try {
                const payload = { rights : [...assignedRights] };
                const res = await _assagnRightsToGroup(payload, params.id);
                if(res.status === 200){
                    setLoading(false);
                    uncheckAllRights();
                    // TODO: add checked rights to rights group
                    await getGroupById(params.id);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }

    const removeRightsfromGroup = async (e) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT)){
            let checkedRights = groupRights.filter(elm => elm.right.checked === true);
            let assignedRights = [];
            checkedRights.forEach(elm => {
                assignedRights.push(elm.rightId);
            });
            if(assignedRights.length <= 0) return;
            setLoading(true);
            try {
                const payload = { rights : [...assignedRights] };
                const res = await _removeRightsfromGroup(payload, params.id);
                if(res.status === 200){
                    setLoading(false);
                    uncheckAllGroupRights();
                    // TODO: add checked rights to rights group
                    await getGroupById(params.id);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }
    
    const handleFilterRights = (e) => {
        if(e.target.value.length){
            const rights = props.rights.filter((elm) => elm?.description?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) >= 0 );
            setRights(rights);
        }else{
            setRights(props.rights);
        }
    }

    const handleFilterGroupRights = (e) => {
        if(e.target.value.length){
            const groupRights = props.selectedGroup?.groupRights?.filter((elm) => elm.right.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 );
            setGroupRights(groupRights);
        }else{
            setGroupRights(props.selectedGroup?.groupRights);
        }
    }

    const renderRights = () => {
        return rights.map((item, i) => {
            return <LabelRadio key={i} id={i} statement={item.description} icon={FcInspection} className="mb-2" checked={item.checked} onChange={(e) => handleLabelChange(e, item)} />
        })
    }

    const renderGroupRights = () => { // props.selectedGroup?.groupRights?
        return groupRights.map((item, i) => {
            return <LabelRadio key={i} id={`idx-${i}`} statement={item?.right?.description} icon={FcInspection} className="mb-2" checked={item?.right?.checked} onChange={(e) => handleLabelGroupChange(e, item)} />
        })
    }

    const goback = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
        <div className="group-detail-view">
            <div className="page-action">
                <div className="d-flex align-items-center">
                    <button className="btn btn-prev mr-2" onClick={goback}> <FcPrevious /> <span style={{ marginLeft: 10, color: "#1179AD" }} >Retour</span> </button>
                    <Loader loading={loading} />
                </div>
                <div className="title-right">
                    <span className="sub-title-page ">{`GROUP : ${props.selectedGroup?.name}`}</span>
                </div>
            </div>
            <div className="content-group-detail">
                <div className="c-left">
                    <div className="c-header">
                        <div> 
                            <Label statement={"RIGHTS ("+props.rights.length+")"} icon={FcInspection} /> 
                            <label htmlFor="checkAllRights">
                                <input type="checkbox" name="checkAllRights" id="checkAllRights" className="checkbox ml-2" onChange={toggleCheckRights} />
                                <span className="ml-2">Selectionner tout</span> 
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <input type="text" name="filterights" id="filterights" className="form-control" placeholder="rechercher..." onChange={handleFilterRights} />
                        </div>
                    </div>
                    <div className="c-list">
                        {renderRights()}
                    </div>
                </div>
                <div className="c-middle">
                    <button className="btn btn-light mr-2" onClick={(e) => assignRightsToGroup(e)}> <FcRight /> </button>
                    <button className="btn btn-light mr-2 mt-2" onClick={(e) => removeRightsfromGroup(e)}> <FcLeft /> </button>
                </div>
                <div className="c-right">
                    <div className="c-header">
                        <div> 
                            <Label statement={`GROUP : ${props.selectedGroup?.name} (RIGHTS) ${props.selectedGroup?.groupRights?.length}`} icon={FcFilingCabinet} /> 
                            <label htmlFor="checkAllGroupRights">
                                <input type="checkbox" name="checkAllGroupRights" id="checkAllGroupRights" className="checkbox ml-2" onChange={toggleCheckGroupRights} />
                                <span className="ml-2">Selectionner tout</span> 
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <input type="text" name="filterights" id="filterights" className="form-control" placeholder="rechercher..." onChange={handleFilterGroupRights} />
                        </div>
                    </div>
                    <div className="c-list">
                        {renderGroupRights()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {groups, rights} = state;
    return { 
        groups : groups.groups,
        rights : rights.rights,
        selectedGroup : groups.selectedGroup,
    }
}

export default connect(mapStateToProps, { selectGoup, setRights })(GroupDetails);
