import React, {Suspense, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Modal, Container, Row, Col, } from "react-bootstrap";
import {
  addCampaign,
  deleteCampaign,
  selectCampaign,
  setCampaigns,
  updateCampaign
} from "../../../redux/action/smsAction";
import {
    _createCampaigns,
    _getCampaign,
    _deleteCampaign,
    _getSmsCountByShopId,
} from "../../../services/sms";
import {
  getCustomers
} from "../../../services/Customers";
import {
  setCustomers,
} from "../../../redux/action/CustomersAction";
import { setSmsCount } from "../../../redux/action/shopAction";
import Table from "../../../Components/Table/GenericTable";
import BtnAdd from "../../../Components/BtnAdd";
import {_checkAccessOrRedirect} from "../../../services/Auth";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";
import Loader from "../../../Components/Loader";
import Input from "../../../Components/CustomInput/CustomInput";
import {toastr} from "react-redux-toastr";
import ModalRight from "../../../Components/ModalRight";
import Button from "../../../Components/InputButton/InputButton";
import {Pagination} from "react-bootstrap";
import PaginationSize from "../../../Components/paginationSize";
import { IoTrash } from "react-icons/io5";
import LabelRadio from "../../../Components/LabelRadio";
import { FcBusinessman } from "react-icons/fc";

import "./sms.css";

function SmsPageView(props) {

    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState("");
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [loadingForm,setLoadingForm ] = useState(false);
    let [stateCampaign, setStateCampaign] = useState({});
    let [stateData, setStateData] = useState({});
    let [displayForm, setDisplayForm] = useState(null);
    let [isEdit, setIsEdit] = useState(false);
    let [customers, setCustomers] = useState([]);
    let [receivers, setReceivers] = useState([]);
    let [showModalCustomers, setShowModalCustomers] = useState(false);

    const pageRange = 130;

    useEffect(() => {
        ( () => getCampaigns(filter, 20, 1) )();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
      const customers = [];
      props.customers.forEach(customer => {
          customer.selected = false;
          customers.push(customer);
      });
      setCustomers(customers);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (() => getSmsCountOfShop())();
    // eslint-disable-next-line
  }, []);

    const getCampaigns = async (filter = "", pageSize = 20, page = 1) => {
    // Check permission on action
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SMS)) {
      setLoadingData(true);
      try {
        const res = await _getCampaign(filter, pageSize, page);
        setStateData({
          currentPage: res.data.pager.currentPage,
          pageSize: res.data.pager.pageSize,
          endIndex: res.data.pager.endIndex,
          endPage: res.data.pager.endPage,
          pages: res.data.pager.pages,
          startIndex: res.data.pager.startIndex,
          startPage: res.data.pager.startPage,
          totalItems: res.data.pager.totalItems,
          totalPages: res.data.pager.totalPages,
        });
        if (res.status === 200) {
          setLoadingData(false);
          props.setCampaigns(res.data.rows);
        } else {
          setLoadingData(false);
          toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
        }
      } catch (e) {
        console.error("Erreur: ", e);
        toastr.error('Erreur', e.message, {timeOut: 5000});
        setLoadingData(false);
      }
    }
  };

  const getSmsCountOfShop = async () => {
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SMS)) {
      try {
        const res = await _getSmsCountByShopId(+props.shop.id);
        if (res.status === 200) {
          props.setSmsCount(res.data);
        } else {
          toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
        }
      } catch (e) {
        console.error("Erreur: ", e);
        toastr.error('Erreur', e.message, {timeOut: 5000});
      }
    }
  }

  useEffect(() => {
    (async (filter = "", pageSize = 200000, page = 1) => {
      // Check permission on action
      if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_CUSTOMER)) {
        try {
          const res = await getCustomers(filter, pageSize, page);
          if (res.status === 200) {
            props.setCustomers(res.data.rows);
          } else {
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
        } catch (e) {
          console.error("Erreur: ", e.message);
          toastr.error('Erreur', e.message, {timeOut: 5000});
        }
      }
    })()
    // eslint-disable-next-line
  }, []);

  const headerData = [
    {
      title: <div className="size-30 flex-center  rounded"> # </div>,
      align: "center",
      className: '',
      width: 50,
      name: "number"
    },
    {title: "Contacts", align: "left", className: 'pl-15', width: "auto", name: "contacts"},
    {title: "message", align: "left", className: 'pl-15 sm-hide', width: "auto", name: "message"},
    {title: "nb sms", align: "center", className: 'pl-15', width: "75px", name: "nbsms"},
    {title: <> Actions </>, align: "left", className: '', width: "75px", name: "action"},
  ];

  const formatBodyData = (data) => {
    return data?.map((item, i) => {
      let resData = {
        originalData: item,
        number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
        contacts: item.receivers,
        message: item.messageContent,
        nbsms: <b>{item.numberOfPage}</b>,
        action: renderAction(item),
      }
      return resData;
    })
  }

  const processDelete = async (e, id) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_SMS)) {
      const toastrConfirmOptions = {
        onOk: () => deleteCampaign(id),
        onCancel: () => {}
      };
      toastr.confirm("Do you really want to delete this Campaign ?", toastrConfirmOptions);
    }
  }

  const deleteCampaign = async (id) => {
    setLoading(true);
    try {
      const res = await _deleteCampaign(id);
      if (res.status === 200) {
        setLoading(false);
        props.deleteCampaign(id);
      } else {
        setLoading(false);
        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
      }
    } catch (e) {
      console.error("Erreur: ", e);
      toastr.error('Erreur', e.message, {timeOut: 5000});
      setLoading(false);
    }
  }

  const renderAction = (item) => {
    return (
      <div className="">
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_SMS) ?
          <button className="btn btn-action" onClick={(e) => processDelete(e, item.id)}  title='Delete Campaign'><IoTrash/></button> :
          <></>}
      </div>
    )
  }

  const getNumberOfSms = (statement="") => {
    // numberOfPage
    const numberOfPage = Math.ceil(statement.length / pageRange);
    const countContacts = receivers.length;
    return numberOfPage * countContacts;
  }

  const getNumberOfPage = (statement="") => {
    return statement.length ? Math.ceil(statement.length / pageRange) : 0;
  }

  const getCharacterLeft = (statement="") => {
    if(!statement.length) return pageRange;
    const coef = Math.floor(statement.length/pageRange);
    const multi = coef * pageRange;
    const diff = statement.length - multi;

    return pageRange - diff;
  }

  const selectElement = (item) => {
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_SMS)) {
      props.selectCampaign(item?.originalData);
      setIsRightVisible(true);
      setDisplayForm("preview");
    }else{
      console.log("You don't have permissions!");
    }
  }

  const setPage = async (page) => {
    if (stateData.currentPage !== page) {
      await getCampaigns("", stateData.pageSize, page);
    }
  }

  const setSize = async (pageSize) => {
    await getCampaigns("", pageSize, 1);
  }

  const renderPaginarion = () => {
    if (stateData.totalPages > 1) {
      let items = [];
      for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
            className="pointer">
            {number}
          </Pagination.Item>,
        );
      }
      return (
        <div className="d-flex align-items-center justify-content-between mt-4">
          <Pagination>
            <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.startPage)}/>
            <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage - 1)}/>
            {items}
            <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage + 1)}/>
            <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.endPage)}/>
          </Pagination>
          <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
        </div>
      )
    } else {
      return <></>
    }
  }

  const renderRightModal = () => {
    switch (displayForm) {
      case "new" : {
        return (
          <ModalRight
            title={isEdit ? "Edit campaign" : "New campaign"}
            content={renderFormCreate()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }
      case "preview" : {
        return (
          <ModalRight
            title="Campaign detail"
            content={renderDetailCampaign()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }

      default :
        return <></>
    }
  }

  const displayCreateCampaignForm = (e) => {
    e.preventDefault();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.SEND_SMS)) {
      setIsRightVisible(true);
      setStateCampaign({});
      setIsEdit(false);
      setDisplayForm("new");
    }
  }

  const decrementSmsCount = (value) => {
    const smsLeft = +props.smsCount?.numberOfSms;
    const diff = smsLeft - value;
    const payload = {
      ...props.smsCount,
      numberOfSms: diff,
    };
    props.setSmsCount(payload);
  }

  const checkSmsLeft = (nbSms) => {
    const smsLeft = +props.smsCount?.numberOfSms || 0;
    return smsLeft >= +nbSms;
  }

  const verifyCampaignData = () => {
    if(stateCampaign?.messageContent){
      return stateCampaign.messageContent.length > 0 && receivers.length > 0;
    }
    return false;
  }

  const createCampaign = async (e) => {
    const numberOfPage = getNumberOfSms(stateCampaign.messageContent);
    if(checkSmsLeft(numberOfPage)){
      if(verifyCampaignData()){
        setLoadingForm(true);
        try {
          const payload = { 
            shopId : +props.shop.id,
            campaign : {
              ...stateCampaign, 
              numberOfPage,
            }
          };
          const res = await _createCampaigns(+props.shop.id, payload);
          if (res.status === 200) {
            setLoadingForm(false);
            props.addCampaign(res.data);
            toastr.success('Success', numberOfPage + " SMS sent to " + receivers.length + " contacts.", {timeOut: 5000});
            decrementSmsCount(numberOfPage);
            uncheckAllCustomers();
            setStateCampaign({});
          } else {
            setLoadingForm(false);
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingForm(false);
        }
      }else{
        toastr.error('Error', "Please, make shure you select any contacts or enter a message", {timeOut: 5000});
      }
    }else{
      toastr.error('Error', "You have not enouth sms to proceed! Contact the administrator for more informations.", {timeOut: 5000});
    }
  }

  const editCampaign = async (e) => {
    e.stopPropagation();
  }

  const handleChange = (e) => {
    stateCampaign = {...stateCampaign, [e.target.name]: e.target.value}
    setStateCampaign(stateCampaign);
  }

  const renderFormCreate = () => {
      let receivers = stateCampaign?.receivers;
      let countContacts = 0;

      if (receivers){
        countContacts = receivers.split(",").length;
      }
    return (
      <form name="campaignCreateForm" className="m-2" onSubmit={createCampaign}>
        <div className="form-group">
            <label htmlFor="receivers" className="label-input">Contacts:</label>
            {/* <input className="form-control" type="text" name="receivers" id="receivers" value={stateCampaign.receivers || ''}
                onChange={handleChange} required/> */}
            <button className="form-control btn border" type="button" onClick={(e) => setShowModalCustomers(true)}>
                <span> Select contacts - <b style={{fontSize: "0.8rem"}}> {`${countContacts} Contacts`} </b> </span>
            </button>
        </div>

        <div className="form-group">
          <label htmlFor="messageContent" className="label-input">Message:</label>
          <textarea className="form-control" type="text" name="messageContent" id="messageContent" value={stateCampaign.messageContent || ''}
                onChange={handleChange} rows="5" style={{resize: "none"}} required></textarea>
          <div className="d-flex justify-content-between mt-2" style={{fontSize: "0.8rem"}}>
            <div>Number of page : { getNumberOfPage(stateCampaign?.messageContent) }</div> 
            <div>{`characters left: ${getCharacterLeft(stateCampaign?.messageContent)}`}</div>
          </div>
        </div>

        <div className="separator mb-2 mt-2"></div>
        <div className="form-group">
          <Button
            variant="primary"
            value={isEdit ? "Update" : "Submit"}
            type="submit"
            className="m-0 form-control"
            loading={loadingForm}
            onClick={isEdit ? editCampaign : createCampaign}
          />
        </div>
      </form>
    )
  }

  const renderDetailCampaign = () => {
      let receivers = props.selectedCampaign.receivers;
        receivers = receivers.split(",").join(", ");
    return (
      <div className="p-2">
        <div className="item-group">
          <div className="item-left">Contacts</div>
        </div>
        <p className="content-text-area">
            {receivers}
        </p>
        <div className="item-group">
          <div className="item-left">Messaqge</div>
        </div>
        <p className="content-text-area">
            {props.selectedCampaign.messageContent}
        </p>
        <div className="item-group">
          <div className="item-left">Created at</div>
          <div className="item-right">{props.selectedCampaign.createdAt}</div>
        </div>
      </div>
    )
  }

  const getContacts = (e) => {
    e.stopPropagation();
    const selectedCustomers = customers.filter( customer => customer.checked === true );
    const contacts = [];
    if(selectedCustomers.length){
        selectedCustomers.forEach( customer => {
            if(customer.phone1) { contacts.push(customer.phone1)};
        });
    }
    stateCampaign = {...stateCampaign, receivers: contacts.join(",")};
    setStateCampaign(stateCampaign);
    setReceivers(contacts);
    setShowModalCustomers(false);
  }

  const handleLabelChange = (e, item) => {
      const newCustomers = [ ...customers ];
      const customerIndex = newCustomers.findIndex(elm => elm.id === item.id);
      newCustomers[customerIndex] = Object.assign(item, {checked: e.target.checked});
      setCustomers([...newCustomers]);
  }

  const checkAllCustomers = () => {
    const newCustomers = customers.map(customer => Object.assign(customer, {checked: true}));
    setCustomers([...newCustomers]);
  }

  const uncheckAllCustomers = () => {
    const newCustomers = customers.map(customer => Object.assign(customer, {checked: false}));
    setCustomers([...newCustomers]);
  }

  const toggleCheckAllCustomers = (e) => {
    if(e.target.checked){
        checkAllCustomers();
    }else{
        uncheckAllCustomers();
    }
  }

  const renderCustomers = () => {
    return customers.map((item, i) => {
        return <LabelRadio key={i} id={i} statement={`${item.name} (${item.phone1})`} icon={FcBusinessman} className="mb-2" checked={item.checked} onChange={(e) => handleLabelChange(e, item)} />
    })
  }

  return (
    <>
        <div className="sms-page-view">
            <div className="page-action">
                <div className="d-flex aligns-items-center">
                    <BtnAdd
                    statement="New Campaign"
                    onClick={(e) => displayCreateCampaignForm(e)}
                    disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.SEND_SMS)}
                    className="mr-2"
                    style={{marginRight: 5}}
                    />
                    <span className="label"><b>{`${props.smsCount?.numberOfSms || 0} `}</b> &nbsp; sms left</span>
                    <Loader loading={loading}/>
                </div>
                <div className="search-content-form">
                    <Input
                    type="text"
                    globalClassName="search-input"
                    name="filter"
                    value={filter}
                    placeholder="Enter a keyword..."
                    onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
            </div>

            <Suspense fallback={<Loader loading/>}>
                <Table
                header={headerData}
                body={formatBodyData(props.campaigns)}
                className='mt-1 pr-5'
                hoverColor="rgba(0, 0, 0, 0.05)"
                isLoading={loadingData}
                asLoader={<Loader loading/>}
                onSelect={(item) => selectElement(item)}
                selectedClass="active"
                />
                {renderPaginarion()}
            </Suspense>

        </div>

        {renderRightModal()}

        {/* Modal for profile */}

            <Modal show={showModalCustomers}  onHide={() => setShowModalCustomers(false)}>
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center justify-content-between">
                    <span>Customers {``}</span>
                    <label htmlFor="checkAllCustomers" style={{fontSize: "0.8rem"}}>
                      <input type="checkbox" name="checkAllCustomers" id="checkAllCustomers" className="checkbox ml-2" onChange={toggleCheckAllCustomers} />
                      <span className="ml-2">Check all</span> 
                    </label>
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col>
                      <div className="modal-content-data">
                        {renderCustomers()}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <button 
                  type='button' 
                  className='btn btn-info' 
                  onClick={(e) => getContacts(e)} 
                  desabled={receivers.length ? true : false}
                >Get contacts</button>
              </Modal.Footer>
            </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  const {sms, auth, customers, shop} = state;

  return {
    campaigns: sms.campaigns, 
    selectedCampaign: sms.selectedCampaign,
    customers: customers.customers,
    shop : auth?.authData?.shop,
    smsCount: shop.smsCount,
  };
}

export default connect(mapStateToProps, {
  addCampaign,
  deleteCampaign,
  selectCampaign,
  setCampaigns,
  updateCampaign,
  setSmsCount,
  setCustomers,
})(SmsPageView);
