import React, {Component} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAuthData, setCurrentAuthUser, setAuthToken } from "../redux/action/AuthAction";
import { _extractPayloadFromToken } from "../helpers/jwt";
import MainLayout from "../layouts/main";
import Auth from "../pages/Auth";
import ShopPage from "../pages/Shop";
import DashboardPage from "../pages/Main/Dashboard";
import AccountManagementPage from "../pages/Main/AccountManagement";
import UsersAccounts from "../views/main/users/usersAccounts";
import Inventory from "../pages/Main/Inventory";
import Services from "../pages/Main/Services";
import Groups from "../views/main/users/groups";
import GroupDetail from "../views/main/users/groups/groupDetail";
import UserAccountDetail from "../views/main/users/usersAccounts/userAccountDetail";
import Rights from "../views/main/users/rights";
import CategoryProduct from "../views/main/inventory/categoryProduct";
import CategoryService from "../views/main/inventory/categoryService";
import Product from "../views/main/inventory/product";
import ProductHistory from "../views/main/inventory/product/productHistory";
import Service from "../views/main/inventory/services";
import Operations from "../views/main/inventory/operations";
import NoMatch from "./404";
import { _checkAuth, _checkAccessOrRedirect } from "../services/Auth";
import { FUNCTIONALITIES_CODE, PATHS } from "../constants/AuthConstants";
import CustomerPage from "../pages/Customer";
import CustomersPage from "../pages/Main/Customers";
import CustomersList from "../views/main/Customers";
import CustomersHistory from "../views/main/Customers/customerHitory";
import CustomerServiceHistory from "../views/main/Customers/customerServiceHistory";
import EmployeeList from "../views/main/Employees";
import EmployeeHistory from "../views/main/Employees/employeeHistory";
import EmployeeServiceHistory from "../views/main/Employees/employeeServiceHistory";
import EmployeeFunction from "../views/main/Employees/employeeFunction";
import SellersPage from "../pages/Main/Sellers";
import CashierPage from "../pages/Main/Cashier";
import CashierRegistration from "../views/main/Cashier";
import Orders from "../views/main/Cashier/orders";
import OrderPage from "../pages/Main/Order";
import OrderView from "../views/main/Order";
import OrderList from "../views/main/Order/order-operations";
import CashierOperations from "../views/main/Cashier/cashier-operations";
import FetchData from "../pages/Auth/fetchData";
import Sms from "../pages/Main/Sms";

class RoutesList extends Component {
    constructor(props){
        super(props);
        // const token = JSON.parse(sessionStorage.getItem('token'));
        this.state = {
            // authToken: token
        };
    }

    componentDidMount = () => {
        this.checkAndAuthenticate();
    }

    checkAndAuthenticate = () => {
        try{
            const token = sessionStorage.getItem('token');
            if(token){
                const tk = JSON.parse(token);
                const authData = _extractPayloadFromToken(tk);
                this.props.setAuthData(authData);
                this.props.setCurrentAuthUser(authData.user);
                this.props.setAuthToken(token);
            }
        }catch(e){
            console.error("Error: ", e);
        }
    }

    renderRoute = (Element, right) => {
        if(_checkAuth()){
            if(_checkAccessOrRedirect(right)){
                return <Element />
            }
            return <Navigate to={PATHS.UNAUTHORIZED_PATH} />
        }
        return <Navigate to={PATHS.AUTH_FAILED_URL}  />
    }

    render(){
        return (
            <Routes>
                <Route path="/" element={<Auth />} />
                <Route path="/fetch-data" element={<FetchData />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/customer" element={<CustomerPage />} />
                <Route path="/main/*" element={<MainLayout /> } >
                    <Route path="dashboard" element={ this.renderRoute(DashboardPage, FUNCTIONALITIES_CODE.DASHBOARD) } />
                    <Route path="account-management/*" element={ this.renderRoute(AccountManagementPage, FUNCTIONALITIES_CODE.ACCOUNT_MANAGEMENT) }>
                        <Route path="users-accounts" element={ this.renderRoute(UsersAccounts, FUNCTIONALITIES_CODE.USERS_ACCOUNTS) } />
                        <Route path="users-accounts/:id/groups" element={ this.renderRoute(UserAccountDetail, FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER) } />
                        <Route path="groups" element={ this.renderRoute(Groups, FUNCTIONALITIES_CODE.GROUP_RIGHT) } />
                        <Route path="groups/:id" element={ this.renderRoute(GroupDetail, FUNCTIONALITIES_CODE.ASSIGN_RIGHTS_TO_GROUP_RIGHT) } />
                        <Route path="rights" element={ this.renderRoute(Rights, FUNCTIONALITIES_CODE.RIGHT) } />
                    </Route>
                    <Route path="customers/*" element={this.renderRoute(CustomersPage, FUNCTIONALITIES_CODE.CUSTOMER)}>
                        <Route path="all" element={ this.renderRoute(CustomersList, FUNCTIONALITIES_CODE.LIST_CUSTOMER) } />
                        <Route path="all/:id/history" element={ this.renderRoute(CustomersHistory, FUNCTIONALITIES_CODE.HISTORY_CUSTOMER) } />
                        <Route path="all/:id/services-history" element={ this.renderRoute(CustomerServiceHistory, FUNCTIONALITIES_CODE.HISTORY_CUSTOMER) } />
                    </Route>
                    <Route path="employees" element={this.renderRoute(SellersPage, FUNCTIONALITIES_CODE.EMPLOYEE)}>
                        <Route path="all" element={ this.renderRoute(EmployeeList, FUNCTIONALITIES_CODE.LIST_EMPLOYEE) } />
                        <Route path="all/:id/history" element={ this.renderRoute(EmployeeHistory, FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE) } />
                        <Route path="all/:id/services-history" element={ this.renderRoute(EmployeeServiceHistory, FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE) } />
                        <Route path="functions" element={ this.renderRoute(EmployeeFunction, FUNCTIONALITIES_CODE.LIST_FUNCTION_EMPLOYEE) } />
                    </Route>
                    <Route path="inventory/*" element={this.renderRoute(Inventory, FUNCTIONALITIES_CODE.INVENTORY)}>
                        <Route path="operations" element={ this.renderRoute(Operations, FUNCTIONALITIES_CODE.LIST_OPERATIONS) } />
                        <Route path="products" element={ this.renderRoute(Product, FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT) } />
                        <Route path="products/:id" element={ this.renderRoute(ProductHistory, FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT) } />
                        <Route path="categories" element={ this.renderRoute(CategoryProduct, FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY) } />
                    </Route>
                    <Route path="services/*" element={this.renderRoute(Services, FUNCTIONALITIES_CODE.SERVICE)}>
                        <Route path="all" element={ this.renderRoute(Service, FUNCTIONALITIES_CODE.LIST_SERVICE) } />
                        <Route path="categories" element={ this.renderRoute(CategoryService, FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY) } />
                    </Route>
                    <Route path="cashier/*" element={this.renderRoute(CashierPage, FUNCTIONALITIES_CODE.CASHIER)}>
                        <Route path="cashier" element={ this.renderRoute(CashierRegistration, FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION) } />
                        <Route path="operations" element={ this.renderRoute(CashierOperations, FUNCTIONALITIES_CODE.LIST_CASHIER_OPERATION) } />
                        <Route path="customer-orders" element={ this.renderRoute(Orders, FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER) } />
                    </Route>
                    <Route path="shop/*" element={this.renderRoute(OrderPage, FUNCTIONALITIES_CODE.CUSTOMER_SHOP)}>
                        <Route path="order" element={ this.renderRoute(OrderView, FUNCTIONALITIES_CODE.CREATE_CUSTOMER_ORDER) } />
                        <Route path="operations" element={ this.renderRoute(OrderList, FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER) } />
                    </Route>
                    <Route path="sms" element={this.renderRoute(Sms, FUNCTIONALITIES_CODE.SMS)} />
                </Route>
                <Route
                    path="*"
                    element={({ history }) => {
                        return <NoMatch history={history} />;
                    }}
                />
            </Routes>
        )
    }
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return { currentUser : auth.currentAuth }
}

export default connect(mapStateToProps, { setAuthData, setCurrentAuthUser, setAuthToken })(RoutesList)
