import React from "react";

import "./ticket.css";

const Ticket = ({title, statement, icon, className, style, loading, onClick}) => {

    const processClick = (e) => {
        if(onClick) { onClick(e) }
    }

    return (
        <div className={`ticket ${className ? className : ""}`} style={{...style}} onClick={(e) => processClick(e)}>
            <div className="ico">
                {icon}
            </div>
            <div className="info">
                <div className="title">{title}</div>
                { !loading ?
                    <div className="statement">{statement}</div> :
                    <div className="statement loading"></div>
                }
            </div>
        </div>
    )
}

export default Ticket;