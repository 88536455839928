import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP, DELETE_GROUP, SELECT_GROUP} from '../action-type/GroupType';

export const addGroup = (data) => ({
    type: ADD_GROUP,
    payload: data
})

export const setGroups = (data) => ({
    type: SET_GROUPS,
    payload: data
})

export const updateGroup = (data) => ({
    type: UPDATE_GROUP,
    payload: data
})

export const deleteGroup = (id) => ({
    type: DELETE_GROUP,
    payload: id
})

export const selectGoup = (data) => ({
    type: SELECT_GROUP,
    payload: data
})