import { ADD_CATEGORY, ADD_CATEGORIES, SET_CATEGORIES, UPDATE_CATEGORY, DELETE_CATEGORY, SELECT_CATEGORY} from '../action-type/categoryProductType';
const INITIAL_STATE = {
    categories : [],
    selectedCategory : {},
};

const CategoryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_CATEGORIES : {
            return { ...state, categories: action.payload };
        }
        case ADD_CATEGORY: {
            if (action.payload) {
                return {
                    ...state,
                    categories: [ action.payload, ...state.categories],
                }
            }
            return state;
        }
        case ADD_CATEGORIES: {
            if (action.payload) {
                const data = state.categories.concat(action.payload);
                return { ...state, categories: data, }
            }
            return state;
        }
        case UPDATE_CATEGORY: {
            if (action.payload) {
                const i = state.categories.findIndex(elm => elm.id === action.payload.id);
                state.categories[i] = action.payload;
                return state
            }
            return state;
        }
        case DELETE_CATEGORY: {
            if (action.payload) {
                const {categories} = state;
                const i = categories.findIndex(elm => elm.id === action.payload);
                categories.splice(i, 1);
                return {...state, categories};
            }
            return state;
        }
        case SELECT_CATEGORY: {
            return {
                ...state,
                selectedCategory: action.payload
            }
        }
        default: return state;
    }
}

export default CategoryReducer;