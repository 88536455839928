import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const getCustomers = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_CUSTOMER } };
    return await axios.get(`customers?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getOperationsServicesByCustomerId = async (id, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`customers/${+id}/history?pageSize=${pageSize}&page=${page}`, config);
}

export const _createCustomer = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_CUSTOMER } };
    return await axios.post(`customers`, data, config);
}

export const _createCustomerUser = async (data) => {
    return await axios.post(`customer-users`, data);
}

export const _updateCustomer = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_CUSTOMER } };
    const id = data.id;
    delete data.id;
    return await axios.put(`customers/${+id}`, data, config);
}

export const _deleteCustomer = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_CUSTOMER } };
    return await axios.delete(`customers/${+id}`, config);
}
