import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _createCashierOperation = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION } };
    return await axios.post(`cashier-operations`, data, config);
}

export const _createCashierOperations = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION } };
    return await axios.post(`cashier-operations/multiples`, data, config);
}

export const _createCashierOperationFromOrder = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION } };
    return await axios.post(`cashier-operations/orders`, data, config);
}

export const _cancelCashierOperation = async (payload, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_CASHIER_OPERATION } };
    return await axios.put(`cashier-operations/${id}`, payload, config);
}

export const _getCashierOperations = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_CASHIER_OPERATION } };
    return await axios.get(`cashier-operations?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getCashierOperationById = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_CASHIER_OPERATION } };
    return await axios.get(`cashier-operations/${+id}`, config);
}