import {
  ADD_EMPLOYEES, DELETE_EMPLOYEES, SELECT_EMPLOYEES, SET_EMPLOYEES, UPDATE_EMPLOYEES,
} from "../action-type/sellersType";

const INITIAL_STATE = {
  sellers: [],
  selectedSeller: {}
};

const SellersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EMPLOYEES: {
      if (action.payload) {
        return {
          ...state,
          sellers: action.payload
        }
      }
      return state;
    }
    case ADD_EMPLOYEES: {
      if (action.payload) {
        return {
          ...state,
          sellers: [action.payload, ...state.sellers]
        }
      }
      return state;
    }
    case UPDATE_EMPLOYEES: {
      if (action.payload) {
        const i = state.sellers.findIndex(elm => elm.id === action.payload.id);
        state.sellers[i] = action.payload;
        return state;
      }
      return state;
    }
    case SELECT_EMPLOYEES: {
      return {
        ...state,
        selectedSeller: action.payload
      }
    }
    case DELETE_EMPLOYEES: {
      if (action.payload) {
        let {sellers} = state;
        const i = sellers.findIndex(elm => elm.id === action.payload);
        sellers.splice(i, 1);
        return {...state, sellers};
      }
      return state;
    }

    default:
      return state
  }
}

export default SellersReducer;
