import {
    SET_ACCOUNTS, ADD_ACCOUNT, UPDATE_ACCOUNT, DELETE_ACCOUNT, SET_RECORDS, ADD_RECORD, ADD_RECORDS_MULTIPLE, UPDATE_RECORD, DELETE_RECORD, SELECT_RECORD, SELECT_ACCOUNT,
    SET_STATEMENTS, SELECT_STATEMENT, ADD_STATEMENTS_MULTIPLE, SET_RECONCICLED_BANK_RECORD_ITEM
} from '../action-type/accountType';

export const setAccounts = (data) => ({
    type: SET_ACCOUNTS,
    payload: data,
});

export const addAccount = (data) => ({
    type: ADD_ACCOUNT,
    payload: data,
});

export const updateAccount = (data) => ({
    type: UPDATE_ACCOUNT,
    payload: data,
});

export const deleteAccount = (id) => ({
    type: DELETE_ACCOUNT,
    payload: id,
});

export const setRecords = (data) => ({
    type: SET_RECORDS,
    payload: data,
});

export const addRecord = (data) => ({
    type: ADD_RECORD,
    payload: data,
});

export const addRecordsMultiple = (data) => ({
    type: ADD_RECORDS_MULTIPLE,
    payload: data,
});

export const updateRecord = (data) => ({
    type: UPDATE_RECORD,
    payload: data,
});

export const deleteRecord = (data) => ({
    type: DELETE_RECORD,
    payload: data,
});

export const selectRecord = (data) => ({
    type: SELECT_RECORD,
    payload: data,
});

export const selectAccount = (data) => ({
    type: SELECT_ACCOUNT,
    payload: data,
});


export const setStatements = (data) => ({
    type: SET_STATEMENTS,
    payload: data,
});

export const addStatementsMultiple = (data) => ({
    type: ADD_STATEMENTS_MULTIPLE,
    payload: data,
});

export const selectStatement = (data) => ({
    type: SELECT_STATEMENT,
    payload: data,
});

export const setReconciledBankRecord = (data) => ({
    type: SET_RECONCICLED_BANK_RECORD_ITEM,
    payload: data,
});