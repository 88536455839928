import React from "react";
// import {useLocation} from 'react-router-dom';


const NoMatch = (props) => {
    return (
    <div className="page-not-found mt-5">
        <h3>
            Page not found: <code>{props.history.location.pathname}</code>
        </h3>
    </div>
    );
}

export default NoMatch;