import {
  ADD_CUSTOMERS,
  DELETE_CUSTOMERS,
  SELECT_CUSTOMERS,
  SET_CUSTOMERS,
  UPDATE_CUSTOMERS,
} from "../action-type/customersType";

const INITIAL_STATE = {
  customers: [],
  selectedCustomer: {}
};

const CustomersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOMERS: {
      if (action.payload) {
        return {
          ...state,
          customers: action.payload
        }
      }
      return state;
    }
    case ADD_CUSTOMERS: {
      if (action.payload) {
        return {
          ...state,
          customers: [action.payload, ...state.customers]
        }
      }
      return state;
    }
    case UPDATE_CUSTOMERS: {
      if (action.payload) {
        const i = state.customers.findIndex(elm => elm.id === action.payload.id);
        state.customers[i] = action.payload;
        return state;
      }
      return state;
    }
    case SELECT_CUSTOMERS: {
      return {
        ...state,
        selectedCustomer: action.payload
      }
    }
    case DELETE_CUSTOMERS: {
      if (action.payload) {
        let customers = state.customers;
        const i = customers.findIndex(elm => elm.id === action.payload);
        customers.splice(i, 1);
        return {...state, customers};
      }
      return state;
    }

    default:
      return state
  }
}

export default CustomersReducer;
