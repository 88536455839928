import React, {useState, useEffect, Suspense} from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../../layouts/defaultContentPage";
import {PAGE} from "../../../../constants/constants";
import BtnAdd from "../../../../Components/BtnAdd";
import {_checkAccessOrRedirect} from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Input from "../../../../Components/CustomInput/CustomInput";
import Label from "../../../../Components/label";
import { GoSignIn, GoSignOut } from "react-icons/go";
import {
    _getProducts,
    _createProduct,
    _addProductQuantity,
    _updateProduct,
    _deleteProduct,
  } from "../../../../services/Inventory";
import { _getCategories } from "../../../../services/categoryProduct";
  import {toastr} from "react-redux-toastr";
  import {IoTrash, IoPencil} from "react-icons/io5";
  import Table from "../../../../Components/Table/GenericTable";
  import {connect} from "react-redux";
  import {
    addProduct,
    setProducts,
    updateProduct,
    deleteProduct,
    selectProduct,
    addProductQuantity,
    removeProductQuantity,
  } from "../../../../redux/action/InventoryAction";
import { setCategories } from "../../../../redux/action/categoryProductAction";
  import ModalRight from "../../../../Components/ModalRight";
  import Button from "../../../../Components/InputButton/InputButton";
  import {Pagination} from "react-bootstrap";
  import PaginationSize from "../../../../Components/paginationSize";
  import {FUNCTIONALITIES_CODE} from "../../../../constants/AuthConstants";
  import moment from "moment";
  import {FcOvertime} from "react-icons/fc";
  import { IoAddCircleOutline } from "react-icons/io5";
  import { numberFormat } from "../../../../helpers/Number";

import "./product.css";

function Product (props) {
    let [isRightVisible, setIsRightVisible] = useState(false);
    let [filter, setFilter] = useState();
    let [loading, setLoading] = useState(false);
    let [loadingData, setLoadingData] = useState(false);
    let [loadingForm, setLoadingForm] = useState(false);
    let [stateProduct, setStateProduct] = useState({ 
      codeID: "", 
      designation: "", 
      conditionnement: "", 
      unitPrice: "", 
      initialStock: "", 
      categoryProductId: "",
      startPromoDate: "",
      endPromoDate: "",
    });
    const [stockIn, setStockIn] = useState(0);
    let [stateData, setStateData] = useState({});
    let [displayForm, setDisplayForm] = useState(null);
    let [isEdit, setIsEdit] = useState(false);
    let [stateErrorForm, setStateErrorForm] = useState(
      { 
        codeID: {valid: false, error: ""}, 
        designation: {valid: false, error: ""}, 
        conditionnement: {valid: false, error: ""}, 
        unitPrice: {valid: false, error: ""}, 
        initialStock: {valid: false, error: ""}, 
        categoryProductId: {valid: false, error: ""} 
    });
    let [productTable, setProductTable] = useState(null);
    let navigate = useNavigate();

    const gotoHistory = (e, id) => {
      e.preventDefault();
      navigate(`${id}`);
    }

    const getTableNode = (node) => {
      setProductTable(node);
    }

    useEffect(() => {
        (async (filter = "", pageSize = 20, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT)) {
            setLoadingData(true);
            try {
              const res = await _getProducts(filter, pageSize, page);
              setStateData({
                currentPage: res.data.pager.currentPage,
                pageSize: res.data.pager.pageSize,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
              });
              if (res.status === 200) {
                setLoadingData(false);
                props.setProducts(res.data.rows);
              } else {
                setLoadingData(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingData(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, []);

      // categories
      useEffect(() => {
        (async (filter="", pageSize=1000, page=1) => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY)){
                setLoadingData(true);
                try {
                    const res = await _getCategories(filter, pageSize, page);
                    
                    if(res.status === 200){
                        setLoadingData(false);
                        props.setCategories(res.data.rows);
                    }else{
                        setLoadingData(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Error', e.message, {timeOut: 5000});
                    setLoadingData(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setStateProduct({ 
        codeID: "", 
        designation: "", 
        conditionnement: "", 
        unitPrice: "", 
        initialStock: "", 
        categoryProductId: "",
        startPromoDate: "",
        endPromoDate: "", 
      });
      // eslint-disable-next-line
    },[])
    
      const getFilterProducts = async (filter = "", pageSize = 20, page = 1) => {
        // Check permission on action
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT)) {
          setLoadingData(true);
          try {
            const res = await _getProducts(filter, pageSize, page);
            setStateData({
              currentPage: res.data.pager.currentPage,
              pageSize: res.data.pager.pageSize,
              endIndex: res.data.pager.endIndex,
              endPage: res.data.pager.endPage,
              pages: res.data.pager.pages,
              startIndex: res.data.pager.startIndex,
              startPage: res.data.pager.startPage,
              totalItems: res.data.pager.totalItems,
              totalPages: res.data.pager.totalPages,
            });
            if (res.status === 200) {
              setLoadingData(false);
              props.setProducts(res.data.rows);
            } else {
              setLoadingData(false);
              toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
            }
          } catch (e) {
            console.error("Erreur: ", e);
            toastr.error('Erreur', e.message, {timeOut: 5000});
            setLoadingData(false);
          }
        }
      };
    
      // Handle change for create customer form
      const handleCSChange = (e) => {
        stateProduct = {...stateProduct, [e.target.name]: e.target.value}
        setStateProduct(stateProduct);
      }

      const handleCSChangeDate = (e) => {
        stateProduct = {...stateProduct, [e.target.name]: e.target.value };
        setStateProduct(stateProduct);
      }

      const handleStockInChange = (e) => {
        if(+e.target.valueAsNumber > 0){
          setStockIn(e.target.valueAsNumber);
        }else{
          setStockIn(0);
        }
      }

      const validateForm = () => {
        const testCodeID = stateProduct?.codeID?.length ? true : false;
        const testDesignation = stateProduct?.designation?.length ? true : false;
        const testCond = stateProduct?.conditionnement?.length ? true : false;
        const testUnitPrice = +stateProduct?.unitPrice >= 0 ? true : false;
        const testInitialStock = +stateProduct?.initialStock >= 0 ? true : false;
        const testProductCategory = +stateProduct?.categoryProductId > 0 ? true : false;

        if(!testCodeID){ setStateErrorForm({...stateErrorForm, codeID : { valid: !!testCodeID, error: "This fild is required!" } })  } else { setStateErrorForm({...stateErrorForm, codeID : { valid: true, error: "" } }) }
        if(!testDesignation){ setStateErrorForm({...stateErrorForm, designation : { valid: !!testDesignation, error: "This fild is required!" } }) } else { setStateErrorForm({...stateErrorForm, designation : { valid: true, error: "" } }) }
        if(!testCond){ setStateErrorForm({...stateErrorForm, conditionnement : { valid: !!testCond, error: "This fild is required!" } })  } else { setStateErrorForm({...stateErrorForm, conditionnement : { valid: true, error: "" } }) }
        if(!testUnitPrice){ setStateErrorForm({...stateErrorForm, unitPrice : { valid: !!testUnitPrice, error: "This fild has not to be null" } })  } else { setStateErrorForm({...stateErrorForm, unitPrice : { valid: true, error: "" } }) }
        if(!testInitialStock){ setStateErrorForm({...stateErrorForm, initialStock : { valid: !!testInitialStock, error: "This fild has not to be null" } })  } else { setStateErrorForm({...stateErrorForm, initialStock : { valid: true, error: "" } }) }
        if(!testProductCategory){ setStateErrorForm({...stateErrorForm, categoryProductId : { valid: !!testProductCategory, error: "This fild is required!" } })  } else { setStateErrorForm({...stateErrorForm, categoryProductId : { valid: true, error: "" } }) }
        return testCodeID && testDesignation && testCond && testInitialStock && testProductCategory;

      }
    
      const createProduct = async (e) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_INVENTORY_PRODUCT)) {
          if (!loading && validateForm()) {
            if(stateProduct.startPromoDate){
              if(!stateProduct.endPromoDate){
                toastr.error('Error', "You have to set the END promo date", {timeOut: 3000});
                return;
              }
            }

            if(stateProduct.endPromoDate){
              if(!stateProduct.startPromoDate){
                toastr.error('Error', "You have to set the START promo date", {timeOut: 3000});
                return;
              }
            }

            if(stateProduct.startPromoDate && stateProduct.endPromoDate && (stateProduct.endPromoDate < stateProduct.startPromoDate)){
              toastr.error('Error', "The START promo date has to be less or equal to END promo date", {timeOut: 3000});
              return;
            }

            setLoadingForm(true);
            try {
              stateProduct = { ...stateProduct, shopId: +props.shop.id };
              const res = await _createProduct(stateProduct);
              if (res.status === 200) {
                props.addProduct(res.data);
                setStateProduct({});
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
              setLoadingForm(false);
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingForm(false);
            }
          }else{
            toastr.error('Error', "Please fill all the required fields!", {timeOut: 3000});
          }
        } else {
          toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
      }
    
      const editProduct = async (e) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_INVENTORY_PRODUCT)) {
          if (!loading && validateForm()) {
            try {
              setLoadingForm(true);
                delete stateProduct.categoryProduct;
                delete stateProduct.operationInventories;
              const res = await _updateProduct(stateProduct, stateProduct.id);
              if (res.status === 200) {
                props.updateProduct(res.data);
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
              setLoadingForm(false);
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingForm(false);
            }
          }else{
            toastr.error('Error', "Please fill all the required fields!", {timeOut: 3000});
          }
        } else {
          toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
      }

      const addProductInStock = async (e) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.INVENTORY_PRODUCT_IN)) {
          if (!loading && +stockIn > 0) {
            try {
              setLoadingForm(true);
              const payload = {
                stockIn,
                quantity: stockIn,
              };
              const res = await _addProductQuantity(payload, stateProduct.id);
              if (res.status === 200) {
                let newProduct = {...stateProduct, initialStock: (stateProduct.initialStock + stockIn)};
                props.updateProduct(newProduct);
                setIsRightVisible(false);
                toastr.success('Success', "Product daa in stock successfully!", {timeOut: 3000});
              } else {
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
              setLoadingForm(false);
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e.message, {timeOut: 5000});
              setLoadingForm(false);
            }
          }else{
            toastr.error('Error', "Please set the quantity!", {timeOut: 3000});
          }
        } else {
          toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3000});
        }
      }

      const renderCategories = () => {
          return props?.categories?.map((item, i) => {
              return (
                  <option value={item.id} key={i}>{item.name}</option>
              )
          })
      }

      const calculatePromo = () => {
        if(stateProduct.discount && +stateProduct.discount > 0 && +stateProduct.discount <= 100){
          const discountPercent = stateProduct.discount ? +stateProduct.discount : 0;
          const valueDiscount = (stateProduct.unitPrice * discountPercent) / 100;
          const priceDiscount = +stateProduct.unitPrice - valueDiscount;
          stateProduct = {...stateProduct, promo: priceDiscount };
          setStateProduct(stateProduct);
        }else{
          stateProduct = {...stateProduct, promo: 0, discount: 0 };
          setStateProduct(stateProduct);
        }

      }

      const handleDiscount = (e) => {
        const val = (e.target.value && +e.target.value > 0) ? e.target.value : 0;
        stateProduct = {...stateProduct, [e.target.name]: val};
        setStateProduct(stateProduct);
        calculatePromo();
      }

      const handleUnitPrice = (e) => {
        stateProduct = {...stateProduct, [e.target.name]: e.target.value};
        setStateProduct(stateProduct);
        calculatePromo();
      }
    
      const renderFormCreate = () => {
        return (
          <form name="sellerCreateForm" className="m-2">
            <div style={{color: "#555555"}} className="mb-2">Fields with (<b style={{color: "red"}}>*</b>) sign are required!</div>
    
            <div className="form-group">
              <label htmlFor="categoryProductId" className="label-input">Product category: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.categoryProductId?.valid ? <span className="validation-error">{stateErrorForm?.categoryProductId?.error}</span> : <></> }
              <select className="form-control select" name="categoryProductId" id="categoryProductId" onChange={handleCSChange} defaultValue={stateProduct.categoryProductId || ''}>
                  <option value="">Select a category...</option>
                  { renderCategories() }
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="codeID" className="label-input">Code ID: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.codeID?.valid ? <span className="validation-error">{stateErrorForm?.codeID?.error}</span> : <></> }
              <input className="form-control" type="text" name="codeID" id="codeID" value={stateProduct.codeID || ''}
                     onChange={handleCSChange} required/>
            </div>
    
            <div className="form-group">
              <label htmlFor="designation" className="label-input">Designation: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.designation?.valid ? <span className="validation-error">{stateErrorForm?.designation?.error}</span> : <></> }
              <input className="form-control" type="text" name="designation" id="designation" value={stateProduct.designation || ''}
                     onChange={handleCSChange} required/>
            </div>

            <div className="form-group">
              <label htmlFor="conditionnement" className="label-input">Conditionnement: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.conditionnement?.valid ? <span className="validation-error">{stateErrorForm?.conditionnement?.error}</span> : <></> }
              <input className="form-control" type="text" name="conditionnement" id="conditionnement" value={stateProduct.conditionnement || ''}
                     onChange={handleCSChange} required/>
            </div>
    
            { !isEdit ? <div className="form-group">
              <label htmlFor="initialStock" className="label-input">Initial stock: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.initialStock?.valid ? <span className="validation-error">{stateErrorForm?.initialStock?.error}</span> : <></> }
              <input className="form-control" type="number" name="initialStock" id="initialStock" value={stateProduct.initialStock || ''}
                     onChange={handleCSChange} required/>
            </div> : <></>}

            <div className="form-group">
              <label htmlFor="unitPrice" className="label-input">Unit price: <b style={{color: "red"}}>*</b> </label>
              {!stateErrorForm?.unitPrice?.valid ? <span className="validation-error">{stateErrorForm?.unitPrice?.error}</span> : <></> }
              <input className="form-control" type="number" name="unitPrice" id="unitPrice" value={stateProduct.unitPrice || ''}
                     onChange={handleUnitPrice} required/>
            </div>

            <div className="form-group">
              <label htmlFor="discount" className="label-input">Discount (%): </label>
              <input className="form-control" type="number" name="discount" id="discount" value={stateProduct.discount || ""}
                     onChange={handleDiscount}/>
            </div>

            <div className="form-group">
              <label htmlFor="startPromoDate" className="label-input">Start promotion date: </label>
              <input className="form-control" type="date" name="startPromoDate" id="startPromoDate" value={stateProduct.startPromoDate || ''}
                onChange={handleCSChangeDate}/>
            </div>

            <div className="form-group">
              <label htmlFor="endPromoDate" className="label-input">End promotion date: </label>
              <input className="form-control" type="date" name="endPromoDate" id="endPromoDate" value={stateProduct.endPromoDate || ''}
                onChange={handleCSChangeDate}/>
            </div>

            <div className="form-group">
              <label htmlFor="promo" className="label-input">Promotion price: </label>
              <input className="form-control" type="number" name="promo" id="promo" value={stateProduct.promo || ""} disabled/>
            </div>
    
            <div className="separator mb-2 mt-2"></div>
            <div className="form-group">
              <Button
                variant="primary"
                value={isEdit ? "Update" : "Submit"}
                className="m-0 form-control"
                loading={loadingForm}
                onClick={isEdit ? editProduct : createProduct}
              />
            </div>
          </form>
        )
      }

      const renderFormProductAdd = () => {
        return (
          <form name="productAddForm" className="m-2">
            <div className="item-group">
              <div className="item-left">Code ID</div>
              <div className="item-right">{props.selectedProduct.codeID}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Designation</div>
              <div className="item-right">{props.selectedProduct.designation}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Initial stock</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{numberFormat(props.selectedProduct.initialStock, 0, ".", " ")}</b> </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="stockIn" className="label-input">Stock: <b style={{color: "red"}}>*</b> </label>
              <input className="form-control" type="number" name="stockIn" id="stockIn" value={stockIn || 0}
                onChange={handleStockInChange} required/>
            </div>

            <div className="separator mb-2 mt-2"></div>

            <div className="form-group">
              <Button
                variant="primary"
                value={"Add in stock"}
                className="m-0 form-control"
                loading={loadingForm}
                onClick={addProductInStock}
              />
            </div>

          </form>
        )
      }
    
      const renderDetailProduct = () => {
        return (
          <div className="p-2">
            <div className="item-group">
              <div className="item-left">Code ID</div>
              <div className="item-right">{props.selectedProduct.codeID}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Designation</div>
              <div className="item-right">{props.selectedProduct.designation}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Conditionnement</div>
              <div
                className="item-right">{props.selectedProduct.conditionnement}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Initial stock</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{numberFormat(props.selectedProduct.initialStock, 0, ".", " ")}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Unit price</div>
              <div
                className="item-right"><b style={{color: "green"}}>{numberFormat(props.selectedProduct.unitPrice, 2, ".", " ")}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Discount</div>
              <div
                className="item-right"><b style={{color: "red"}}>{`-${numberFormat(props.selectedProduct.discount, 2, ".", " ")} %`}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Start promo date</div>
              <div className="item-right">{`${moment(props.selectedProduct.startPromoDate).format("YYYY-MM-DD")}`} </div>
            </div>
            <div className="item-group">
              <div className="item-left">End promo date</div>
              <div className="item-right">{`${moment(props.selectedProduct.endPromoDate).format("YYYY-MM-DD")}`} </div>
            </div>
            <div className="item-group">
              <div className="item-left">Promotion price</div>
              <div
                className="item-right"><b style={{color: "#1179AD"}}>{numberFormat(props.selectedProduct.promo, 2, ".", " ")}</b> </div>
            </div>
            <div className="item-group">
              <div className="item-left">Product category</div>
              <div className="item-right">{props.selectedProduct?.categoryProduct?.name}</div>
            </div>
            <h5 className="title-5 mt-3">Last Operation</h5>
           <div className="item-group">
              <div className="item-left">Operation type</div>
              <div className="item-right"> 
                <Label 
                    statement={props.selectedProduct?.operationInventories[0].operationType} 
                    icon={ props.selectedProduct?.operationInventories[0].operationType === "IN" ? GoSignIn : GoSignOut}
                    style={{width: "50px"}}
                /> 
              </div>
            </div>
            <div className="item-group">
              <div className="item-left">Stock In</div>
              <div className="item-right">{props.selectedProduct?.operationInventories[0].stockIn}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Stock Out</div>
              <div className="item-right">{props.selectedProduct?.operationInventories[0].stockout}</div>
            </div>
            <div className="item-group">
              <div className="item-left">Final stock</div>
              <div className="item-right"><b style={{color: "#1179AD"}}>{props.selectedProduct.operationInventories[0]?.finalStock}</b></div>
            </div>
            <div className="item-group">
              <div className="item-left">Operation Date</div>
              <div className="item-right" style={{color: "red"}} >{moment(props.selectedProduct?.operationInventories[0].createdAt).format("YYYY-MM-DD, HH : mm") }</div>
            </div>
            <div className="item-group">
              <button type="button" className="form-control btn btn-default mt-2" style={{color: "#ff0077", fontSize: "0.8rem"}} onClick={(e) => gotoHistory(e, +props.selectedProduct.id)} >
                <Label icon={FcOvertime} statement="View all operations" className="justify-content-center" />  
              </button>
            </div>
          </div>
        )
      }
    
      const renderRightModal = () => {
        switch (displayForm) {
          case "new" : {
            return (
              <ModalRight
                title={isEdit ? "Edit Product" : "New Product"}
                content={renderFormCreate()}
                isVisible={isRightVisible}
                onClose={() => setIsRightVisible(false)}
              />
            )
          }
          case "preview" : {
            return (
              <ModalRight
                title="Product detail"
                content={renderDetailProduct()}
                isVisible={isRightVisible}
                onClose={() => { setIsRightVisible(false); productTable.unSelectAll() }}
              />
            )
          }
          case "add" : {
            return (
              <ModalRight
                title={"Add Product in stock"}
                content={renderFormProductAdd()}
                isVisible={isRightVisible}
                onClose={() => setIsRightVisible(false)}
              />
            )
          }
          default :
            return <></>
        }
      }
    
      const displayCreateProductForm = (e) => {
        e.preventDefault();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_INVENTORY_PRODUCT)) {
          setIsRightVisible(true);
          setIsEdit(false);
          setStateProduct({});
          setDisplayForm("new");
        }
      }
    
      const deleteProduct = async (e, id) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_INVENTORY_PRODUCT)) {
          const toastrConfirmOptions = {
            onOk: () => delProduct(id),
            onCancel: () => {
            }
          };
          toastr.confirm("Do you really want to delete this sellers ?", toastrConfirmOptions);
        }
      }
    
      const delProduct = async (id) => {
        setLoading(true);
        try {
          const res = await _deleteProduct(id);
          if (res.status === 200) {
            setLoading(false);
            props.deleteProduct(id);
          } else {
            setLoading(false);
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoading(false);
        }
      }
    
      const displayEditProductForm = (e, item) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_INVENTORY_PRODUCT)) {
          // const {id, ...customer} = item
          setIsEdit(true);
          setStateProduct(item);
          setIsRightVisible(true);
          setDisplayForm("new");
        }
      }

      const displayAddProductForm = (e, item) => {
        e.stopPropagation();
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.INVENTORY_PRODUCT_IN)) {
          setStateProduct(item);
          props.selectProduct(item);
          setIsRightVisible(true);
          setDisplayForm("add");
        }
      }
    
      const selectElement = (item) => {
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT)) {
          props.selectProduct(item?.originalData);
          setIsRightVisible(true);
          setDisplayForm("preview");
        }
      }
    
      const renderAction = (item) => {
        return (
          <div>
            {
              _checkAccessOrRedirect(FUNCTIONALITIES_CODE.INVENTORY_PRODUCT_IN) ?
              <button className="btn btn-action" onClick={(e) => displayAddProductForm(e, item)}  title='Add Product stock'>
                  <IoAddCircleOutline color="blue"/>
              </button> :
              <></>
            }
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT) ?
            <button className="btn btn-action" onClick={(e) => gotoHistory(e, +item?.id)}  title='Product history'>
                <FcOvertime/>
            </button> :
            <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_INVENTORY_PRODUCT) ?
              <button className="btn btn-action" onClick={(e) => displayEditProductForm(e, item)}>
                <IoPencil color="blue"/>
              </button> :
              <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_INVENTORY_PRODUCT) ?
              <button className="btn btn-action" onClick={(e) => deleteProduct(e, item.id)}><IoTrash/></button> :
              <></>}
          </div>
        )
      }
    
      const headerData = [
        {
          title: <div className="size-30 flex-center  rounded"> # </div>,
          align: "center",
          className: '',
          width: 50,
          name: "number"
        },
        {title: "Code ID", align: "left", className: 'pl-15 sm-hide', width: "auto", name: "codeID"},
        {title: "Designation", align: "left", className: 'pl-15', width: "auto", name: "designation"},
        {title: "Conditionnement", align: "left", className: 'pl-15 md-hide', width: "auto", name: "conditionnement"},
        {title: "Initial Stock", align: "left", className: 'pl-15', width: "50px", name: "initialStock"},
        {title: "Unit Price", align: "right", className: 'pr-2', width: "auto", name: "unitPrice"},
        {title: "Discount", align: "center", className: '', width: "50px", name: "discount"},
        {title: "Promo price", align: "right", className: 'pr-2', width: "auto", name: "promo"},
        {title: "Promo period", align: "left", className: 'pr-2', width: "auto", name: "promoPeriod"},
        {title: <> Actions </>, align: "center", className: '', width: "150px", name: "action"},
      ];
    
      const formatBodyData = (data) => {
        return data?.map((item, i) => {
          let resData = {
            originalData: item,
            number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
            codeID: item.codeID,
            designation: item.designation,
            conditionnement: item.conditionnement,
            initialStock: <b>{numberFormat(item.initialStock, 0, ".", " ")}</b> ,
            unitPrice: <b style={{color: "green"}}>{`${numberFormat(item.unitPrice, 2, ".", " ")} XAF`}</b>,
            discount: +item.discount > 0 ? <b style={{color: "red"}}>{`-${numberFormat(item.discount, 2, ".", " ")} %`}</b> : "",
            promo: item.promo > 0 ? <b style={{color: "#1179AD"}}>{`${numberFormat(item.promo, 2, ".", " ")} XAF`}</b> : "",
            promoPeriod: (item.startPromoDate && item.endPromoDate )? <><b style={{color: "#999999"}}>{`${moment(item.startPromoDate).format("YYYY-MM-DD")}`}</b> - <b style={{color: "#666666"}}>{`${moment(item.endPromoDate).format("YYYY-MM-DD")}`}</b></> : "",
            action: renderAction(item),
          }
          return resData;
        })
      }
    
      const setPage = async (page) => {
        if (stateData.currentPage !== page) {
          await getFilterProducts("", stateData.pageSize, page);
        }
      }
    
      const setSize = async (pageSize) => {
        await getFilterProducts("", pageSize, 1);
      }
    
      const renderPaginarion = () => {
        if (stateData.totalPages > 1) {
          let items = [];
          for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
                className="pointer">
                {number}
              </Pagination.Item>,
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <Pagination>
                <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.startPage)}/>
                <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage - 1)}/>
                {items}
                <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(stateData.currentPage + 1)}/>
                <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
                  onClick={() => setPage(+stateData.endPage)}/>
              </Pagination>
              <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
            </div>
          )
        } else {
          return <></>
        }
      }
    
      const handleFilterChange = async (e) => {
        setFilter(e.target.value);
        await getFilterProducts(e.target.value);
      }


    return(
        <PageLayout
            title={PAGE.INVENTORY}
            rightElement={null}
            isRightVisible={false}
        >
      <>
        <div className="product-list-view">
            <div className="page-action">
                <div className="d-flex aligns-items-center">
                    <BtnAdd
                        statement="New product"
                        onClick={(e) => displayCreateProductForm(e)}
                        disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_INVENTORY_PRODUCT)}
                        className="mr-2"
                        style={{marginRight: 5}}
                    />
                    <Loader loading={false}/>
                </div>
                <div className="search-content-form">
                    <Input
                        type="text"
                        globalClassName="search-input"
                        name="filter"
                        value={filter}
                        placeholder="Enter a keyword..."
                        onChange={(e) => handleFilterChange(e)}
                    />
                </div>
            </div>

            <Suspense fallback={<Loader loading/>}>
                <Table
                header={headerData}
                body={formatBodyData(props.products)}
                className=''
                hoverColor="rgba(0, 0, 0, 0.05)"
                isLoading={loadingData}
                asLoader={<Loader loading/>}
                onSelect={(item) => selectElement(item)}
                selectedClass="active"
                onRef={component => getTableNode(component)}
                />
                {renderPaginarion()}
            </Suspense>
        </div>
        {renderRightModal()}
    </>
    </PageLayout>
    )
}


const mapStateToProps = (state) => {
    const {inventory, categoryProducts, auth} = state;
  
    return {
      products: inventory.products, 
      selectedProduct: inventory.selectedProduct,
      categories : categoryProducts.categories,
      shop : auth?.authData?.shop,
    };
  }
  
  export default connect(mapStateToProps, {
    addProduct,
    setProducts,
    updateProduct,
    deleteProduct,
    selectProduct,
    setCategories,
    addProductQuantity,
    removeProductQuantity,
  })(Product);
