import React, {useEffect} from 'react';
import {connect} from "react-redux";
import PageLayout from "../../../layouts/defaultContentPage";
import {PAGE} from "../../../constants/constants";
import { /* NavLink,  useLocation, */ Outlet, useNavigate } from "react-router-dom";
import './customers.css';

const CustomersPage = (props) => {
  /* let {pathname} = useLocation();
  let [links, setLinks] = useState([]); */
  let navigate = useNavigate();
  
  /* useEffect(() => {
    let part = pathname.split("/", 3);
    let link = items.find(item => (item.path.split("/",1))[0] === part[2]);
    setLinks(link?.children);
  },[pathname, links, items]); */

  useEffect(() => {
      navigate("all");
      // eslint-disable-next-line
  },[]);

  /* const renderSubMenu = () => {
    return (
        <ul className="subMenu">
            {
                links?.map((link, i) => {
                    return (
                        <li key={i}>
                            <NavLink 
                                to={link.path}
                                className={({ isActive }) => isActive ? "sub-menu active" : "sub-menu"}
                            >
                                <Label icon={link.icon} statement={link.name} />
                            </NavLink>
                        </li>
                    )
                }) 
            }
        </ul>
    )
  } */

  return (
    <PageLayout
      title={PAGE.CUSTOMERS}
      rightElement={null}
      isRightVisible={false}
    >
      <Outlet />
    </PageLayout>
  );
};

const mapStateToProps = (state) => {
  const {customers} = state;

  return {customers: customers.customers, selectedCustomer: customers.selectedCustomer};
}

export default connect(mapStateToProps, { })(CustomersPage);
