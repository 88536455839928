import axios from '../config/axios';
import { FUNCTIONALITIES_CODE } from "../constants/AuthConstants";

export const _getProducts = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT } };
    return await axios.get(`inventories/products?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getProductById = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`inventories/products/${+id}`, config);
}

export const _createProduct = async (data) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.CREATE_INVENTORY_PRODUCT } };
    return await axios.post(`inventories`, data, config);
}

export const _addProductQuantity = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.INVENTORY_PRODUCT_IN } };
    return await axios.post(`inventories/${+id}/add`, data, config);
}

export const _removeProductQuantity = async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.INVENTORY_PRODUCT_IN } };
    return await axios.post(`inventories/${+id}/remove`, data, config);
}

export const _updateProduct= async (data, id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.UPDATE_INVENTORY_PRODUCT } };
    return await axios.put(`inventories/${+id}`, data, config);
}

export const _deleteProduct = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.DELETE_INVENTORY_PRODUCT } };
    return await axios.delete(`inventories/${+id}`, config);
}

export const _getOperations = async (filter, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_OPERATIONS } };
    return await axios.get(`inventories/operations?filter=${filter}&pageSize=${pageSize}&page=${page}`, config);
}

export const _getOperationById = async (id) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.LIST_OPERATIONS } };
    return await axios.get(`inventories/${+id}`, config);
}

export const _getOperationsByProductId = async (id, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`inventories/products/${+id}/operations?pageSize=${pageSize}&page=${page}`, config);
}

export const _getOperationsByCustomerId = async (id, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`inventories/customers/${+id}/operations?pageSize=${pageSize}&page=${page}`, config);
}

export const _getOperationsBySellerId = async (id, pageSize, page) => {
    const config = { headers: { 'permission': FUNCTIONALITIES_CODE.PREVIEW_INVENTORY_PRODUCT } };
    return await axios.get(`inventories/sellers/${+id}/operations?pageSize=${pageSize}&page=${page}`, config);
}
