export const SET_ORDER_OPERATIONS = 'SET_ORDER_OPERATIONS';
export const ADD_ORDER_OPERATION = 'ADD_ORDER_OPERATION';
export const ADD_ORDER_OPERATIONS = 'ADD_ORDER_OPERATIONS';
export const UPDATE_ORDER_OPERATION = 'UPDATE_ORDER_OPERATION';
export const DELETE_ORDER_OPERATION = 'DELETE_ORDER_OPERATION';
export const SELECT_ORDER_OPERATION = 'SELECT_ORDER_OPERATION';

export const SET_ORDER_OPERATION_LINES = 'SET_ORDER_OPERATION_LINES';
export const ADD_ORDER_OPERATION_LINE = 'ADD_ORDER_OPERATION_LINE';
export const UPDATE_ORDER_OPERATION_LINE = 'UPDATE_ORDER_OPERATION_LINE';
export const DELETE_ORDER_OPERATION_LINES = 'DELETE_ORDER_OPERATION_LINES';
export const SELECT_ORDER_OPERATION_LINE = 'SELECT_ORDER_OPERATION_LINE';