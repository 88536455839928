import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import { Modal, Container, Row, Col, InputGroup, DropdownButton, Dropdown, FormControl, Button, Table, } from "react-bootstrap";
import { getSellers } from "../../../services/Sellers";
import {_checkAccessOrRedirect} from "../../../services/Auth";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";
import Label from "../../../Components/label";
import { setCustomers } from "../../../redux/action/CustomersAction";
import { setSellers, } from "../../../redux/action/SellersAction";
import { addOrderOperation, selectOrderOperation } from "../../../redux/action/ordderAction";
import {  _getProducts, } from "../../../services/Inventory";
import {  _getServices, } from "../../../services/services";
import { _createOrderOperation, } from "../../../services/order";
import { 
    FcManager,
    FcCheckmark,
    FcCurrencyExchange,
    FcTodoList,
    FcLeft,
} from "react-icons/fc";
import { IoLogoFacebook, IoLogoInstagram, IoGlobe, } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import PageLayout from "../../../layouts/defaultContentPage";
import {PAGE, SEARCH_KEY_TYPE, PAYMENT_MODE, SERVICE} from "../../../constants/constants";
import { numberFormat } from "../../../helpers/Number";
import Storage from "../../../helpers/Storage";
import { SESSION_STORAGE_KEYS } from "../../../constants/constants";

import "./order.css";

function OrderView (props) {
    // eslint-disable-next-line
    const [loadindData, setLoadingData] = useState(false);
    const [searchKey, setSearchKey] = useState(SEARCH_KEY_TYPE.PRODUCT);
    const [filter, setFilter] = useState('');
    const [seller, setSeller] = useState('');
    const [products, setProducts] = useState([]);
    // eslint-disable-next-line
    const [loadindProduct, setLoadindProduct] = useState(false);
    const [services, setServices] = useState([]);
    // eslint-disable-next-line
    const [loadindService, setLoadingService] = useState(false);
    const [loadindSeller, setLoadingSeller] = useState(false);
    const [filterSeller, setFilterSeller] = useState('');
    let [quantity, setQuantity] = useState(0);
    let [suggestions, setSuggestions] = useState([]);
    let [selectedProductService, setSelectedProductService] = useState({});
    const [paymentMode, setPaymentMode] = useState(PAYMENT_MODE.CASH);
    let [operationLine, setOperationLine] = useState({});
    let [orderOperationLines, setOrderOperationLines] = useState([]);
    let [totalTTC, setTotalTTC] = useState(0);
    let [discount, setDiscount] = useState(0);
    const [processInterval, setProcessInterval] = useState(true);
    // eslint-disable-next-line
    let [ticketNumber, setTicketNumber] = useState("");
    const [rendezvous, setRendezvous] = useState(null);
    let [showModal, setShowModal] = useState(false);

    const handleKeyType = (eventKey) => {
        setSearchKey(eventKey)
        setSuggestions([]);
        setFilter('');
        setDiscount(0);
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
            if(searchKey === SEARCH_KEY_TYPE.PRODUCT){
                const items = products.filter(elm => elm.designation.toLowerCase().startsWith(e.target.value) || elm.codeID.toLowerCase().startsWith(e.target.value));
                setSuggestions(items);
            }else{
                const items = services.filter(elm => elm.name.toLowerCase().startsWith(e.target.value) || elm.codeID.toLowerCase().startsWith(e.target.value));
                setSuggestions(items);
            }
    }

    const handleFilterFocus = (e) => {
        if(searchKey === SEARCH_KEY_TYPE.PRODUCT){
            setSuggestions(products);
        }else{
            setSuggestions(services);
        }
    }

    /* const handleFilterFocusOut = (e) => {
        setTimeout( () => {setSuggestions([])}, 1000);
    } */

    const handleChangeSeller = (eventKey) => {
        setSeller(JSON.parse(eventKey));
    }

    const handleChangePaymentMode = (eventKey) => {
        setPaymentMode(eventKey);
    }

    const handleQuantityChange = (e) => {
        let qty = parseInt(+e.target.value, 10);
        qty = qty < 0 ? 0 : qty;
        setQuantity(qty);
    }

    const handleQuantity = (qty) => {
        quantity = quantity+""+qty;
        let qte = parseInt(quantity, 10);
        qte = qte < 0 ? 0 : qte;
        setQuantity(qte);
    }

    const handleRemoveBackNumber = (e) => {
        e.stopPropagation();
        let part = quantity.toString().split("");
        part.pop();
        let qte = part.length < 1 ? 0 : parseInt(part.join(""), 10);
        qte = qte < 0 ? 0 : qte;
        setQuantity(qte);
    }

    const clearScreen = (e) => {
        e.stopPropagation();
        setQuantity(0);
    }

    // get sellers
    useEffect(() => {
        (async (filter = "", pageSize = 1000, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_EMPLOYEE)) {
            setLoadingSeller(true);
            try {
              const res = await getSellers(filter, pageSize, page);
              if (res.status === 200) {
                setLoadingSeller(false);
                props.setSellers(res.data.rows);
              } else {
                setLoadingSeller(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e, {timeOut: 5000});
              setLoadingSeller(false);
            }
          }else{
            toastr.error('Erreur', "You do not have permission for this action!", {timeOut: 5000});
          }
        })()
        // eslint-disable-next-line
      }, []);

      // get products
      useEffect(() => {
        (async (filter = "", pageSize = 10000, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT)) {
            setLoadindProduct(true);
            try {
              const res = await _getProducts(filter, pageSize, page);
              if (res.status === 200) {
                setLoadindProduct(false);
                setProducts(res.data.rows);
              } else {
                setLoadindProduct(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e, {timeOut: 5000});
              setLoadindProduct(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, []);

      // get services
      useEffect(() => {
        (async (filter = "", pageSize = 100000, page = 1) => {
          // Check permission on action
          if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_SERVICE)) {
            setLoadingService(true);
            try {
              const res = await _getServices(filter, pageSize, page);
              if (res.status === 200) {
                setLoadingService(false);
                setServices(res.data.rows);
              } else {
                setLoadingService(false);
                toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
              }
            } catch (e) {
              console.error("Erreur: ", e);
              toastr.error('Erreur', e, {timeOut: 5000});
              setLoadingService(false);
            }
          }
        })()
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        setProducts(props.products);
        setServices(props.services);
         // eslint-disable-next-line
      }, [])

      useEffect(() => {
        // calculate tip
        const tip = calculateTip(selectedProductService);

        // calculate Total price
        const totalPrice = calculateTotalPrice(selectedProductService);

        setOperationLine( Object.assign(operationLine, { quantity, tip, totalPrice }) );
        // eslint-disable-next-line
      }, [quantity, selectedProductService, operationLine])

    const renderSellers = () => {
        if(filterSeller.length > 1) {
            return props.sellers.filter(elm => elm.name.toLowerCase().startsWith(filterSeller))
                .map((item, i) => {
                    return (
                        <Dropdown.Item key={i} href="#" eventKey={JSON.stringify(item)} >{item.name}</Dropdown.Item>
                    )
                });
        }
        return props.sellers.map((item, i) => {
            return (
                <Dropdown.Item key={i} href="#" eventKey={JSON.stringify(item)} >{item.name}</Dropdown.Item>
            )
        });
    }

    const calculateQtyUp = (data) => {
        let unitPrice = 0;
        const promo = calculatePromo(data);
        if (searchKey === SEARCH_KEY_TYPE.SERVICE) {
            if (checkPromo(data)) {
                unitPrice = +promo;
            } else {
                unitPrice = +data?.amount;
            }
        }else{
            if (checkPromo(data)) {
                unitPrice = +promo;
            } else {
                unitPrice = +data?.unitPrice;
            }
        }
        return quantity * unitPrice;
    }

    const calculateTip = (data) => {
        if (searchKey === SEARCH_KEY_TYPE.SERVICE) {
            if(seller.id){
                let qtyPu = calculateQtyUp(data);
                return ( qtyPu * SERVICE.TIP_RATE ) / 100;
            }else{
                return null;
            }
        }else{
            return null;
        }
    }

    const applyPercentage = (value, percent) => {
        return value - ((value * percent) / 100);
    }

    const calculateTotalPrice = (data) => {
        let qtyPu = calculateQtyUp(data);
        if(searchKey === SEARCH_KEY_TYPE.SERVICE){
            const tip =  calculateTip(data);
            qtyPu = applyPercentage(qtyPu, discount);
            return qtyPu + tip;
        }else{
            return qtyPu;
        }
    }

    const calculateTotalTTC = (orderOperationLines = []) => {
        let ttc = 0;
        orderOperationLines.forEach(elm => {
            ttc += elm.totalPrice;
        });
        setTotalTTC(ttc);
    }

    const selectSuggestion = (data) => {
        setSelectedProductService(data);
        const filterValue = `(${data.codeID}) ${searchKey === SEARCH_KEY_TYPE.PRODUCT ? data.designation : data.name}`;
        setFilter(filterValue);
        const promo = calculatePromo(data);
        const opLine = {
            codeID: data.codeID,
            designation: searchKey === SEARCH_KEY_TYPE.PRODUCT ? data.designation : data.name,
            unitPrice: searchKey === SEARCH_KEY_TYPE.PRODUCT ? (+promo > 0 ? +promo : data.unitPrice) : (+promo > 0 ? +promo : data.amount),
            price: searchKey === SEARCH_KEY_TYPE.PRODUCT ? data.unitPrice : data.amount,
            promo: +promo > 0 ? +promo : 0,
            quantity: quantity,
            tip: calculateTip(data),
            tipRate: searchKey === SEARCH_KEY_TYPE.SERVICE ? SERVICE.TIP_RATE : null,
            discount: discount,
            totalPrice: calculateTotalPrice(data),
            inventoryProductId: searchKey === SEARCH_KEY_TYPE.PRODUCT ? data.id : null,
            serviceId: searchKey === SEARCH_KEY_TYPE.SERVICE ? data.id : null,
        };
        setOperationLine({...operationLine, ...opLine});
        // setOrderOperationLines([ ...orderOperationLines, {...operationLine}]);
        setSuggestions([]);
        setShowModal(false);
    }

    const checkProductQty = () => {
        if(searchKey === SEARCH_KEY_TYPE.PRODUCT){
            return +selectedProductService.initialStock >= +quantity;
        }else{
            return true;
        }
    }

    const checkOrderOperationLine = (opLine) => {
        return orderOperationLines.findIndex( item => item.codeID ===  opLine.codeID && item.designation === opLine.designation);
    }

    const addOrderOperationLine = (e) => {
        e.stopPropagation();
        console.log({operationLine});
        // check quantity product
        if(checkProductQty()){
            if(operationLine && quantity > 0){
                const i = checkOrderOperationLine(operationLine);
                if( i >= 0 ){
                    orderOperationLines[i] = operationLine;
                    setOrderOperationLines([ ...orderOperationLines ]);
                    calculateTotalTTC([ ...orderOperationLines ]);
                    clearOperationLine();
                }else{
                    setOrderOperationLines([ ...orderOperationLines, {...operationLine}]);
                    calculateTotalTTC([ ...orderOperationLines, {...operationLine}]);
                    clearOperationLine();
                }
                setDiscount(0);
            }else{
                toastr.error('Error', "Please, set the product or quantity", {timeOut: 3000});
            }
        }else{
            toastr.error('Error', "The quantity you specify is more than what it is un stock ( "+selectedProductService.initialStock+" in stock )", {timeOut: 5000});
        }
    }

    const clearOperationLine = () => {
        setFilter("");
        setQuantity(0);
        setOperationLine({});
        setSelectedProductService({});
    }

    const resetForm = () => {
        clearOperationLine();
        setTotalTTC(0);
        setOrderOperationLines([]);
    }

    const deleteOperationLine = (e, i) => {
        e.stopPropagation();
        orderOperationLines.splice(i, 1);
        setOrderOperationLines([ ...orderOperationLines]);
        calculateTotalTTC([ ...orderOperationLines ]);
    }

    const checkPromo = (prodServ) => {
        if(prodServ?.startPromoDate && prodServ?.endPromoDate){
            const today = moment(new Date());
            return today.diff(moment(prodServ?.startPromoDate)) >= 0 && moment(prodServ?.endPromoDate).diff(today) >= 0;
        }else{
            return false;
        }
    }

    const calculatePromo = (prodServ) => {
        if(checkPromo(prodServ)){
            return (+prodServ?.promo > 0) ? +prodServ?.promo : 0;
        }else{
            return 0;
        }
    }

    const renderSuggestions = () => {
        if(suggestions.length){
            return suggestions.map((item, i) => {
                return (
                    <div key={i} className="suggestion-item" onClick={() => selectSuggestion(item)}> 
                        <span className="statement">( <b>{item.codeID}</b> ) { searchKey === SEARCH_KEY_TYPE.PRODUCT ? item.designation : item.name} </span> 
                        <div>
                            {   (checkPromo(item) && +item.promo > 0) ? 
                                <span className="promo"> 
                                    <sup>XAF</sup> 
                                    <span> { numberFormat(item.promo, 2, ".", " ") } </span>
                                </span> : <></>
                            }
                            <span className={`price ${(checkPromo(item) && +item.promo > 0) ? "line-through text-red" : ""}`}> 
                                <sup className="mr-1">XAF</sup> 
                                    { 
                                        searchKey === SEARCH_KEY_TYPE.PRODUCT ? 
                                            numberFormat(item.unitPrice, 2, ".", " ") :  
                                            numberFormat(item.amount, 2, ".", " ") 
                                    }
                            </span> 
                        </div>
                    </div>
                )
            })
        }
        return <></>
    }

    const renderTabTicket = () => {
        return (
            <div style={{width: "100%"}} id="ticket" className="mt-2">
                <Table striped bordered hover style={{fontSize: "0.7rem"}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>CD</th>
                            <th>QTY</th>
                            <th>NAME</th>
                            <th>U.P</th>
                            <th>TIP</th>
                            <th>DISC</th>
                            <th align="right">T.P</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            orderOperationLines.length ? orderOperationLines.map((item, i) => {
                                return <tr key={i} className="cursor-pointer">
                                    <td className="p-0" align="center"> 
                                        <button type="button" className="btn p-0" style={{width: 30, height: 30, border: "1px solid rgba(0, 0, 0, 0.3)", marginTop: 1, }} onClick={(e) => deleteOperationLine(e, i)}>
                                            <IoIosClose />
                                        </button> 
                                    </td>
                                    <td> {item.codeID} </td>
                                    <td> {numberFormat(item.quantity, 2, ".", " ")} </td>
                                    <td> {item.designation} </td>
                                    <td> {numberFormat(item.unitPrice, 2, ".", " ")} </td>
                                    <td> { item.tip > 0 ? numberFormat(item.tip, 2, ".", " ") : "-"} </td>
                                    <td> { item.discount ? "-"+numberFormat(item.discount, 2, ".", " ")+"%" : "-"} </td>
                                    <td align="right"> <b>{numberFormat(item.totalPrice, 2, ".", " ")}</b> </td>
                                </tr>
                            }) : <tr>
                                <td colSpan={8} align="center"> <span>No items</span> </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <div className="social-networks hidden" id="socialnetworks" style={{marginLeft: 50 }}>
                    <div className="social-item"> <IoLogoFacebook /> <span>makaylafashion</span> </div> 
                    <div className="social-item">  <IoLogoInstagram /> <span>makaylafashion.mf</span> </div> 
                    <div className="social-item"> <IoGlobe /> <span>www.makaylafashion.com</span> </div> 
                </div>
            </div>
        )
    }

    const generateTicketNumber = () => {
        return moment(new Date()).format("YYYYMMDDHms");
    }

    const saveOrderOperationToStorage = (payload) => {
        // test if key exist
        const orderOperations = Storage.Get(SESSION_STORAGE_KEYS.ORDER_OPERATIONS);
        if(orderOperations){
            orderOperations.push(payload);
            Storage.Set(SESSION_STORAGE_KEYS.ORDER_OPERATIONS, orderOperations);
        }else{
            Storage.Set(SESSION_STORAGE_KEYS.ORDER_OPERATIONS, [payload]);
        }
    }

    const createOrderOperation = async (e) => {
        e.stopPropagation();
        if(!orderOperationLines.length){
            toastr.error('Error', "Please, add at least one record!", {timeOut: 3000});
            return;
        }
        if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_CUSTOMER_ORDER)) {
            const ticketNum = generateTicketNumber();
            setTicketNumber(ticketNum);
            orderOperationLines = orderOperationLines.map(item => {
                return Object.assign(item, {sellerId: seller.id});
            })
            const payload = {
                ticketNumber: ticketNum,
                paymentMode: paymentMode,
                totalHT: totalTTC,
                vatRate: 0,
                vat: 0,
                totalTTC: totalTTC,
                customerId: +props.currentUser?.customer?.id,
                sellerId: seller ? seller.id : null,
                userId: props.currentUser.id,
                orderOperationLines : orderOperationLines,
                rendezvousAt : rendezvous,
            };
            props.addOrderOperation(payload);
            props.selectOrderOperation(payload);
            try {
                const res = await _createOrderOperation(payload);
                if (res.status === 200) {
                  setLoadingData(false);
                  resetForm();
                  props.addOrderOperation(res.data);
                  props.selectOrderOperation(res.data);
                  
                  toastr.success('Success', "Order operation succeded!", {timeOut: 3000});
                } else {
                    setLoadingData(false);
                    // save order operation in local storage
                    toastr.error('Error', res.errorMessage, {timeOut: 3000});
                }
            } catch (e) {
                if(e.message === "Network Error"){ 
                    saveOrderOperationToStorage(payload);
                    toastr.info('NOTE', "Data saved locally!", {timeOut: 5000});
                    resetForm();
                    setProcessInterval(true);
                }else{
                    toastr.error('Error', e.message, {timeOut: 5000});
                }
                setLoadingData(false);
            }
        }else{
            toastr.error('Error', "You do not have permission for this action", {timeOut: 3000});
        }
    }

    // Process after offline
    const createOrderOperations = async (intervalId) => {
        const orderOperations = Storage.Get(SESSION_STORAGE_KEYS.ORDER_OPERATIONS);
        if(orderOperations){
            try {
                const result = await Promise.all(
                    orderOperations.map( async (item) => {
                        const res = await _createOrderOperation(item);
                        if (res.status === 200) {
                            props.addOrderOperation(res.data);
                            props.selectOrderOperation(res.data);
                        }
                    })
                );
                if(result){
                    toastr.info('NOTE', "Order operation saved!", {timeOut: 1000});
                    Storage.Clear(SESSION_STORAGE_KEYS.ORDER_OPERATIONS);
                    clearInterval(intervalId);
                    setProcessInterval(false);
                }
            } catch (e) {
                if(e.message !== "Network Error"){ 
                    toastr.error('Error', e.message, {timeOut: 5000});
                }
            }
        }
    }

    useEffect(() => {
        if(processInterval){
            const intervalId = setInterval(() => {
                createOrderOperations(intervalId);
            }, 1000 * 60);
        }
        // eslint-disable-next-line
    },[]);

    return (
        <PageLayout
            title={PAGE.ORDER_OPERATIONS}
            isRightVisible={false}
            rightElement={renderTabTicket()}
            rightElementClassName="sm-hide"
        >
            <div className="order-view sm-hiden">
                {/* left side */}
                <Row>
                    <Col md={6} sm={12}>
                        <InputGroup className="mb-1 mt-1 p-1" size="sm">
                            <DropdownButton 
                                variant="outline-secondary" 
                                id="dropdown-seller" 
                                title={ loadindSeller ? "Waiting for employee" : <Label icon={FcManager} statement={`${ seller ? "Employee : " + seller.name : 'Select an employee'}`} /> }
                                onSelect={handleChangeSeller}
                                style={{backgroundColor: "#FFFFFF"}}
                                className="form-control"
                            >
                                <FormControl
                                    autoFocus
                                    className="mx-3 my-2 w-auto"
                                    placeholder="Type to filter..."
                                    onChange={(e) => setFilterSeller(e.target.value)}
                                    value={filterSeller}
                                />
                                { loadindSeller ? "Waiting for employee" : renderSellers() }
                            </DropdownButton>
                        </InputGroup>

                        <InputGroup className="mb-1 mt-3" size="lg" style={{backgroundColor: "#FFFFFF"}}>
                            {/* search input */}
                            <DropdownButton
                                variant="outline-secondary"
                                title={`${ searchKey ? searchKey.toLocaleLowerCase() : "Select item..."}`}
                                size="lg"
                                id="input-group-dropdown-1"
                                onSelect={handleKeyType}
                            >
                                <Dropdown.Item href="#" eventKey={`${SEARCH_KEY_TYPE.PRODUCT}`} >{SEARCH_KEY_TYPE.PRODUCT.toLocaleLowerCase()}</Dropdown.Item>
                                <Dropdown.Item href="#" eventKey={`${SEARCH_KEY_TYPE.SERVICE}`} >{SEARCH_KEY_TYPE.SERVICE.toLocaleLowerCase()}</Dropdown.Item>
                            </DropdownButton>
                            <div className="form-control">
                                {/* <FormControl 
                                    aria-label="Text input with dropdown button" 
                                    placeholder="Focus and find an items here..." 
                                    value={filter} 
                                    onChange={handleFilterChange} 
                                    onFocus={handleFilterFocus} 
                                    onBlur={handleFilterFocusOut} 
                                /> */}
                                <Button 
                                    className="btn btn-warning form-control text-ellipsis"
                                    onClick={(e) => { handleFilterFocus(e); setShowModal(true)}}
                                >
                                    {filter ? filter : " Click here to select item"}
                                </Button>
                                { /* suggestions.length ? 
                                    <div className="suggestion-list">
                                        {renderSuggestions()}
                                    </div> : 
                                    <></>
                                 */}
                            </div>
                            {/* quantity input */}
                            <InputGroup.Text id="inputGroup-quantity-lg">quantity</InputGroup.Text>
                            <div>
                                <FormControl 
                                    aria-label="Large" 
                                    aria-describedby="inputGroup-sizing-sm" 
                                    type="number" 
                                    name="quantity"
                                    style={{width: "75px", height: "100%"}}
                                    value={quantity} 
                                    onChange={handleQuantityChange}
                                />
                            </div>
                        </InputGroup>

                        {/* buttons quantity */}
                        <Row className="mb-1 mt-3 p-2" style={{backgroundColor: "#FFFFFF"}} id="btns">
                            <Col>
                                <table className="w-100 mb-1">
                                    <tr>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(1)}>1</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(2)}>2</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(3)}>3</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(0)}>0</Button></td>
                                    </tr>
                                    <tr>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(4)}>4</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(5)}>5</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(6)}>6</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={handleRemoveBackNumber}> <FcLeft /> </Button></td>
                                    </tr>
                                    <tr>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(7)}>7</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(8)}>8</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={() => handleQuantity(9)}>9</Button></td>
                                        <td><Button variant="outline-primary" className="btn-qty" onClick={clearScreen}>CLR</Button></td>
                                    </tr>
                                </table>
                            </Col>
                            <Col style={{backgroundColor: "#FFFFFF"}}>
                                <Row className="p-0">
                                    <Col>
                                        {/* payment mode */}
                                        <InputGroup className="mb-1  mt-0" size="sm">
                                            <Button variant="outline-primary" className="form-control p-2" onClick={addOrderOperationLine}>
                                                <Label icon={FcTodoList} statement={`Add to list`} /> 
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <div className="separator"></div>
                                <Row className="p-0">
                                    <Col>
                                        <InputGroup className="mb-1 mt-1" size="lg">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={ <Label icon={FcCurrencyExchange} statement={`${ paymentMode ? `Payment mode : ${paymentMode}` : "Select a payment mode..."}`} className="text-truncate" />}
                                                size="lg"
                                                id="input-group-payment-mode"
                                                onSelect={handleChangePaymentMode}
                                            >
                                                <Dropdown.Item href="#" eventKey={`${PAYMENT_MODE.CASH}`} >{PAYMENT_MODE.CASH.toLocaleLowerCase()}</Dropdown.Item>
                                                <Dropdown.Item href="#" eventKey={`${PAYMENT_MODE.ORANGE_MONEY}`} >{PAYMENT_MODE.ORANGE_MONEY.toLocaleLowerCase()}</Dropdown.Item>
                                                <Dropdown.Item href="#" eventKey={`${PAYMENT_MODE.MTN_MOMO}`} >{PAYMENT_MODE.MTN_MOMO.toLocaleLowerCase()}</Dropdown.Item>
                                            </DropdownButton>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-1" size="sm" style={{backgroundColor: "#FFFFFF"}}>
                                            {/* rendez-vous input */}
                                            <InputGroup.Text id="inputGroup-quantity-lg">Rendez-vous</InputGroup.Text>
                                            <div className="form-control">
                                                <FormControl 
                                                    aria-label="Large" 
                                                    aria-describedby="inputGroup-sizing-sm" 
                                                    type="datetime-local" 
                                                    name="rendezvousAt"
                                                    value={rendezvous} 
                                                    onChange={(e) => setRendezvous(e.target.value)}
                                                />
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="p-0">
                                    <Col>
                                        <InputGroup className="mb-1 mt-1" size="lg">
                                            <Button variant="outline-success" className="form-control" onClick={(e) => createOrderOperation(e)}>
                                                <Label icon={FcCheckmark} statement={`Validate`} className="text-truncate" /> 
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* buttons control quantity */}

                        <Row className="mt-3">
                            
                            <Col className="bg-price-block">
                                <h4 className="h4 text-white text-center mt-2"> Total Price </h4>
                                <div className="d-flex justify-content-center h-100">
                                    <h1 class="display-3 bold text-white"><sup>XAF</sup> <span>{numberFormat(totalTTC, 2, ".", " ")}</span> </h1>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* end left side */}

                    {/* right side */}
                    <Col md={6} sm={12}>
                        {renderTabTicket()}
                    </Col>
                    {/* end right side */}
                </Row>
                
                <Modal show={showModal} fullscreen onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100">
                            <FormControl 
                                aria-label="Text input with dropdown button" 
                                placeholder="Focus and find an items here..." 
                                value={filter} 
                                onChange={handleFilterChange} 
                                /* onFocus={handleFilterFocus} 
                                onBlur={handleFilterFocusOut}  */
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={12} className="modal-suggestion-list">
                                    { suggestions.length ? renderSuggestions() : <></> }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

            </div>
        </PageLayout>
    )
}

const mapStateToProps = (state) => {
    const { customers, services, sellers, inventory, order, auth} = state;
  
    return {
      customers: customers.customers, 
      sellers: sellers.sellers, 
      products: inventory.products, 
      services: services.services, 
      shop : auth?.authData?.shop,
      currentUser : auth.currentAuth,
      selectedOrderOperation: order.selectedOrderOperation,
    };
  }
  
  export default connect(mapStateToProps, {setCustomers, setSellers, addOrderOperation, selectOrderOperation })(OrderView);
