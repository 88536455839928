import React, {useEffect, useState, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import {_checkAccessOrRedirect} from "../../../services/Auth";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";
import Loader from "../../../Components/Loader";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import moment from "moment";
import Chart from 'react-apexcharts'
import {
    _getCountCustomers,
    _getCountSellers,
    _getCountProducts,
    _getCountAvailableProducts,
    _getCountServices,
    _getMostSoldProducts,
    _getMostSoldServices,
    _getBestSellers,
    _getBestCustomers,
    _getDailyIncomes,
    _getMonthlyIncomes,
    _getAnnualIncomes,
    _getDailyIncomesInMounth,
    _getMonthlyIncomesInYear,
} from "../../../services/dashboard";
import {
    setCountCustomers,
    setCountSellers,
    setCountProducts,
    setCountAvailableProducts,
    setCountServices,
    setMostSoldProducts,
    setMostSoldServices,
    setBestCustomers,
    setBestSellers,
    setDailyIncomes,
    setMonthlyIncomes,
    setAnnualIncomes,
    setDailyIncomesInMonth,
    setMonthlyIncomesInYear,
} from "../../../redux/action/dashboardAction";
import { 
    FcBusinessman,
    FcManager,
    FcCurrencyExchange, 
    FcPackage,
    FcServices,
    FcCalculator,
    FcOpenedFolder,
    FcCalendar,
    FcPlanner,
    FcClock,
    FcStatistics,
    FcDonate,
    FcRefresh,
} from "react-icons/fc";
import { numberFormat } from "../../../helpers/Number";
import Ticket from "../../../Components/Ticket";
import Label from "../../../Components/label";
import CardTable from "../../../Components/CardTable";

import userIonicon from "../../../assets/svg/profile-icon.svg";

import "./dashboard.css";

const DashboardPage = (props) => {
    let navigate = useNavigate();
    const [timenow, setTimenow] = useState(new Date());
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [loadingSeller, setLoadingSeller] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [loadingAvailableProduct, setLoadingAvailableProduct] = useState(false);
    const [loadingService, setLoadingService] = useState(false);
    const [loadingDailyIncome, setLoadingDailyIncome] = useState(false);
    const [stateDailyIncome,] = useState({startDate: '', endDate: ''});
    const [loadingMonthlyIncome, setLoadingMonthlyIncome] = useState(false);
    // const [stateMonthlyIncome, setStateMonthlyIncome] = useState({startDate: '', endDate: ''});
    const [loadingAnnualIncome, setLoadingAnnualIncome] = useState(false);
    // const [stateAnnualIncome, setStateAnnualIncome] = useState({startDate: '', endDate: ''});
    const [loadingMSP, setLoadingMSP] = useState(false);
    const [stateMSP, setStateMSP] = useState({startDate: '', endDate: '', pageSize: 10, page: 1});
    const [loadingMSS, setLoadingMSS] = useState(false);
    const [stateMSS, setStateMSS] = useState({startDate: '', endDate: '', pageSize: 10, page: 1});
    // Daily income Month
    const [stateDIM,] = useState({startDate: '', endDate: ''});
    const [loadingDIM, setLoadingDIM] = useState(false);
    const [chartOption, setChartOption] = useState({
        chart: { id: 'Daily income of month', },
        xaxis: { categories: [], }
    });
    /* const [chartSeries, setChartSeries] = useState([{
        name: 'Daily income',
        data: [0, 40, 35, 50, 49, 60, 70, 91, 125, 60, 60, 60,],
    }]); */
    // Monthly income year
    const [stateMIY, ] = useState({startDate: '', endDate: ''});
    const [loadingMIY, setLoadingMIY] = useState(false);
    const [chartOptionMIY, setChartOptionMIY] = useState({
        chart: { id: 'Monthly income of year', },
        xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'], }
    });
    /* const [chartSeriesMIY, setChartSeriesMIY] = useState([{
        name: 'Monthly income',
        data: [],
    }]); */

    // Best customers
    const [loadingBC, setLoadingBC] = useState(false);
    /* const [chartSeriesBC, setChartSeriesBC] = useState([{
        name: 'Best customer',
        data: [],
    }]); */
    const [chartOptionBC, setChartOptionBC] = useState({
        chart: {
          type: 'bar',
          // height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: [],
        }
    },);

    // Best seller
    const [loadingBS, setLoadingBS] = useState(false);
    /* const [chartSeriesBS, setChartSeriesBS] = useState([{
        data: [],
    }]); */
    const [chartOptionBS, setChartOptionBS] = useState({
        chart: {
          type: 'bar',
          // height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: [],
        }
    },);

  const sortProductServices = (tab = []) => {
    return tab.sort(function (a, b) {
        return b.out - a.out;
    });
  }

  const sortCustomerSeller = (tab = []) => {
    return tab.sort(function (a, b) {
        return b.income - a.income;
    });
  }

  // go to page
  const gotoPage = (route) => {
    navigate(route);
  }

  // Get Customers
  useEffect(() => {
    (() => {
        setInterval(() => setTimenow(new Date()), 1000);
    })()
    // eslint-disable-next-line 
}, [])

  // Get Customers
  useEffect(() => {
      (async () => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_CUSTOMERS)){
            setLoadingCustomer(true);
            try {
                const res = await _getCountCustomers();
                if(res.status === 200){
                    props.setCountCustomers(res.data);
                }else{
                    toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
                }
            }catch(e){
                console.error("Erreur: ", e.message);
                toastr.error('Erreur', e.message, {timeOut: 3000});
            }finally{
                setLoadingCustomer(false);
            }
        }
      })()
      // eslint-disable-next-line 
  }, [])

  // Get Sellers
  useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_EMPLOYEES)){
          setLoadingSeller(true);
          try {
              const res = await _getCountSellers();
              if(res.status === 200){
                  props.setCountSellers(res.data);
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingSeller(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [])

  // Get Inventory products
  useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_PRODUCTS_INVENTORY)){
          setLoadingProduct(true);
          try {
              const res = await _getCountProducts();
              if(res.status === 200){
                  props.setCountProducts(res.data);
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingProduct(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [])

  // Get Available Inventory products
  useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY)){
          setLoadingAvailableProduct(true);
          try {
              const res = await _getCountAvailableProducts();
              if(res.status === 200){
                  props.setCountAvailableProducts(res.data);
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingAvailableProduct(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [])

   // Get count services
   useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_SERVICES)){
          setLoadingService(true);
          try {
              const res = await _getCountServices();
              if(res.status === 200){
                  props.setCountServices(res.data);
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingService(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [])

  // Get most sold product
  useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_PRODUCTS)){
          setLoadingMSP(true);
          try {
              const res = await _getMostSoldProducts(stateMSP.startDate, stateMSP.endDate, stateMSP.pageSize, stateMSP.page);
              if(res.status === 200){
                  props.setMostSoldProducts(sortProductServices(res.data.rows));
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingMSP(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [stateMSP])

  // Get most sold services
  useEffect(() => {
    (async () => {
      if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_SERVICES)){
          setLoadingMSS(true);
          try {
              const res = await _getMostSoldServices(stateMSS.startDate, stateMSS.endDate, stateMSS.pageSize, stateMSS.page);
              if(res.status === 200){
                  props.setMostSoldServices(sortProductServices(res.data.rows));
              }else{
                  toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
              }
          }catch(e){
              console.error("Erreur: ", e.message);
              toastr.error('Erreur', e.message, {timeOut: 3000});
          }finally{
              setLoadingMSS(false);
          }
      }
    })()
    // eslint-disable-next-line 
  }, [stateMSS])

  const handleMSPChange = (e) => {
    setStateMSP({ 
        ...stateMSP, 
        [e.target.name]: moment(e.target.value, "YYYY-MM").startOf("month").toDate(),
        endDate: moment(e.target.value, "YYYY-MM").endOf("month").toDate(),
    });
  }

  const handleMSSChange = (e) => {
    setStateMSS({ 
        ...stateMSS, 
        [e.target.name]: moment(e.target.value, "YYYY-MM").startOf("month").toDate(),
        endDate: moment(e.target.value, "YYYY-MM").endOf("month").toDate(),
    });
  }

  const getDIM = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES_MONTH)){
        setLoadingDIM(true);
        try {
            const res = await _getDailyIncomesInMounth(stateDIM.startDate, stateDIM.endDate);
            if(res.status === 200){
              // moment().get('month'); // 0 to 11
              const daysInMonth = moment(new Date()).daysInMonth() // 31
              const data = Array(daysInMonth).fill(0);
              const categories = [];
              data.forEach((elm, idx) => {
                  categories.push(idx + 1);
              });
              res.data.forEach((elem) => {
                  let dayOfMonth = parseInt(moment(elem.day, "DD/MM/YYYY").format('DD'), 10);
                  data[dayOfMonth - 1] = elem.income;
              });
              setChartOption({
                  ...chartOption, 
                  xaxis: { ...chartOption.xaxis, categories },
              });

              props.setDailyIncomesInMonth([{
                  name: 'Daily income',
                  data,
              },]);

            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
            setLoadingDIM(false);
        }
    }
  }

  // Daily Incomes in Month
  useEffect(() => {
    (() => getDIM() )()
    // eslint-disable-next-line 
  }, []);

  const DIMRefresh = useCallback(() => {
    (() => getDIM() )()
    // eslint-disable-next-line 
  }, []);

  const getMIY = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES_YEAR)){
        setLoadingMIY(true);
        try {
            const res = await _getMonthlyIncomesInYear(stateMIY.startDate, stateMIY.endDate);
            if(res.status === 200){
              // moment().get('month'); // 0 to 11 
              const monthsInYear = 12;
              const data = Array(monthsInYear).fill(0);
              const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];
              
              res.data.forEach((elem) => {
                  let monthOfYear = parseInt(moment(elem.month, "MM/YYYY").format('MM'), 10);
                  data[monthOfYear - 1] = elem.income;
              });
              setChartOptionMIY({
                  ...chartOptionMIY, 
                  xaxis: { ...chartOptionMIY.xaxis, categories },
              });

              props.setMonthlyIncomesInYear([{
                  name: 'Monthly income',
                  data,
              },]);

            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
            setLoadingMIY(false);
        }
    }
  }

  // Monthly income in yeah
  useEffect(() => {
    (() => {
        (() => getMIY() )()
    })()
    // eslint-disable-next-line 
  }, [])

  const MIYRefresh = useCallback(() => {
    (() => getMIY() )()
    // eslint-disable-next-line 
  }, []);

  // Best customers
  const getBC = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_CUSTOMERS)){
        setLoadingBC(true);
        try {
            const res = await _getBestCustomers(stateMIY.startDate, stateMIY.endDate, 10, 1);
            if(res.status === 200){
              const customers = sortCustomerSeller(res.data.rows);
              const categories = [];
              const data = [];
              customers.forEach(elm => {
                  categories.push(elm.customer.name);
                  data.push(elm.income);
              });
              setChartOptionBC({
                  ...chartOptionBC, 
                  xaxis: { ...chartOptionBC.xaxis, categories },
              });

              props.setBestCustomers([{
                  name: 'Best customer', data, 
              },]);

            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
            setLoadingBC(false);
        }
    }
  }

  useEffect(() => {
    (() => getBC() )()
    // eslint-disable-next-line 
  }, [])

  const BCRefresh = useCallback(() => {
    (() => getBC() )()
    // eslint-disable-next-line 
  }, []);

   // Best sellers

   const getBS = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_SELLERS)){
        setLoadingBS(true);
        try {
            const res = await _getBestSellers(stateMIY.startDate, stateMIY.endDate, 10, 1);
            if(res.status === 200){
              const sellers = sortCustomerSeller(res.data.rows);
              const categories = [];
              const data = [];
              sellers.forEach(elm => {
                  categories.push(elm.seller.name);
                  data.push(elm.income);
              });
              setChartOptionBS({
                  ...chartOptionBS, 
                  xaxis: { ...chartOptionBS.xaxis, categories },
              });

              props.setBestSellers([{ name: 'Best sellers', data, },]);

            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
            setLoadingBS(false);
        }
    }
   }

   useEffect(() => {
    (() => getBS() )()
    // eslint-disable-next-line 
  }, [])

  const BSRefresh = useCallback(() => {
    (() => getBS() )()
    // eslint-disable-next-line 
  }, []);

  // Daily income

  const getDI = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES)){
        setLoadingDailyIncome(true);
        try {
            const res = await _getDailyIncomes(stateDailyIncome.startDate, stateDailyIncome.endDate);
            if(res.status === 200){
                props.setDailyIncomes(res.data[0]?.income || 0);
            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
          setLoadingDailyIncome(false);
        }
    }
  }

  useEffect(() => {
    (() => getDI() )()
    // eslint-disable-next-line 
}, [])

const DIRefresh = useCallback(() => {
    (() => getDI() )()
    // eslint-disable-next-line 
  }, []);

// Monthly income

const getMI = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES)){
        setLoadingMonthlyIncome(true);
        try {
            const res = await _getMonthlyIncomes(stateDailyIncome.startDate, stateDailyIncome.endDate);
            if(res.status === 200){
                props.setMonthlyIncomes(res.data[0]?.income || 0);
            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
          setLoadingMonthlyIncome(false);
        }
    }
}

useEffect(() => {
    (() => getMI() )()
    // eslint-disable-next-line 
}, [])

const MIRefresh = useCallback(() => {
    (() => getMI() )()
    // eslint-disable-next-line 
  }, []);

// Annual income

const getAI = async () => {
    if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_ANNUAL_INCOMES)){
        setLoadingAnnualIncome(true);
        try {
            const res = await _getAnnualIncomes(stateDailyIncome.startDate, stateDailyIncome.endDate);
            if(res.status === 200){
                props.setAnnualIncomes(res.data[0]?.income || 0);
            }else{
                toastr.error('Erreur', res.errorMessage, {timeOut: 3000});
            }
        }catch(e){
            console.error("Erreur: ", e.message);
            toastr.error('Erreur', e.message, {timeOut: 3000});
        }finally{
          setLoadingAnnualIncome(false);
        }
    }
}

useEffect(() => {
    (async () => {
        (() => getAI())()
    })()
    // eslint-disable-next-line 
}, [])

const AIRefresh = useCallback(() => {
    (() => getAI())()
    // eslint-disable-next-line 
  }, []);

  return(
    <div className='dashboard-view'>
        <div className="sub-header mt-2"></div>

        <h6 className="mt-2"> <Label icon={FcCalendar} statement="Authentication activity" /> </h6>
        <Row>
            <Col md={4} sm={12} className="mt-2">
                <Ticket 
                    icon={<FcPlanner size={50} />}
                    title="Last Connection"
                    statement={`${moment(props.currentUser?.lastConnection || props.currentUser?.createdAt || new Date()).format("DD, MMM YYYY")} at ${moment(props.currentUser?.lastConection || props.currentUser?.createdAt || new Date()).format("HH : mm")}`}
                />
            </Col>
            <Col md={4} sm={12} className="mt-2">
                <Ticket 
                    icon={<FcClock size={50} />}
                    title={`${moment().format("dddd DD, MMMM YYYY")}`}
                    statement={`${moment(timenow).format("HH : mm : ss")}`}
                />
            </Col>
            <Col md={4} sm={12} className="mt-2">
                <Ticket 
                    icon={<img src={userIonicon} alt="user icon" width={50} height={50}/>}
                    title="User connected"
                    statement={`${props.currentUser?.firstname} ${props.currentUser?.lastname}`}
                />
            </Col>
        </Row>

        { 
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_ANNUAL_INCOMES) ? 
            <h6 className="mt-2"> <Label icon={FcDonate} statement="Incomes" /> </h6> : <></>
        }
        <Row className="">
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES) ? <Col md={4} sm={12} className="mb-2">
                <Ticket 
                    icon={<FcDonate size={50} />}
                    title="Daily incomes"
                    statement={`XAF ${numberFormat(props.dailyIncomes, 2, ".", " ")}`}
                    onClick={() => DIRefresh()}
                    loading={loadingDailyIncome}
                />
            </Col> : <></>}
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES) ? <Col md={4} sm={12} className="mb-2">
                <Ticket 
                    icon={<FcCurrencyExchange size={50} />}
                    title="Monthly incomes"
                    statement={`XAF ${numberFormat(props.monthlyIncomes, 2, ".", " ")}`}
                    onClick={(e) => MIRefresh()}
                    loading={loadingMonthlyIncome}
                />
            </Col> : <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_ANNUAL_INCOMES) ? <Col md={4} sm={12} className="mb-2">
                <Ticket 
                    icon={<FcCalculator size={50} />}
                    title="Annual incomes"
                    statement={`XAF ${numberFormat(props.annualIncomes, 2, ".", " ")}`}
                    onClick={(e) => AIRefresh()}
                    loading={loadingAnnualIncome}
                />
            </Col> : <></>}
        </Row>

        {
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES_MONTH)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES_YEAR) ?
            <h6 className="mt-2"> <Label icon={FcStatistics} statement="Charts" /> </h6> : <></>
        }
        <Row>
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_DAILY_INCOMES_MONTH) ? <Col md={6} sm={12}>
                <h6 className='ml-2 mr-2 d-flex align-items-center justify-content-between'> <div className='d-flex align-items-center'>Daily income on &nbsp; <b className=' mr-5'>{moment().format("MMMM, YYYY")} </b>  <Loader loading={loadingDIM} /></div>  <button className='btn btn-refresh border' onClick={() => DIMRefresh()}> <FcRefresh /> </button> </h6>
                <Chart options={chartOption} series={props.dailyIncomesInMonth || []} type="bar" />
            </Col> : <></> }
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MONTHLY_INCOMES_YEAR) ? <Col md={6} sm={12}>
                <h6 className='ml-2 mr-2 d-flex align-items-center justify-content-between'> <div className='d-flex align-items-center'>Monthly income on  &nbsp; <b className=' mr-5'>{moment().format("YYYY")} </b>  <Loader loading={loadingMIY} /></div> <button className='btn btn-refresh border' onClick={() => MIYRefresh()}> <FcRefresh /> </button> </h6>
                <Chart options={chartOptionMIY} series={props.monthlyIncomesInYear || []} type="bar" color="#444444" />
            </Col> : <></>}
        </Row>
        
        {
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_CUSTOMERS)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_SELLERS) ?
            <h6 className="mt-2"> <Label icon={FcStatistics} statement="Best performences" /> </h6> : <></>
        }
        <Row>
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_CUSTOMERS) ? <Col md={6} sm={12}>
                <h6 className='ml-2 mr-2 d-flex align-items-center justify-content-between'> <div className='d-flex align-items-center'>Best customers on &nbsp;  <b>{moment().format("MMMM, YYYY")} </b>  <Loader loading={loadingBC} /></div>  <button className='btn btn-refresh border' onClick={() => BCRefresh()}> <FcRefresh /> </button>  </h6>
                <Chart options={chartOptionBC} series={props.bestCustomers || []} type="bar" />
            </Col> : <></> }
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_BEST_SELLERS) ? <Col md={6} sm={12}>
                <h6 className='ml-2 mr-2 d-flex align-items-center justify-content-between'>  <div className='d-flex align-items-center'>Best sellers on &nbsp; <b>{moment().format("MMMM, YYYY")} </b>  <Loader loading={loadingBS} /></div>  <button className='btn btn-refresh border' onClick={() => BSRefresh()}> <FcRefresh /> </button>  </h6>
                <Chart options={chartOptionBS} series={props.bestSellers || []} type="bar" />
            </Col> : <></> }
        </Row>

        {
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_CUSTOMERS)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_EMPLOYEES)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_PRODUCTS_INVENTORY)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_SERVICES) ?
            <h6 className="mt-2"> <Label icon={FcOpenedFolder} statement="Counts" /> </h6> : <></>
        }
        <Row className="mt-2">
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_CUSTOMERS) ? <Col className="mb-2">
                <Ticket 
                    icon={<FcBusinessman size={50} />}
                    title="Customers registred"
                    statement={props.countCustomers}
                    onClick={(e) => gotoPage("/main/customers/all")}
                    loading={loadingCustomer}
                />
            </Col> : <></>}
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_EMPLOYEES) ? <Col className="mb-2">
                <Ticket 
                    icon={<FcManager size={50} />}
                    title="Employees registred"
                    statement={props.countSellers}
                    onClick={(e) => gotoPage("/main/employees/all")}
                    loading={loadingSeller}
                />
            </Col> : <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_PRODUCTS_INVENTORY) ? <Col className="mb-2">
                <Ticket 
                    icon={<FcPackage size={50} />}
                    title="Products registred"
                    statement={props.countProducts}
                    onClick={(e) => gotoPage("/main/inventory/products")}
                    loading={loadingProduct}
                />
            </Col> : <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_AVAILABLE_PRODUCT_INVENTORY) ? <Col className="mb-2">
                <Ticket 
                    icon={<FcPackage size={50} />}
                    title="Available Products registred"
                    statement={props.countAvailableProducts}
                    onClick={(e) => gotoPage("/main/inventory/products")}
                    loading={loadingAvailableProduct}
                />
            </Col> : <></>}
            {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_COUNT_SERVICES) ? <Col className="mb-2">
                <Ticket 
                    icon={<FcServices size={50} />}
                    title="Services registred"
                    statement={props.countServices}
                    onClick={(e) => gotoPage("/main/services/all")}
                    loading={loadingService}
                />
            </Col> : <></>}
        </Row>

        {
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_PRODUCTS)||
            _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_SERVICES) ?
            <h6 className="mt-2"> <Label icon={FcPackage} statement="Products & Services" /> </h6> : <></>
        }
        <Row className='mb-2'>
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_PRODUCTS) ? <Col md={6} sm={12}>
                <CardTable 
                    icon={FcPackage}
                    title={`Most sold inventory products`}
                    data={props.mostSoldProducts}
                    payloadKey="inventoryProduct"
                    payloadSubKey="designation"
                    loading={loadingMSP}
                    onSelectRow={(item) => gotoPage("/main/inventory/products/"+item?.originalData?.inventoryProduct.id)}
                    onChange={(e) => handleMSPChange(e) }
                />
            </Col> : <></>}
            { _checkAccessOrRedirect(FUNCTIONALITIES_CODE.DASHBOARD_MOST_SOLD_SERVICES) ? <Col md={6} sm={12}>
                <CardTable 
                    icon={FcServices}
                    title={`Most sold services`}
                    data={props.mostSoldServices}
                    payloadKey="service"
                    payloadSubKey="name"
                    loading={loadingMSS}
                    onSelectRow={(item) => gotoPage("/main/services/"+item?.originalData?.service.id)}
                    onChange={(e) => handleMSSChange(e) }
                />
            </Col> : <></>}
        </Row>

    </div>
  )

}

const mapStateToProps = (state) => {
  const {dashboard, auth} = state;

  return {
    countCustomers: dashboard.countCustomers,
    countSellers: dashboard.countSellers,
    countProducts: dashboard.countProducts,
    countAvailableProducts: dashboard.countAvailableProducts,
    countServices: dashboard.countServices,
    mostSoldProducts: dashboard.mostSoldProducts,
    mostSoldServices: dashboard.mostSoldServices,
    bestCustomers: dashboard.bestCustomers,
    bestSellers: dashboard.bestSellers,
    dailyIncomes: dashboard.dailyIncomes,
    monthlyIncomes: dashboard.monthlyIncomes,
    annualIncomes: dashboard.annualIncomes,
    dailyIncomesInMonth: dashboard.dailyIncomesInMonth,
    monthlyIncomesInYear: dashboard.monthlyIncomesInYear,

    currentUser : auth.currentAuth,
    shop : auth?.authData?.shop,
  };
}

export default connect(mapStateToProps, {
    setCountCustomers,
    setCountSellers,
    setCountProducts,
    setCountAvailableProducts,
    setCountServices,
    setMostSoldProducts,
    setMostSoldServices,
    setBestCustomers,
    setBestSellers,
    setDailyIncomes,
    setMonthlyIncomes,
    setAnnualIncomes,
    setDailyIncomesInMonth,
    setMonthlyIncomesInYear,
})(DashboardPage);
