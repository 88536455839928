import {FUNCTIONALITIES_CODE} from "../constants/AuthConstants";
import Auth from "../pages/Auth";
import ShopPage from "../pages/Shop";
import DashboardPage from "../pages/Main/Dashboard";
import { 
    FcComboChart, 
    FcBusinessman,
    FcManager,
    FcShop,
    // FcNews,
    FcConferenceCall, 
    FcPortraitMode, 
    FcFilingCabinet, 
    FcInspection,
    FcOrgUnit,
    FcSerialTasks,
    FcPackage,
    FcOpenedFolder,
    FcList,
    FcServices,
    FcCalculator,
    FcInvite,
} from "react-icons/fc";

const ROUTES = [
    {
        name: "Authentication",
        routeProps: {
            path: "/",
            element: Auth,
        },
        subRoutes: [],
        isProtected: false,
        hasRights: false,
        right: null,
    },
    {
        name: "Shop",
        routeProps: {
            path: "/shop",
            element: ShopPage,
        },
        subRoutes: [],
        isProtected: false,
        hasRights: false,
        right: null,
    },
    {
        name: "Main",
        routeProps: {
            path: "/main/*",
            element: DashboardPage,
        },
        subRoutes: [
            {
                name: "Dashboard",
                routeProps: {
                    path: "dashboard",
                    element: DashboardPage,
                },
                isProtected: true,
                hasRights: true,
                right: FUNCTIONALITIES_CODE.DASHBOARD,
                subRoutes: [],
            }
        ],
        isProtected: true,
        hasRights: false,
        right: null,
    },
];

export const ROUTES_APP = [
    {
        id: 1,
        name: "Dashboard",
        path: "dashboard",
        right: FUNCTIONALITIES_CODE.DASHBOARD,
        className: "",
        icon: FcComboChart,
        isActive: false,
        children: [],
    },
    {
        id: 2,
        name: "Customer",
        path: "customers",
        right: FUNCTIONALITIES_CODE.CUSTOMER,
        className: "",
        icon: FcBusinessman,
        isActive: false,
        children: [],
    },
    {
        id: 10,
        name: "Shop",
        path: "shop",
        right: FUNCTIONALITIES_CODE.CUSTOMER_SHOP,
        className: "",
        icon: FcShop,
        isActive: false,
        children: [
            {
                id: 1,
                name: "Order",
                path: "order",
                right: FUNCTIONALITIES_CODE.CREATE_CUSTOMER_ORDER,
                className: "",
                icon: FcCalculator,
                isActive: true,
                children: [],
            },
            {
                id: 2,
                name: "Order operations",
                path: "operations",
                right: FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER,
                className: "",
                icon: FcList,
                isActive: false,
                children: [],
            },
        ],
    },
    {
        id: 3,
        name: "Employees",
        path: "employees",
        right: FUNCTIONALITIES_CODE.EMPLOYEE,
        className: "",
        icon: FcManager,
        isActive: false,
        children: [
            {
                id: 1,
                name: "List",
                path: "all",
                right: FUNCTIONALITIES_CODE.LIST_EMPLOYEE,
                className: "",
                icon: FcList,
                isActive: true,
                children: [],
            },
            {
                id: 2,
                name: "Functions",
                path: "functions",
                right: FUNCTIONALITIES_CODE.LIST_FUNCTION_EMPLOYEE,
                className: "",
                icon: FcOpenedFolder,
                isActive: false,
                children: [],
            },
        ],
    },
    {
        id: 4,
        name: "Inventory",
        path: "inventory",
        right: FUNCTIONALITIES_CODE.INVENTORY,
        className: "",
        icon: FcShop,
        isActive: false,
        children: [
            {
                id: 1,
                name: "Inventory",
                path: "Operations",
                right: FUNCTIONALITIES_CODE.LIST_OPERATIONS,
                className: "",
                icon: FcSerialTasks,
                isActive: true,
                children: [],
            },
            {
                id: 2,
                name: "Products",
                path: "products",
                right: FUNCTIONALITIES_CODE.LIST_INVENTORY_PRODUCT,
                className: "",
                icon: FcPackage,
                isActive: false,
                children: [],
            },
            {
                id: 4,
                name: "Categories",
                path: "categories",
                right: FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY,
                className: "",
                icon: FcOrgUnit,
                isActive: false,
                children: [],
            },
        ],
    },
    {
        id: 5,
        name: "Services",
        path: "services",
        right: FUNCTIONALITIES_CODE.SERVICE,
        className: "",
        icon: FcServices,
        isActive: false,
        children: [
            {
                id: 1,
                name: "Services",
                path: "all",
                right: FUNCTIONALITIES_CODE.LIST_SERVICE,
                className: "",
                icon: FcServices,
                isActive: false,
                children: [],
            },
            {
                id: 2,
                name: "Categories",
                path: "categories",
                right: FUNCTIONALITIES_CODE.LIST_PRODUCT_CATEGORY,
                className: "",
                icon: FcOrgUnit,
                isActive: false,
                children: [],
            },],
    },
    {
        id: 6,
        name: "Cashier",
        path: "cashier",
        right: FUNCTIONALITIES_CODE.CASHIER,
        className: "",
        icon: FcCalculator,
        isActive: false,
        children: [
            {
                id: 1,
                name: "Cashier",
                path: "cashier",
                right: FUNCTIONALITIES_CODE.CREATE_CASHIER_OPERATION,
                className: "sm-hide",
                icon: FcCalculator,
                isActive: false,
                children: [],
            },
            {
                id: 2,
                name: "Cashier operations",
                path: "operations",
                right: FUNCTIONALITIES_CODE.LIST_CASHIER_OPERATION,
                className: "",
                icon: FcList,
                isActive: false,
                children: [],
            },
            {
                id: 3,
                name: "Customer orders",
                path: "customer-orders",
                right: FUNCTIONALITIES_CODE.LIST_CUSTOMER_ORDER,
                className: "",
                icon: FcBusinessman,
                isActive: false,
                children: [],
            },
        ],
    },
    /* {
        id: 7,
        name: "Billing",
        path: "billing",
        right: FUNCTIONALITIES_CODE.BILL,
        className: "",
        icon: FcNews,
        isActive: false,
        children: [],
    }, */
    {
        id: 8,
        name: "SMS",
        path: "sms",
        right: FUNCTIONALITIES_CODE.SMS,
        className: "",
        icon: FcInvite,
        isActive: false,
        children: [
            {
                id: 1,
                name: "New campaign",
                path: "new-campaign",
                right: FUNCTIONALITIES_CODE.SEND_SMS,
                className: "",
                icon: FcPortraitMode,
                isActive: true,
                children: [],
            },
        ],
    },
    {
        id: 9,
        name: "Account management",
        path: "account-management",
        right: FUNCTIONALITIES_CODE.ACCOUNT_MANAGEMENT,
        className: "",
        icon: FcConferenceCall,
        isActive: false,
        children: [
            {
                id: 1,
                name: "Users accounts",
                path: "users-accounts",
                right: FUNCTIONALITIES_CODE.USERS_ACCOUNTS,
                className: "",
                icon: FcPortraitMode,
                isActive: true,
                children: [],
            },
            {
                id: 2,
                name: "Groups",
                path: "groups",
                right: FUNCTIONALITIES_CODE.GROUP_RIGHT,
                className: "",
                icon: FcFilingCabinet,
                isActive: false,
                children: [],
            },
            {
                id: 3,
                name: "Rights",
                path: "rights",
                right: FUNCTIONALITIES_CODE.RIGHT,
                className: "",
                icon: FcInspection,
                isActive: false,
                children: [],
            },
        ],
    },
];

export default ROUTES;