import React, {Suspense, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import BtnAdd from "../../../Components/BtnAdd";
import {_checkAccessOrRedirect} from "../../../services/Auth";
import Loader from "../../../Components/Loader";
import Input from "../../../Components/CustomInput/CustomInput";
import moment from "moment";
import './employee.css';
import {
  _createSeller,
  _deleteSeller,
  _updateSeller,
  getSellers
} from "../../../services/Sellers";
import { _getEmployeeFunctions } from "../../../services/employeeFunction";
import { setEmployeeFunctions } from "../../../redux/action/employeeFunctionAction";
import {toastr} from "react-redux-toastr";
import {IoPencil, IoTrash} from 'react-icons/io5';
import {FcPackage, FcServices} from "react-icons/fc";
import Table from "../../../Components/Table/GenericTable";
import {connect} from "react-redux";
import {
  addSeller,
  deleteSeller,
  selectSeller,
  setSellers,
  updateSeller
} from "../../../redux/action/SellersAction";
import ModalRight from "../../../Components/ModalRight";
import Button from "../../../Components/InputButton/InputButton";
import {Pagination} from "react-bootstrap";
import PaginationSize from "../../../Components/paginationSize";
import {FUNCTIONALITIES_CODE} from "../../../constants/AuthConstants";

const SellersPage = (props) => {
  let navigate = useNavigate();
  let [isRightVisible, setIsRightVisible] = useState(false);
  let [filter, setFilter] = useState();
  let [loading, setLoading] = useState(false);
  let [loadingData, setLoadingData] = useState(false);
  let [loadingForm, setLoadingForm] = useState(false);
  let [stateSeller, setStateSeller] = useState({});
  let [stateData, setStateData] = useState({});
  let [displayForm, setDisplayForm] = useState(null);
  let [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    (async (filter = "", pageSize = 20, page = 1) => {
      // Check permission on action
      if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_EMPLOYEE)) {
        setLoadingData(true);
        try {
          const res = await getSellers(filter, pageSize, page);
          setStateData({
            currentPage: res.data.pager.currentPage,
            pageSize: res.data.pager.pageSize,
            endIndex: res.data.pager.endIndex,
            endPage: res.data.pager.endPage,
            pages: res.data.pager.pages,
            startIndex: res.data.pager.startIndex,
            startPage: res.data.pager.startPage,
            totalItems: res.data.pager.totalItems,
            totalPages: res.data.pager.totalPages,
          });
          if (res.status === 200) {
            console.log(res.data.rows);
            setLoadingData(false);
            props.setSellers(res.data.rows);
          } else {
            setLoadingData(false);
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          console.log({stateData});
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingData(false);
        }
      }else{
        toastr.error('Erreur', "You do not have permission for this action!", {timeOut: 5000});
      }
    })()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async (filter="", pageSize=20, page=1) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_FUNCTION_EMPLOYEE)){
            try {
                const res = await _getEmployeeFunctions(filter, pageSize, page);
                if(res.status === 200){
                    props.setEmployeeFunctions(res.data.rows);
                }else{
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur ici', e.message, {timeOut: 5000});
            }
        }
    })()
    // eslint-disable-next-line
}, []);

  const getFilterSellers = async (filter = "", pageSize = 20, page = 1) => {
    // Check permission on action
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_EMPLOYEE)) {
      setLoadingData(true);
      try {
        const res = await getSellers(filter, pageSize, page);
        setStateData({
          currentPage: res.data.pager.currentPage,
          pageSize: res.data.pager.pageSize,
          endIndex: res.data.pager.endIndex,
          endPage: res.data.pager.endPage,
          pages: res.data.pager.pages,
          startIndex: res.data.pager.startIndex,
          startPage: res.data.pager.startPage,
          totalItems: res.data.pager.totalItems,
          totalPages: res.data.pager.totalPages,
        });
        if (res.status === 200) {
          setLoadingData(false);
          props.setSellers(res.data.rows);
        } else {
          setLoadingData(false);
          toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
        }
      } catch (e) {
        console.error("Erreur: ", e);
        toastr.error('Erreur', e, {timeOut: 5000});
        setLoadingData(false);
      }
    }
  };

  // Handle change for create customer form
  const handleCSChange = (e) => {
    stateSeller = {...stateSeller, [e.target.name]: e.target.value}
    setStateSeller(stateSeller);
  }

  const createSeller = async (e) => {
    e.stopPropagation();
    setLoadingForm(true);
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_EMPLOYEE)) {
      if (!loading) {
        try {
          stateSeller = { ...stateSeller, shopId: +props.shop.id };
          const res = await _createSeller(stateSeller);
          if (res.status === 200) {
            props.addSeller(res.data);
            stateSeller = {}
            setStateSeller(stateSeller);
          } else {
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          setLoadingForm(false);
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingForm(false);
        }
      }
    } else {
      toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
    }
  }

  const editSeller = async (e) => {
    e.stopPropagation();
    setLoadingForm(true);
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_EMPLOYEE)) {
      if (!loading) {
        try {
          stateSeller = { ...stateSeller, shopId: +props.shop.id };
          const res = await _updateSeller(stateSeller);
          if (res.status === 200) {
            props.updateSeller(res.data);
          } else {
            toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
          }
          setLoadingForm(false);
        } catch (e) {
          console.error("Erreur: ", e);
          toastr.error('Erreur', e.message, {timeOut: 5000});
          setLoadingForm(false);
        }
      }
    } else {
      toastr.error('Erreur', "You do not have permition for this action", {timeOut: 3});
    }
  }

  const renderCategories = () => {
    return props?.employeeFunctions?.map((item, i) => {
        return (
            <option value={item.id} key={i}>{item.name}</option>
        )
    })
}

  const renderFormCreate = () => {
    return (
      <form name="sellerCreateForm" className="m-2" onSubmit={createSeller}>

        <div className="form-group">
            <label htmlFor="employeeFunctionId" className="label-input">Function:</label>
            <select className="form-control select" name="employeeFunctionId" id="employeeFunctionId" onChange={handleCSChange} defaultValue={stateSeller.employeeFunctionId || ''}>
                <option value="">Select a function...</option>
                { renderCategories() }
            </select>
          </div>

        <div className="form-group">
          <label htmlFor="name" className="label-input">Name:</label>
          <input className="form-control" type="text" name="name" id="name" value={stateSeller.name || ''}
                 onChange={handleCSChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="birthDate" className="label-input">Birth date:</label>
          <input className="form-control" type="date" name="birthDate" id="birthDate" value={stateSeller.birthDate || ''}
                 onChange={handleCSChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="phone1" className="label-input">Phone 1:</label>
          <input className="form-control" type="text" name="phone1" id="phone1" value={stateSeller.phone1 || ''}
                 onChange={handleCSChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="phone2" className="label-input">Phone 2:</label>
          <input className="form-control" type="text" name="phone2" id="phone1" value={stateSeller.phone2 || ''}
                 onChange={handleCSChange} required/>
        </div>

        <div className="form-group">
          <label htmlFor="email" className="label-input">Email:</label>
          <input className="form-control" type="email" name="email" id="email" value={stateSeller.email || ''}
                 onChange={handleCSChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="address" className="label-input">Address:</label>
          <input className="form-control" type="text" name="address" id="address" value={stateSeller.address || ''}
                 onChange={handleCSChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="cni" className="label-input">CNI:</label>
          <input className="form-control" type="text" name="cni" id="cni" value={stateSeller.cni || ''}
                 onChange={handleCSChange}/>
        </div>

        <div className="separator mb-2 mt-2"></div>
        <div className="form-group">
          <Button
            variant="primary"
            value={isEdit ? "Update" : "Submit"}
            className="m-0 form-control"
            loading={loadingForm}
            onClick={isEdit ? editSeller : createSeller}
          />
        </div>
      </form>
    )
  }

  const renderDetailSeller = () => {
    return (
      <div className="p-2">
         <div className="item-group">
          <div className="item-left">Fonction</div>
          <div className="item-right"> <b>{props.selectedSeller?.employeeFunction?.name}</b> </div>
        </div>
        <div className="item-group">
          <div className="item-left">Name</div>
          <div className="item-right">{props.selectedSeller.name}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Birth date</div>
          <div className="item-right">{moment(props.selectedSeller.birthDate).format("YYYY-MM-DD") }</div>
        </div>
        <div className="item-group">
          <div className="item-left">Phone 1</div>
          <div
            className="item-right">{props.selectedSeller.phone1}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Phone 2</div>
          <div
            className="item-right">{props.selectedSeller.phone2}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Email</div>
          <div className="item-right">{props.selectedSeller.email}</div>
        </div>
        <div className="item-group">
          <div className="item-left">Address</div>
          <div className="item-right">{props.selectedSeller.address}</div>
        </div>
        <div className="item-group">
          <div className="item-left">CNI</div>
          <div className="item-right">{props.selectedSeller.cni}</div>
        </div>
      </div>
    )
  }

  const renderRightModal = () => {
    switch (displayForm) {
      case "new" : {
        return (
          <ModalRight
            title={isEdit ? "Edit Employee" : "New Employee"}
            content={renderFormCreate()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }
      case "preview" : {
        return (
          <ModalRight
            title="Employee detail"
            content={renderDetailSeller()}
            isVisible={isRightVisible}
            onClose={(e) => setIsRightVisible(false)}
          />
        )
      }

      default :
        return <></>
    }
  }

  const displayCreateSellerForm = (e) => {
    e.preventDefault();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_EMPLOYEE)) {
      setIsRightVisible(true);
      setStateSeller({});
      setIsEdit(false);
      setDisplayForm("new");
    }
  }

  const deleteSeller = async (e, id) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_EMPLOYEE)) {
      const toastrConfirmOptions = {
        onOk: () => delSeller(id),
        onCancel: () => {
        }
      };
      toastr.confirm("Do you really want to delete this employee?", toastrConfirmOptions);
    }
  }

  const delSeller = async (id) => {
    setLoading(true);
    try {
      const res = await _deleteSeller(id);
      if (res.status === 200) {
        setLoading(false);
        props.deleteSeller(id);
      } else {
        setLoading(false);
        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
      }
    } catch (e) {
      console.error("Erreur: ", e);
      toastr.error('Erreur', e.message, {timeOut: 5000});
      setLoading(false);
    }
  }

  const displayEditSellerForm = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_EMPLOYEE)) {
      // const {id, ...customer} = item
      setIsEdit(true);
      setStateSeller(item);
      setIsRightVisible(true);
      setDisplayForm("new");
    }
  }

  const selectElement = (item) => {
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.PREVIEW_EMPLOYEE)) {
      props.selectSeller(item?.originalData);
      setIsRightVisible(true);
      setDisplayForm("preview");
    }
  }

  const gotoHistory = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE)) {
      navigate(`${item.id}/history`);
    }
  }

  const gotoServiceHistory = (e, item) => {
    e.stopPropagation();
    if (_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE)) {
      navigate(`${item.id}/services-history`);
    }
  }

  const renderAction = (item) => {
    return (
      <div className="">
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE) ?
            <button className="btn btn-action" onClick={(e) => gotoHistory(e, item)}  title='Selling products history'>
                <FcPackage/>
            </button> :
            <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.HISTORY_EMPLOYEE) ?
            <button className="btn btn-action" onClick={(e) => gotoServiceHistory(e, item)}  title='Selling services history'>
                <FcServices/>
            </button> :
            <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.UPDATE_EMPLOYEE) ?
          <button className="btn btn-action" onClick={(e) => displayEditSellerForm(e, item)}>
            <IoPencil color="blue"/>
          </button> :
          <></>}
        {_checkAccessOrRedirect(FUNCTIONALITIES_CODE.DELETE_EMPLOYEE) ?
          <button className="btn btn-action" onClick={(e) => deleteSeller(e, item.id)}><IoTrash/></button> :
          <></>}
      </div>
    )
  }

  const headerData = [
    {
      title: <div className="size-30 flex-center  rounded"> # </div>,
      align: "center",
      className: '',
      width: 50,
      name: "number"
    },
    {title: "Name", align: "left", className: 'pl-15', width: "auto", name: "name"},
    {title: "Phone 1", align: "left", className: 'pl-15 md-hide', width: "auto", name: "phone1"},
    {title: "Email", align: "left", className: 'pl-15 sm-hide', width: "auto", name: "email"},
    {title: "Function", align: "left", className: 'pl-15', width: "auto", name: "function"},
    {title: <> Actions </>, align: "left", className: '', width: "150px", name: "action"},
  ];

  const formatBodyData = (data) => {
    return data?.map((item, i) => {
      let resData = {
        originalData: item,
        number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
        name: item.name,
        phone1: item.phone1,
        email: item.email,
        function: <b>{item?.employeeFunction?.name}</b>,
        action: renderAction(item),
      }
      return resData;
    })
  }

  const setPage = async (page) => {
    if (stateData.currentPage !== page) {
      await getFilterSellers("", stateData.pageSize, page);
    }
  }

  const setSize = async (pageSize) => {
    await getFilterSellers("", pageSize, 1);
  }

  const renderPaginarion = () => {
    if (stateData.totalPages > 1) {
      let items = [];
      for (let number = +stateData.startPage; number <= +stateData.totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === stateData.currentPage} onClick={() => setPage(number)}
            className="pointer">
            {number}
          </Pagination.Item>,
        );
      }
      return (
        <div className="d-flex align-items-center justify-content-between mt-4">
          <Pagination>
            <Pagination.First disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.startPage)}/>
            <Pagination.Prev disabled={+stateData.startPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage - 1)}/>
            {items}
            <Pagination.Next disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(stateData.currentPage + 1)}/>
            <Pagination.Last disabled={+stateData.endPage === +stateData.currentPage}
              onClick={() => setPage(+stateData.endPage)}/>
          </Pagination>
          <PaginationSize value={stateData.pageSize} onChange={setSize} className=""/>
        </div>
      )
    } else {
      return <></>
    }
  }

  const handleFilterChange = async (e) => {
    setFilter(e.target.value);
    await getFilterSellers(e.target.value);
  }


  return (
      <>
        <div className="sellers-list-view">
          <div className="page-action">
            <div className="d-flex aligns-items-center">
              <BtnAdd
                statement="New employee"
                onClick={(e) => displayCreateSellerForm(e)}
                disabled={!_checkAccessOrRedirect(FUNCTIONALITIES_CODE.CREATE_EMPLOYEE)}
                className="mr-2"
                style={{marginRight: 5}}
              />
              <Loader loading={false}/>
            </div>
            <div className="search-content-form">
              <Input
                type="text"
                globalClassName="search-input"
                name="filter"
                value={filter}
                placeholder="Enter a keyword..."
                onChange={(e) => handleFilterChange(e)}
              />
            </div>
          </div>

          <Suspense fallback={<Loader loading/>}>
            <Table
              header={headerData}
              body={formatBodyData(props.sellers)}
              className=''
              hoverColor="rgba(0, 0, 0, 0.05)"
              isLoading={loadingData}
              asLoader={<Loader loading/>}
              onSelect={(item) => selectElement(item)}
              selectedClass="active"
            />
            {renderPaginarion()}
          </Suspense>
        </div>
        {renderRightModal()}
      </>
  );
};

const mapStateToProps = (state) => {
  const {sellers, employeeFunctions, auth} = state;

  return {
    sellers: sellers.sellers, 
    selectedSeller: sellers.selectedSeller,
    shop : auth?.authData?.shop,
    employeeFunctions : employeeFunctions.employeeFunctions,
  };
}

export default connect(mapStateToProps, {
  setSellers,
  addSeller,
  updateSeller,
  deleteSeller,
  selectSeller,
  setEmployeeFunctions,
})(SellersPage);
