import { ADD_PRODUCT, SET_PRODUCTS, UPDATE_PRODUCT, DELETE_PRODUCT, SELECT_PRODUCT, ADD_PRODUCT_QUANTITY, REMOVE_PRODUCT_QUANTITY, SET_OPERATIONS, SELECT_OPERATION } from '../action-type/inventoryType';

export const addProduct = (data) => ({
    type: ADD_PRODUCT,
    payload: data
})

export const setProducts = (data) => ({
    type: SET_PRODUCTS,
    payload: data
})

export const updateProduct = (data) => ({
    type: UPDATE_PRODUCT,
    payload: data
})

export const deleteProduct = (id) => ({
    type: DELETE_PRODUCT,
    payload: id
})

export const selectProduct = (data) => ({
    type: SELECT_PRODUCT,
    payload: data
})

export const addProductQuantity = (data) => ({
    type: ADD_PRODUCT_QUANTITY,
    payload: data
})

export const removeProductQuantity = (data) => ({
    type: REMOVE_PRODUCT_QUANTITY,
    payload: data
})

export const setOperations = (data) => ({
    type: SET_OPERATIONS,
    payload: data
})

export const selectOperation = (data) => ({
    type: SELECT_OPERATION,
    payload: data
})