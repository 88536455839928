import React from "react";

import "./label.css";

function Label ({icon, statement, className, style}) {
    const Icon = icon;
    return (
        <div className={`label-component ${className ? className : ""}`} style={style}>
            <div className="icon">{<Icon />}</div>
            <span className="statement">{statement}</span>
        </div>
    )
}

export default Label;