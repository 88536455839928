import {
    SET_COUNT_CUSTOMERS, SET_COUNT_SELLERS, SET_COUNT_PRODUCTS, SET_COUNT_AVAILABLE_PRODUCTS,
    SET_COUNT_SERVICES, SET_MOST_SOLD_PRODUCTS, SET_MOST_SOLD_SERVICES, SET_BEST_CUSTOMERS,
    SET_BEST_SELLERS, SET_DAILY_INCOMES, SET_MONTHLY_INCOMES, SET_ANNUAL_INCOMES, SET_DAILY_INCOMES_MONTH,
    SET_MONTHLY_INCOMES_YEAR,
} from '../action-type/dashboardType';


const INITIAL_STATE = {
    countCustomers: 0,
    countSellers: 0,
    countProducts: 0,
    countAvailableProducts: 0,
    countServices: 0,
    mostSoldProducts: [],
    mostSoldServices: [],
    bestCustomers: [],
    bestSellers: [],
    dailyIncomes: 0,
    monthlyIncomes: 0,
    annualIncomes: 0,
    dailyIncomesInMonth: [],
    monthlyIncomesInYear: [],
};

const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COUNT_CUSTOMERS: {
          if (action.payload) {
            return {
              ...state,
              countCustomers: action.payload
            }
          }
          return state;
        }

        case SET_COUNT_SELLERS: {
            if (action.payload) {
              return {
                ...state,
                countSellers: action.payload
              }
            }
            return state;
        }

        case SET_COUNT_PRODUCTS: {
            if (action.payload) {
              return {
                ...state,
                countProducts: action.payload
              }
            }
            return state;
        }

        case SET_COUNT_AVAILABLE_PRODUCTS: {
            if (action.payload) {
              return {
                ...state,
                countAvailableProducts: action.payload
              }
            }
            return state;
        }

        case SET_COUNT_SERVICES: {
            if (action.payload) {
              return {
                ...state,
                countServices: action.payload
              }
            }
            return state;
        }

        case SET_MOST_SOLD_PRODUCTS: {
            if (action.payload) {
              return {
                ...state,
                mostSoldProducts: action.payload
              }
            }
            return state;
        }

        case SET_MOST_SOLD_SERVICES: {
            if (action.payload) {
              return {
                ...state,
                mostSoldServices: action.payload
              }
            }
            return state;
        }

        case SET_BEST_CUSTOMERS: {
            if (action.payload) {
              return {
                ...state,
                bestCustomers: action.payload
              }
            }
            return state;
        }

        case SET_BEST_SELLERS: {
            if (action.payload) {
              return {
                ...state,
                bestSellers: action.payload
              }
            }
            return state;
        }

        case SET_DAILY_INCOMES: {
            if (action.payload) {
              return {
                ...state,
                dailyIncomes: action.payload
              }
            }
            return state;
        }

        case SET_MONTHLY_INCOMES: {
            if (action.payload) {
              return {
                ...state,
                monthlyIncomes: action.payload
              }
            }
            return state;
        }

        case SET_ANNUAL_INCOMES: {
            if (action.payload) {
              return {
                ...state,
                annualIncomes: action.payload
              }
            }
            return state;
        }

        case SET_DAILY_INCOMES_MONTH: {
            if (action.payload) {
              return {
                ...state,
                dailyIncomesInMonth: action.payload
              }
            }
            return state;
        }

        case SET_MONTHLY_INCOMES_YEAR: {
            if (action.payload) {
              return {
                ...state,
                monthlyIncomesInYear: action.payload
              }
            }
            return state;
        }

        default: return state;
    }
}

export default DashboardReducer;
