import React from "react";
import { IoIosClose } from "react-icons/io";

import "./InputGroupForm.css"

function InputFormGroup({name, nameKey, description, descriptionKey, placeholderName, placeholderDescription, onNameChange, onDescChange, onSubmit, onSubmitUpdate, onReset, className, isUpdate}) {

    return (
        <div className={`input-group-form ${className ? className : ''}`}>
            <div className="inline-input">
                <input 
                    type="text" 
                    className="form-control" 
                    name={nameKey}
                    value={name} 
                    placeholder={placeholderName ? placeholderName : 'Enter name here...'} 
                    onChange={onNameChange}
                />
            </div>
            <div className="inline-input">
                <input 
                    type="text" 
                    className="form-control" 
                    name={descriptionKey}
                    value={description} 
                    placeholder={placeholderDescription ? placeholderDescription : 'Enter description here...'} 
                    onChange={onDescChange}
                />
            </div>
            { isUpdate ? <button className="btn btn-cancel" onClick={onReset}> <IoIosClose /> </button> : <></>}
            <button className="btn btn-primary" onClick={isUpdate ? onSubmitUpdate : onSubmit}>{` ${ isUpdate ? "UPDATE" : "CREATE"} `}</button>
        </div>
    )
}

export default InputFormGroup;