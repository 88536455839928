import React from "react";

const LockClosedOutlineComponent = props => (
  <svg viewBox="0 0 15 18.661" width={props.width ? props.width : 15} height={props.height ? props.height : 18.661} className={props.className ? props.className : ""}>
    <defs></defs>
    <g transform="translate(-95.443 -34.037)">
      <path
        stroke={props.color ? props.color : "#1179ad"}
        fill="transparent"
        d="M183.071,38.057V35.312c0-1.277-1.583-2.312-3.535-2.312S176,34.035,176,35.312v2.745"
        transform="translate(-76.593 1.537)"
      />
      <rect
        stroke={props.color ? props.color : "#1179ad"}
        fill="transparent"
        width={14}
        height={12}
        rx={2}
        transform="translate(95.943 40.198)"
      />
    </g>
  </svg>
);

export default LockClosedOutlineComponent;
