import React from "react";
import Table from "../Table/GenericTable";
import Label from "../label";
import Loader from "../Loader";

import "./cardtable.css";

const CardTable = ({title, icon, data, loading, payloadKey, payloadSubKey, className, style, onChange, onSelectRow}) => {

    const headerData = [
        {
          title: <div className="size-30 flex-center  rounded"> # </div>,
          align: "center",
          className: '',
          width: 50,
          name: "number"
        },
        {title: "Code ID", align: "left", className: 'pl-15 sm-hide', width: "auto", name: "codeID"},
        {title: "Designation", align: "left", className: 'pl-15', width: "auto", name: "designation"},
        {title: "Out", align: "right", className: 'pr-5 sm-hide bold', width: "auto", name: "out"},
    ];

    const formatBodyData = (data) => {
        return data?.map((item, i) => {
          let resData = {
            originalData: item,
            number: <div className="size-30  rounded d-flex align-items-center justify-content-center"> {i + 1} </div>,
            codeID: item?.[payloadKey]?.codeID,
            designation: <b>{item?.[payloadKey]?.[payloadSubKey]}</b>,
            out: <b>{item.out}</b>,
          }
          return resData;
        })
      }

    return(
        <div className={`card-table ${className ? className : ''}`} style={{...style}}>
            <div className="d-flex align-item-center justify-content-between">
                <h6> <Label icon={icon} statement={title} /> </h6>
                <input type="month" name="startDate" id="startDate" className="form-input" onChange={(e) => onChange(e)} />
            </div>
            
            <div className="separator mb-3 mt-3"></div>
            <Table
              header={headerData}
              body={formatBodyData(data)}
              className='mt-2 classification'
              hoverColor="rgba(0, 0, 0, 0.05)"
              isLoading={loading}
              asLoader={<Loader loading/>}
              onSelect={(item) => onSelectRow(item)}
              selectedClass="active"
            />
        </div>
    )
}

export default CardTable;