import React, {useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import {toastr} from 'react-redux-toastr';
import { FUNCTIONALITIES_CODE } from "../../../../constants/AuthConstants";
import { _getUsersAccountById } from "../../../../services/Account";
import { _assagnGroupsToUser, _removeGroupsfromUser, _getGroups } from "../../../../services/Group";
import { selectAccount } from "../../../../redux/action/AccountAction";
import { setGroups } from "../../../../redux/action/GroupAction";
import { _checkAccessOrRedirect } from "../../../../services/Auth";
import Loader from "../../../../Components/Loader";
import Label from "../../../../Components/label"
import LabelRadio from "../../../../Components/LabelRadio";
import { FcInspection, FcFilingCabinet, FcPrevious, FcRight, FcLeft } from "react-icons/fc";
import "./usersAccounts.css";

function UserAccountDetails (props) {
    let [loading, setLoading] = useState(false);
    // let [assignRights, setAssignRights] = useState([]);
    let [assignGroup, setAssignGroup] = useState([]);
    let [assignedGroup, setAssignedGroup] = useState([]);
    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        (async () => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT)){
                setLoading(true);
                try {
                    const res = await _getUsersAccountById(+params.id);
                    if(res.status === 200){
                        setLoading(false);
                        props.selectAccount(res.data);
                    }else{
                        setLoading(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e, {timeOut: 5000});
                    setLoading(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);

    const getUserAccountById = async (id) => {
        // Check permission on action
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_USER_ACCOUNT)){
            setLoading(true);
            try {
                const res = await _getUsersAccountById(+id);
                if(res.status === 200){
                    setLoading(false);
                    props.selectAccount(res.data);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        (async () => {
            // Check permission on action
            if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.LIST_GROUP)){
                setLoading(true);
                try {
                    const res = await _getGroups("", 1000, 1);
                    if(res.status === 200){
                        setLoading(false);
                        props.setGroups(res.data.rows);
                    }else{
                        setLoading(false);
                        toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                    }
                }catch(e){
                    console.error("Erreur: ", e);
                    toastr.error('Erreur', e, {timeOut: 5000});
                    setLoading(false);
                }
            }
        })()
        // eslint-disable-next-line
    }, []);

    const handleLabelChange = (e, item) => {
        if(e.target.checked){
            addGroupToPayload(item.id);
        }else{
            removeGroupToPayload(item.id);
        }
    }

    const handleAssignedLabelChange = (e, item) => {
        if(e.target.checked){
            addAssignedGroupToPayload(item.groupId);
        }else{
            removeAssignedGroupToPayload(item.groupId);
        }
    }

    const addGroupToPayload = (id) => {
        let newList = [...assignGroup, { groupId : id }] 
        setAssignGroup(newList);
    }

    const addAssignedGroupToPayload = (id) => {
        let newList = [...assignedGroup, id ] 
        setAssignedGroup(newList);
    }

    const removeGroupToPayload = (id) => {
        const i = assignGroup.findIndex(elm => +elm.groupId === +id);
        assignGroup.splice(i, 1);
        setAssignGroup(assignGroup);
    }

    const removeAssignedGroupToPayload = (id) => {
        const i = assignedGroup.findIndex(elm => +elm === +id);
        assignedGroup.splice(i, 1);
        setAssignedGroup(assignedGroup);
    }

    const assignGroupsToUser = async (e) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER)){
            if(!assignGroup?.length) return;
            setLoading(true);
            try {
                const payload = { groups : [...assignGroup] };
                const res = await _assagnGroupsToUser(payload, params.id);
                if(res.status === 200){
                    setLoading(false);
                    setAssignGroup([]);
                    await getUserAccountById(params.id);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }

    const removeGroupsfromUser = async (e) => {
        if(_checkAccessOrRedirect(FUNCTIONALITIES_CODE.ASSIGN_GROUP_RIGHT_TO_USER)){
            if(!assignedGroup?.length) return;
            setLoading(true);
            try {
                const payload = { groups : [...assignedGroup] };
                const res = await _removeGroupsfromUser(payload, params.id);
                if(res.status === 200){
                    setLoading(false);
                    setAssignedGroup([]);
                    await getUserAccountById(params.id);
                }else{
                    setLoading(false);
                    toastr.error('Erreur', res.errorMessage, {timeOut: 5000});
                }
            }catch(e){
                console.error("Erreur: ", e);
                toastr.error('Erreur', e, {timeOut: 5000});
                setLoading(false);
            }
        }
    }

    const renderGroups = () => {
        return props.groups.map((item, i) => {
            return <LabelRadio key={i} id={i} statement={item.description} icon={FcInspection} className="mb-2" onChange={(e) => handleLabelChange(e, item)} />
        })
    }

    const renderUserGroups = () => {
        return props.selectedAccount?.userGroups?.map((item, i) => {
            return <LabelRadio key={i} id={`idx-${i}`} statement={item?.group?.description} icon={FcInspection} className="mb-2" onChange={(e) => handleAssignedLabelChange(e, item)} />
        })
    }

    const goback = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
        <div className="user-detail-view">
            <div className="page-action">
                <div className="d-flex align-items-center">
                    <button className="btn btn-prev mr-2" onClick={goback}> <FcPrevious /> <span style={{ marginLeft: 10, color: "#1179AD" }} >Go back</span> </button>
                    <Loader loading={loading} />
                </div>
                <div className="title-right">
                    <span className="sub-title-page ">{`USER ACCOUNT : ${props.selectedAccount?.email}`}</span>
                </div>
            </div>
            <div className="content-group-detail">
                <div className="c-left">
                    <div className="c-header">
                        <span> <Label statement={`GROUPS (${props.groups.length})`} icon={FcFilingCabinet} /> </span>
                    </div>
                    <div className="c-list">
                        {renderGroups()}
                    </div>
                </div>
                <div className="c-middle">
                    <button className="btn btn-light mr-2" onClick={(e) => assignGroupsToUser(e)}> <FcRight /> </button>
                    <button className="btn btn-light mr-2 mt-2" onClick={(e) => removeGroupsfromUser(e)}> <FcLeft /> </button>
                </div>
                <div className="c-right">
                    <div className="c-header">
                        <span> <Label statement={`USER : ${props.selectedAccount?.email} (GROUPS) ${props.selectedAccount?.userGroups?.length}`} icon={FcFilingCabinet} /> </span>
                    </div>
                    <div className="c-list">
                        {renderUserGroups()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {accounts, groups, rights} = state;
    return { 
        groups : groups.groups,
        rights : rights.rights,
        usersAccounts : accounts.accounts,
        selectedAccount : accounts.selectedAccount,
    }
}

export default connect(mapStateToProps, { setGroups, selectAccount })(UserAccountDetails);
