import { 
    SET_CASHIER_OPERATIONS, SELECT_CASHIER_OPERATION, ADD_CASHIER_OPERATION, ADD_CASHIER_OPERATIONS, DELETE_CASHIER_OPERATION,
    SET_CASHIER_OPERATION_LINES, ADD_CASHIER_OPERATION_LINE, UPDATE_CASHIER_OPERATION_LINE, DELETE_CASHIER_OPERATION_LINES, SELECT_CASHIER_OPERATION_LINE,
} from '../action-type/cashierType';

const INITIAL_STATE = {
    cashierOperations : [],
    cashierOperationLines : [],
    selectedCashierOperation : {},
    selectedCashierOperationLine : {},
};

const CashierReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_CASHIER_OPERATIONS : {
            return { ...state, cashierOperations: action.payload };
        }
        case SELECT_CASHIER_OPERATION : {
            return { ...state, selectedCashierOperation: action.payload };
        }
        case ADD_CASHIER_OPERATION : {
            if (action.payload) {
                return {
                    ...state,
                    cashierOperations: [ ...state.cashierOperations, action.payload],
                }
            }
            return state;
        }
        case ADD_CASHIER_OPERATIONS : {
            if (action.payload) {
                const {cashierOperations} = state;
                cashierOperations.concat(action.payload);
                return {
                    ...state,
                    cashierOperations,
                }
            }
            return state;
        }
        case DELETE_CASHIER_OPERATION: {
            if (action.payload) {
                const cashierOperations = state.cashierOperations;
                const idx = cashierOperations.findIndex(elm => +elm.id === +action.payload);
                cashierOperations.splice(idx, 1);
                return {...state, cashierOperations};
            }
            return state;
        }
        case SET_CASHIER_OPERATION_LINES : {
            return { ...state, cashierOperationLines: action.payload };
        }
        case SELECT_CASHIER_OPERATION_LINE : {
            return { ...state, cashierOperationLines: action.payload };
        }
        case ADD_CASHIER_OPERATION_LINE : {
            if (action.payload) {
                return {
                    ...state,
                    cashierOperationLines: [ ...state.cashierOperationLines, action.payload],
                }
            }
            return state;
        }
        case UPDATE_CASHIER_OPERATION_LINE: {
            if (action.payload) {
                state.cashierOperationLines[+action.payload.index] = action.payload.data;
                return state
            }
            return state;
        }
        case DELETE_CASHIER_OPERATION_LINES: {
            if (action.payload) {
                state.cashierOperationLines = action.payload.map((element) => {
                    return state.cashierOperationLines.splice(element, 1);
                });
                return state;
            }
            return state;
        }
        default: return state;
    }
}

export default CashierReducer;
